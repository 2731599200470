import React, { useEffect, useState } from 'react';
import VoiceTransferCreate from './VoiceTransferCreate';
// import TranscendSpeakers from "./TranscendSpeakers";
// import TranscendTranscribe from "./TranscendTranscribe";
// import TranscendTranslate from "./TranscendTranslate";
import AudioSegments from './AudioSegments';
import DubbedAudio from './DubbedAudio';
import VoiceTransferHome from './VoiceTransferHome';
// import SpectralSyncVideo from "./SpectralSyncVideo";
// import FileUploadMessage from "./FileUploadMessage";
import { Routes, Route, Link, Outlet, useLocation } from 'react-router-dom';

export default function VoiceTransfer({
  setSearchText,
  searchText,
  setUserInfo,
  selectedVoiceTransferComponent,
  setSelectedVoiceTransferComponent,
  menuLock,
  setMenuLock,
  projectName,
  setProjectName,
}) {
  const [projectId, setProjectId] = useState('');
  const projectIdLc = projectId.toLowerCase();
  const [languageId, setLanguageId] = useState('');
  const [speakersList, setSpeakersList] = useState([]);
  const [videoUrl, setVideoUrl] = useState('');
  const [selectedLangArray, setSelectedLangArray] = useState([]);
  const [voice1, setVoice1] = useState({});
  const [voicesArray, setVoicesArray] = useState([]);
  const [speakers, setSpeakers] = useState([]);
  const apiURL = process.env.REACT_APP_API_URL;
  useEffect(() => {
    document.title = 'Voice Transfer - Spectral Studio';
    setSearchText('');
  }, []);

  const location = useLocation();

  useEffect(() => {
    // Check if the pathname matches 'voiceTransfer' and not 'voiceTransfer/...'
    const isVoiceTransferHome = location.pathname === '/voice-transfer';

    if (isVoiceTransferHome) {
      setMenuLock(false);
    } else {
      setMenuLock(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (selectedVoiceTransferComponent !== 'voiceTransferHome') {
      setMenuLock(true);
    }
  }, [selectedVoiceTransferComponent]);

  function fetchUserInfo() {
    const reqBody = {
      UserId: localStorage.getItem('userSessionId'),
    };
    fetch(`${apiURL}/web/get_user_info`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers if needed
      },
      body: JSON.stringify(reqBody),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the data from the API
        if (data.IsValid) {
          localStorage.setItem('userInfo', JSON.stringify(data.UserInfo));
        }
      })
      .catch((error) => {
        // Handle errors
        console.error('Error:', error);
      });

    const fetchVoice = async (project_Id) => {
      const reqBody = {
        UserId: localStorage.getItem('userSessionId'),
        ProjectId: project_Id,
      };
      try {
        const response = await fetch(`${apiURL}/web/get_voices`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(reqBody),
        });

        if (response.ok) {
          const data = await response.json();

          if (data.IsValid) {
            // console.log("voice list is",data.VoiceList[0]);
            localStorage.setItem('voiceList', JSON.stringify(data.VoiceList));
            setVoice1(data.VoiceList[0]);
            setVoicesArray(data.VoiceList);
          }
        } else {
          // setFlag(false);
          throw new Error('Failed to fetch project data');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };
  }
  useEffect(() => {
    fetchUserInfo();
  }, []);

  // --------------------------------------------------------------------------------------------------------------------------

  const fetchVoice = async (project_Id) => {
    const reqBody = {
      UserId: localStorage.getItem('userSessionId'),
      ProjectId: project_Id,
    };
    try {
      const response = await fetch(`${apiURL}/web/get_voices`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reqBody),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.IsValid) {
          // console.log("voice list is",data.VoiceList[0]);
          localStorage.setItem('voiceList', JSON.stringify(data.VoiceList));
          setVoice1(data.VoiceList[0]);
          setVoicesArray(data.VoiceList);
        }
      } else {
        // setFlag(false);
        throw new Error('Failed to fetch project data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    fetchVoice();
  }, []);

  const renderSelectedVoiceTransferComponent = () => {
    switch (selectedVoiceTransferComponent) {
      case 'voiceTransferHome':
        return (
          <VoiceTransferHome
            searchText={searchText}
            setSelectedVoiceTransferComponent={
              setSelectedVoiceTransferComponent
            }
            setUserInfo={setUserInfo}
            menuLock={menuLock}
            setMenuLock={setMenuLock}
            setProjectId={setProjectId}
            setLanguageId={setLanguageId}
            setVideoUrl={setVideoUrl}
            setProjectName={setProjectName}
            setSelectedLangArray={setSelectedLangArray}
            setVoice1={setVoice1}
            setVoicesArray={setVoicesArray}
          />
        );
      case 'create':
        return (
          <VoiceTransferCreate
            setSelectedVoiceTransferComponent={
              setSelectedVoiceTransferComponent
            }
            projectId={projectId}
            setUserInfo={setUserInfo}
            setProjectId={setProjectId}
            projectName={projectName}
            voice1={voice1}
            setVoice1={setVoice1}
            setSpeakers={setSpeakers}
            setProjectName={setProjectName}
            languageId={languageId}
            setLanguageId={setLanguageId}
            videoUrl={videoUrl}
            setVideoUrl={setVideoUrl}
            setSelectedLangArray={setSelectedLangArray}
            setVoicesArray={setVoicesArray}
          />
        );
      // case "speaker":
      // if (
      //   JSON.parse(localStorage.getItem("userInfo")).IsDubbingClient === true
      // ) {
      //   return <FileUploadMessage />;
      // } else {
      //   return (
      //     <TranscendSpeakers
      //     setSelectedVoiceTransferComponent={setSelectedVoiceTransferComponent}
      //       projectId={projectId}
      //       setProjectId={setProjectId}
      //       languageId={languageId}
      //       videoUrl={videoUrl}
      //       selectedLangArray={selectedLangArray}
      //       voice1={voice1}
      //       voicesArray={voicesArray}
      //       setSpeakers={setSpeakers}
      //       speakers={speakers}
      //     />
      //   );
      // }
      // case "transcribe":
      //   return (
      //     <TranscendTranscribe
      //     setSelectedVoiceTransferComponent={setSelectedVoiceTransferComponent}
      //       projectName={projectName}
      //       speakersList={speakersList}
      //       setSpeakersList={setSpeakersList}
      //       projectId={projectId}
      //       setProjectId={setProjectId}
      //       videoUrl={videoUrl}
      //       setProjectName={setProjectName}
      //       setLanguageId={setLanguageId}
      //       languageId={languageId}
      //       setVoicesArray={setVoicesArray}
      //     />
      //   );
      // case "translate":
      //   return (
      //     <TranscendTranslate
      //     setSelectedVoiceTransferComponent={setSelectedVoiceTransferComponent}
      //       projectName={projectName}
      //       setProjectName={setProjectName}
      //       setProjectId={setProjectId}
      //       languageId={languageId}
      //       projectId={projectId}
      //       speakersList={speakersList}
      //       setSpeakersList={setSpeakersList}
      //       videoUrl={videoUrl}
      //       setVideoUrl={setVideoUrl}
      //       setLanguageId={setLanguageId}
      //     />
      //   );
      case 'audio_segments':
        return (
          <AudioSegments
            setSelectedVoiceTransferComponent={
              setSelectedVoiceTransferComponent
            }
            projectId={projectId}
            setProjectId={setProjectId}
            setLanguageId={setLanguageId}
            languageId={languageId}
            videoUrl={videoUrl}
            setProjectName={setProjectName}
            projectName={projectName}
          />
        );
      case 'download':
        return (
          <DubbedAudio
            setProjectId={setProjectId}
            setSelectedVoiceTransferComponent={
              setSelectedVoiceTransferComponent
            }
            projectId={projectId}
            setLanguageId={setLanguageId}
            languageId={languageId}
            voicesArray={voicesArray}
            setVoicesArray={setVoicesArray}
          />
        );
      // case "spectral-sync":
      //   return (
      //     <SpectralSyncVideo
      //     setSelectedVoiceTransferComponent={setSelectedVoiceTransferComponent}
      //       videoUrl={videoUrl}
      //       projectId={projectId}
      //       languageId={languageId}
      //       setProjectId={setProjectId}
      //       setLanguageId={setLanguageId}
      //       setVideoUrl={setVideoUrl}
      //       setProjectName={setProjectName}
      //     />
      //   );
      default:
        return null;
    }
  };
  return (
    <div className="spectralTranscend">
      {(location.pathname === '/voice-transfer' ||
        location.pathname === '/voice-transfer/home') && (
        <h2 className="mt-4 ms-5">Voice Transfer</h2>
      )}
      {(location.pathname === '/voice-transfer' ||
        location.pathname === '/voice-transfer/home') && (
        <span className="mt-2 ms-5" style={{ color: 'grey' }}>
          Fully flexible end-to-end localization workflow for powerful, dynamic
          and human-in-the-loop localization.
        </span>
      )}
      <div>
        {/* {renderSelectedVoiceTransferComponent()} */}
        <Routes>
          <Route
            path="/"
            element={
              <VoiceTransferHome
                searchText={searchText}
                setSelectedVoiceTransferComponent={
                  setSelectedVoiceTransferComponent
                }
                setUserInfo={setUserInfo}
                menuLock={menuLock}
                setMenuLock={setMenuLock}
                projectId={projectId}
                setProjectId={setProjectId}
                setLanguageId={setLanguageId}
                setVideoUrl={setVideoUrl}
                setProjectName={setProjectName}
                setSelectedLangArray={setSelectedLangArray}
                setVoice1={setVoice1}
                setVoicesArray={setVoicesArray}
              />
            }
          />
          <Route
            path="home"
            element={
              <VoiceTransferHome
                searchText={searchText}
                setSelectedVoiceTransferComponent={
                  setSelectedVoiceTransferComponent
                }
                setUserInfo={setUserInfo}
                menuLock={menuLock}
                setMenuLock={setMenuLock}
                projectId={projectId}
                setProjectId={setProjectId}
                setLanguageId={setLanguageId}
                setVideoUrl={setVideoUrl}
                setProjectName={setProjectName}
                setSelectedLangArray={setSelectedLangArray}
                setVoice1={setVoice1}
                setVoicesArray={setVoicesArray}
              />
            }
          />
          <Route
            path="create"
            element={
              <VoiceTransferCreate
                setSelectedVoiceTransferComponent={
                  setSelectedVoiceTransferComponent
                }
                projectId={projectId}
                setUserInfo={setUserInfo}
                setProjectId={setProjectId}
                projectName={projectName}
                voice1={voice1}
                setVoice1={setVoice1}
                setSpeakers={setSpeakers}
                setProjectName={setProjectName}
                languageId={languageId}
                setLanguageId={setLanguageId}
                videoUrl={videoUrl}
                setVideoUrl={setVideoUrl}
                setSelectedLangArray={setSelectedLangArray}
                setVoicesArray={setVoicesArray}
              />
            }
          />
          <Route
            path=":projectIdLc/audio-segments"
            element={
              <AudioSegments
                setSelectedVoiceTransferComponent={
                  setSelectedVoiceTransferComponent
                }
                projectId={projectId}
                setProjectId={setProjectId}
                setLanguageId={setLanguageId}
                languageId={languageId}
                videoUrl={videoUrl}
                setProjectName={setProjectName}
                projectName={projectName}
              />
            }
          />
          <Route
            path=":projectIdLc/download"
            element={
              <DubbedAudio
                setProjectId={setProjectId}
                setSelectedVoiceTransferComponent={
                  setSelectedVoiceTransferComponent
                }
                projectId={projectId}
                setLanguageId={setLanguageId}
                languageId={languageId}
                voicesArray={voicesArray}
                setVoicesArray={setVoicesArray}
              />
            }
          />
        </Routes>
        <Outlet />
      </div>
    </div>
  );
}
