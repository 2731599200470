import React, { useEffect, useState } from 'react';
import { AiOutlinePlus, AiOutlineSync } from 'react-icons/ai';
import { BsTranslate } from 'react-icons/bs';
import { BiTransferAlt } from 'react-icons/bi';
import spectral from '../multimedia/Spectral Logo_Final_Red.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { RiUserVoiceFill } from 'react-icons/ri';
import { FaQuora } from 'react-icons/fa6';
import { MdSupportAgent } from 'react-icons/md';
import { ImProfile } from 'react-icons/im';
import { RiAdminFill } from 'react-icons/ri';
import { RiArrowUpSLine, RiArrowDownSLine } from 'react-icons/ri';
import { MdDashboard } from 'react-icons/md';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Menu({
  selectedDashboardComponent,
  setSelectedDashboardComponent,
  render,
  setRender,
  setSelectedTranscendComponent,
  setSelectedTranslateComponent,
  setSelectedVoiceTransferComponent,
  selectedAdminComponent,
  setSelectedAdminComponent,
  selectedDigitalTwinComponent,
  setSelectedDigitalTwinComponent,
  selectedProjectsComponent,
  setSelectedProjectsComponent,
  setShowProjectsSubButtons,
  showProjectsSubButtons,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const [showAdminSubButtons, setShowAdminSubButtons] = useState(false);
  const [showDigitalTwinSubButtons, setShowDigitalTwinSubButtons] =
    useState(false);

  useEffect(() => {
    if (selectedDashboardComponent !== 'admin') {
      setShowAdminSubButtons(false);
    }
  }, [selectedDashboardComponent]);
  useEffect(() => {
    if (selectedDashboardComponent !== 'digital-twin') {
      setShowDigitalTwinSubButtons(false);
    }
  }, [selectedDashboardComponent]);

  const notify = (error) =>
    toast.error(error, {
      className: 'custom-error-toast',
    });

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        overflowY: 'auto',
      }}
    >
      <img className="logo" src={spectral} alt="logo" />

      {JSON.parse(localStorage.getItem('userInfo')).Role !== 'DRM' ? (
        <button
          className="new-videoBtn"
          onClick={() => {
            const userInfo = JSON.parse(localStorage.getItem('userInfo'));
            if (
              userInfo &&
              userInfo.LimitCredits > 0 &&
              userInfo.LimitCredits > userInfo.UsageCredits
            ) {
              document.title = 'Transcend - Spectral Studio';
              setSelectedTranscendComponent('create');
              navigate('/spectral-transcend');
            } else {
              if (userInfo.LimitCredits <= 0) {
                notify(
                  'Subscription has expired. Renew your subscription to keep using Spectral.'
                );
              } else if (userInfo.LimitCredits <= userInfo.UsageCredits) {
                notify('Insufficient credits available for project creation!');
              }
            }
          }}
        >
          <span>New Project</span>
          <span>
            <AiOutlinePlus />
          </span>
        </button>
      ) : null}

      {/* <button id='home' className='common-btn' onClick={() =>{document.title = 'Home - Spectral Studio'; navigate('/home')}}><span className='btn-styling'><span style={{ fontSize: '20px' }}><RiHome6Line /></span><span style={{ fontSize: '16px',paddingTop:'4px' }}>Home</span></span></button> */}
      {/* <button id='home' className='common-btn' onClick={() =>{document.title = 'Home - Spectral Studio';setSelectedTranscendComponent('transcendHome'); navigate('/spectral-transcend')}}><span className='btn-styling'><span style={{ fontSize: '20px' }}><RiHome6Line /></span><span style={{ fontSize: '16px',paddingTop:'4px' }}>Home</span></span></button> */}

      {JSON.parse(localStorage.getItem('userInfo')).TranscendAccess === true &&
      JSON.parse(localStorage.getItem('userInfo')).Role !== 'DRM' ? (
        <button
          className="common-btn personalize"
          onClick={() => {
            setSelectedDashboardComponent('spectral-transcend');
            setSelectedTranscendComponent('transcendHome');
            navigate('/transcend');
            setRender(!render);
          }}
          style={{
            backgroundColor:
              location.pathname === '/transcend' ? '#eeeef0' : 'white',
            color: 'black',
          }}
        >
          <div className="btn-styling">
            <span style={{ fontSize: '20px' }}>
              <AiOutlineSync />
            </span>
            <div>
              <span style={{ fontSize: '16px' }}>Transcend</span>
              <span
                style={{ fontSize: '12px', display: 'flex', color: 'grey' }}
              >
                End-to-end
              </span>
            </div>
          </div>
        </button>
      ) : null}

      {/* -------------- voice transfer ------------- */}
      {JSON.parse(localStorage.getItem('userInfo')).VTAccess === true &&
      JSON.parse(localStorage.getItem('userInfo')).Role !== 'DRM' ? (
        <button
          className="common-btn personalize"
          onClick={() => {
            setSelectedDashboardComponent('voice-transfer');
            setSelectedVoiceTransferComponent('voiceTransferHome');
            navigate('/voice-transfer');
            setRender(!render);
          }}
          style={{
            backgroundColor:
              location.pathname === '/voice-transfer' ? '#eeeef0' : 'white',
            color: 'black',
          }}
        >
          <div className="btn-styling">
            <span style={{ fontSize: '20px' }}>
              <BiTransferAlt />
            </span>
            <div>
              <span style={{ fontSize: '16px' }}>Voice Transfer</span>
              <span
                style={{ fontSize: '12px', display: 'flex', color: 'grey' }}
              >
                End-to-end
              </span>
            </div>
          </div>
        </button>
      ) : null}

      {/* <button id='spectral-personalize' className='common-btn personalize' onClick={() =>{ navigate('/spectral-personalize');setRender(!render);}}><span className='btn-styling'><span style={{ fontSize: '20px' }}><BsPersonGear /></span><span style={{ fontSize: '16px',paddingTop:'4px'  }}>Spectral Personalize</span></span></button> */}

      {JSON.parse(localStorage.getItem('userInfo')).IsDubbingClient === false &&
      JSON.parse(localStorage.getItem('userInfo')).TranslateAccess === true &&
      JSON.parse(localStorage.getItem('userInfo')).Role !== 'DRM' ? (
        <button
          className="common-btn personalize"
          onClick={() => {
            setSelectedDashboardComponent('spectral-translate');
            setSelectedTranslateComponent('tHome');
            navigate('/translate');
            setRender(!render);
          }}
          style={{
            backgroundColor:
              location.pathname === '/translate' ? '#eeeef0' : 'white',
            color: 'black',
          }}
        >
          <div className="btn-styling">
            <span style={{ fontSize: '20px' }}>
              <BsTranslate />
            </span>
            <div>
              <span style={{ fontSize: '16px' }}>Translate</span>
              <span
                style={{ fontSize: '12px', display: 'flex', color: 'grey' }}
              >
                One-click
              </span>
            </div>
          </div>
        </button>
      ) : null}

      {JSON.parse(localStorage.getItem('userInfo')).IsDubbingClient === false &&
      JSON.parse(localStorage.getItem('userInfo')).VoiceLibAccess === true &&
      JSON.parse(localStorage.getItem('userInfo')).Role !== 'DRM' ? (
        <button
          className="common-btn"
          onClick={() => {
            setSelectedDashboardComponent('voice-library');
            navigate('/voice-library');
            setRender(!render);
          }}
          style={{
            backgroundColor:
              location.pathname === '/voice-library' ? '#eeeef0' : 'white',
            color: 'black',
          }}
        >
          <span className="btn-styling">
            <span style={{ fontSize: '20px' }}>
              <RiUserVoiceFill />
            </span>
            <span style={{ fontSize: '16px', paddingTop: '4px' }}>
              Voice Library
            </span>
          </span>
        </button>
      ) : null}

      {JSON.parse(localStorage.getItem('userInfo')).IsDubbingClient === false &&
      JSON.parse(localStorage.getItem('userInfo')).VoiceLabAccess === true &&
      JSON.parse(localStorage.getItem('userInfo')).Role !== 'DRM' ? (
        <button
          className="common-btn"
          onClick={() => {
            setSelectedDashboardComponent('voice-lab');
            navigate('/voice-lab');
            setRender(!render);
          }}
          style={{
            backgroundColor:
              location.pathname === '/voice-lab' ? '#eeeef0' : 'white',
            color: 'black',
          }}
        >
          <span className="btn-styling">
            <span style={{ fontSize: '20px' }}>
              <RiUserVoiceFill />
            </span>
            <span style={{ fontSize: '16px', paddingTop: '4px' }}>
              Voice Lab
            </span>
          </span>
        </button>
      ) : null}
      {JSON.parse(localStorage.getItem('userInfo')).Role !== 'DRM' ? (
        <button
          className="common-btn"
          onClick={() => {
            setSelectedDashboardComponent('profile');
            navigate('/profile');
            setRender(!render);
          }}
          style={{
            backgroundColor:
              location.pathname === '/profile' ? '#eeeef0' : 'white',
            color: 'black',
          }}
        >
          <span className="btn-styling">
            <span style={{ fontSize: '20px' }}>
              <ImProfile />
            </span>
            <span style={{ fontSize: '16px', paddingTop: '4px' }}>Profile</span>
          </span>
        </button>
      ) : null}

      {JSON.parse(localStorage.getItem('userInfo')).IsAdmin === true &&
      JSON.parse(localStorage.getItem('userInfo')).Role !== 'DRM' ? (
        <button
          className="common-btn"
          onClick={() => {
            setSelectedDashboardComponent('admin');
            if (selectedAdminComponent === 'user-edit') {
              setSelectedAdminComponent('manage-users');
            }
            setShowAdminSubButtons(!showAdminSubButtons);
            navigate('/admin/manage-users');
            setRender(!render);
          }}
          style={{
            backgroundColor: location.pathname.startsWith('/admin')
              ? '#eeeef0'
              : 'white',
            color: 'black',
          }}
        >
          <span
            className="btn-styling"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <span style={{ fontSize: '20px' }}>
              <RiAdminFill />
            </span>
            <span style={{ fontSize: '16px', paddingTop: '4px' }}>Admin</span>
            <span
              style={{
                marginLeft: 'auto',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {showAdminSubButtons ? (
                <RiArrowUpSLine style={{ fontSize: '20px' }} />
              ) : (
                <RiArrowDownSLine style={{ fontSize: '20px' }} />
              )}
            </span>
          </span>
        </button>
      ) : null}

      {showAdminSubButtons && selectedDashboardComponent === 'admin' && (
        <div>
          <button
            className="common-btn"
            style={{
              backgroundColor:
                selectedAdminComponent === 'manage-users' ? '#eeeef0' : 'white',
              color: 'black',
              width: '70%',
              marginLeft: '20%',
              height: '40px',
            }}
            onClick={() => {
              setSelectedAdminComponent('manage-users');
              navigate('/admin/manage-users');

              // setShowAdminSubButtons(false);
            }}
          >
            <span className="btn-styling">
              <span style={{ fontSize: '14px', paddingTop: '4px' }}>
                Manage Users
              </span>
            </span>
          </button>
          <button
            className="common-btn"
            onClick={() => {
              setSelectedAdminComponent('manage-lip-sync-requests');
              navigate('/admin/manage-lip-sync-requests');

              // setShowAdminSubButtons(false);
            }}
            style={{
              backgroundColor:
                selectedAdminComponent === 'manage-lip-sync-requests'
                  ? '#eeeef0'
                  : 'white',
              color: 'black',
              width: '70%',
              marginLeft: '20%',
              height: '40px',
            }}
          >
            <span className="btn-styling">
              <span style={{ fontSize: '14px', paddingTop: '4px' }}>
                Manage Lip Sync Requests
              </span>
            </span>
          </button>
          <button
            className="common-btn"
            style={{
              backgroundColor:
                selectedAdminComponent === 'manage-projects'
                  ? '#eeeef0'
                  : 'white',
              color: 'black',
              width: '70%',
              marginLeft: '20%',
              height: '40px',
            }}
            onClick={() => {
              setSelectedAdminComponent('manage-projects');
              navigate('/admin/manage-projects');

              // setShowAdminSubButtons(false);
            }}
          >
            <span className="btn-styling">
              <span style={{ fontSize: '14px', paddingTop: '4px' }}>
                Manage Projects
              </span>
            </span>
          </button>
        </div>
      )}

      {JSON.parse(localStorage.getItem('userInfo')).IsDubbingClient === false &&
      JSON.parse(localStorage.getItem('userInfo')).Role === 'DRM' ? (
        <button
          className="common-btn"
          onClick={() => {
            setSelectedDashboardComponent('drm-dashboard');
            navigate('/drm/dashboard');
            setRender(!render);
          }}
          style={{
            backgroundColor:
              selectedDashboardComponent === 'drm-dashboard'
                ? '#eeeef0'
                : 'white',
            color: 'black',
          }}
        >
          <span className="btn-styling">
            <span style={{ fontSize: '20px' }}>
              <MdDashboard />
            </span>
            <span style={{ fontSize: '16px', paddingTop: '4px' }}>
              Dashboard
            </span>
          </span>
        </button>
      ) : null}
      {JSON.parse(localStorage.getItem('userInfo')).IsDubbingClient === false &&
      JSON.parse(localStorage.getItem('userInfo')).Role === 'DRM' ? (
        <button
          className="common-btn"
          onClick={() => {
            setSelectedDashboardComponent('digital-twin');
            if (
              selectedDigitalTwinComponent === 'manage-voice' ||
              selectedDigitalTwinComponent === 'edit-voice'
            ) {
              setSelectedDigitalTwinComponent('voice');
            }
            setShowDigitalTwinSubButtons(!showDigitalTwinSubButtons);
            navigate('/drm/digital-twin');
            setRender(!render);
          }}
          style={{
            backgroundColor:
              selectedDashboardComponent === 'digital-twin'
                ? '#eeeef0'
                : 'white',
            color: 'black',
          }}
        >
          <span
            className="btn-styling"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <span style={{ fontSize: '16px', paddingTop: '4px' }}>
              Digital Twin
            </span>
            <span
              style={{
                marginLeft: 'auto',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {showDigitalTwinSubButtons ? (
                <RiArrowUpSLine style={{ fontSize: '20px' }} />
              ) : (
                <RiArrowDownSLine style={{ fontSize: '20px' }} />
              )}
            </span>
          </span>
        </button>
      ) : null}

      {showDigitalTwinSubButtons &&
        selectedDashboardComponent === 'digital-twin' && (
          <div>
            <button
              className="common-btn"
              style={{
                backgroundColor:
                  selectedDigitalTwinComponent === 'voice'
                    ? '#eeeef0'
                    : 'white',
                color: 'black',
                width: '70%',
                marginLeft: '20%',
                height: '40px',
              }}
              onClick={() => {
                setSelectedDigitalTwinComponent('voice');
                navigate('/drm/digital-twin/voice');

                // setShowAdminSubButtons(false);
              }}
            >
              <span className="btn-styling">
                <span style={{ fontSize: '14px', paddingTop: '4px' }}>
                  Voice
                </span>
              </span>
            </button>
            <button
              className="common-btn"
              style={{
                backgroundColor:
                  selectedDigitalTwinComponent === 'face' ? '#eeeef0' : 'white',
                color: 'black',
                width: '70%',
                marginLeft: '20%',
                height: '40px',
              }}
              onClick={() => {
                setSelectedDigitalTwinComponent('face');
                navigate('/drm/digital-twin/face');

                // setShowAdminSubButtons(false);
              }}
            >
              <span className="btn-styling">
                <span style={{ fontSize: '14px', paddingTop: '4px' }}>
                  Face
                </span>
              </span>
            </button>
            <button
              className="common-btn"
              style={{
                backgroundColor:
                  selectedDigitalTwinComponent === 'face-3d'
                    ? '#eeeef0'
                    : 'white',
                color: 'black',
                width: '70%',
                marginLeft: '20%',
                height: '40px',
              }}
              onClick={() => {
                setSelectedDigitalTwinComponent('face-3d');
                navigate('/drm/digital-twin/face-3d');

                // setShowAdminSubButtons(false);
              }}
            >
              <span className="btn-styling">
                <span style={{ fontSize: '14px', paddingTop: '4px' }}>
                  3D Face
                </span>
              </span>
            </button>
            <button
              className="common-btn"
              style={{
                backgroundColor:
                  selectedDigitalTwinComponent === 'avatar'
                    ? '#eeeef0'
                    : 'white',
                color: 'black',
                width: '70%',
                marginLeft: '20%',
                height: '40px',
              }}
              onClick={() => {
                setSelectedDigitalTwinComponent('avatar');
                navigate('/drm/digital-twin/avatar');

                // setShowAdminSubButtons(false);
              }}
            >
              <span className="btn-styling">
                <span style={{ fontSize: '14px', paddingTop: '4px' }}>
                  Avatar
                </span>
              </span>
            </button>
          </div>
        )}

      {JSON.parse(localStorage.getItem('userInfo')).IsDubbingClient === false &&
      JSON.parse(localStorage.getItem('userInfo')).Role === 'DRM' ? (
        <button
          className="common-btn"
          onClick={() => {
            setSelectedDashboardComponent('projects');
            setSelectedProjectsComponent('project-list');

            // if (selectedProjectsComponent === '') {
            //   setSelectedProjectsComponent('project-list');
            // }
            setShowProjectsSubButtons(!showProjectsSubButtons);
            navigate('/drm/projects');
            setRender(!render);
          }}
          style={{
            backgroundColor:
              selectedDashboardComponent === 'projects' ? '#eeeef0' : 'white',
            color: 'black',
          }}
        >
          <span
            className="btn-styling"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <span style={{ fontSize: '16px', paddingTop: '4px' }}>
              Projects
            </span>
            <span
              style={{
                marginLeft: 'auto',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {showProjectsSubButtons ? (
                <RiArrowUpSLine style={{ fontSize: '20px' }} />
              ) : (
                <RiArrowDownSLine style={{ fontSize: '20px' }} />
              )}
            </span>
          </span>
        </button>
      ) : null}

      {showProjectsSubButtons && selectedDashboardComponent === 'projects' && (
        <div>
          <button
            className="common-btn"
            style={{
              backgroundColor:
                selectedProjectsComponent === 'project-list'
                  ? '#eeeef0'
                  : 'white',
              color: 'black',
              width: '70%',
              marginLeft: '20%',
              height: '40px',
            }}
            onClick={() => {
              setSelectedProjectsComponent('project-list');
              navigate('/drm/projects/list');

              // setShowAdminSubButtons(false);
            }}
          >
            <span className="btn-styling">
              <span style={{ fontSize: '14px', paddingTop: '4px' }}>List</span>
            </span>
          </button>

          <button
            className="common-btn"
            style={{
              backgroundColor:
                selectedProjectsComponent === 'project-transaction'
                  ? '#eeeef0'
                  : 'white',
              color: 'black',
              width: '70%',
              marginLeft: '20%',
              height: '40px',
            }}
            onClick={() => {
              setSelectedProjectsComponent('project-transaction');
              navigate('/drm/projects/transaction');

              // setShowAdminSubButtons(false);
            }}
          >
            <span className="btn-styling">
              <span style={{ fontSize: '14px', paddingTop: '4px' }}>
                Transactions
              </span>
            </span>
          </button>
        </div>
      )}

      {/* ----- */}

      {JSON.parse(localStorage.getItem('userInfo')).IsDubbingClient === false &&
      JSON.parse(localStorage.getItem('userInfo')).Role === 'DRM' ? (
        <button
          className="common-btn"
          onClick={() => {
            setSelectedDashboardComponent('audit-log');
            navigate('/drm/audit-log');
            setRender(!render);
          }}
          style={{
            backgroundColor:
              selectedDashboardComponent === 'audit-log' ? '#eeeef0' : 'white',
            color: 'black',
          }}
        >
          <span className="btn-styling">
            {/* <span style={{ fontSize: '20px' }}>
              <RiUserVoiceFill />
            </span> */}
            <span style={{ fontSize: '16px', paddingTop: '4px' }}>
              Audit Log
            </span>
          </span>
        </button>
      ) : null}
      {/* <button id='my-assets' className='common-btn' onClick={() => navigate('/my-assets')}><span className='btn-styling'><span style={{ fontSize: '20px' }}><AiOutlineFolder /></span><span style={{ fontSize: '16px',paddingTop:'4px'  }}>My Assets</span></span></button> */}
      {/* <button id='tutorials' className='common-btn' onClick={() => {setSelectedDashboardComponent('home');window.open(" https://spectralstudios.ai/", "_blank");}}><span className='btn-styling'><span style={{ fontSize: '20px',paddingTop:'4px'  }}><MdOutlinePlayLesson /></span><span style={{ fontSize: '16px' }}>Tutorials</span></span></button> */}
      {/* <button
        className="common-btn"
        onClick={() => {
          setSelectedDashboardComponent('faq');
          navigate('/home');
          window.open('https://spectralstudios.ai/', '_blank');
        }}
        style={{
          backgroundColor:
            selectedDashboardComponent === 'faq' ? '#eeeef0' : 'white',
          color: 'black',
        }}
      >
        <span className="btn-styling">
          <span style={{ fontSize: '20px' }}>
            <FaQuora />
          </span>
          <span style={{ fontSize: '16px', paddingTop: '4px' }}>FAQ</span>
        </span>
      </button> */}

      {/* <button
        className="common-btn"
        onClick={() => {
          setSelectedDashboardComponent('support');
          navigate('/home');
          window.open('https://forms.gle/wtZhhzS5BUGTw6hq8', '_blank');
        }}
        style={{
          backgroundColor:
            selectedDashboardComponent === 'support' ? '#eeeef0' : 'white',
          color: 'black',
        }}
      >
        <span className="btn-styling">
          <span style={{ fontSize: '20px' }}>
            <MdSupportAgent />
          </span>
          <span style={{ fontSize: '16px', paddingTop: '4px' }}>Support</span>
        </span>
      </button> */}
      <ToastContainer />
    </div>
  );
}
