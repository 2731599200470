import React, { useEffect, useState } from "react";
import { FaVolumeUp } from "react-icons/fa";
import { Rnd } from "react-rnd";

export default function Audio({
  initialMarkings,
  selectedAudioFile,
  xCoordinate,
  setXCoordinate,
  audioPositionSettings,
  reqAudioMarking,
  setReqAudioMarking,
  setTotalAudioDuration,
  totalAudioDuration,
  setInitialMarkings,
  zoomIn,
  zoomOut,
  max,
}) {
  const [audioFilesArray, setAudioFilesArray] = useState([]);
  const animationSpeed = 50;

  useEffect(() => {
    if (selectedAudioFile.name) {
      // Check if selectedAudioFile already exists in audioFilesArray
      const isDuplicate = audioFilesArray.some(
        (file) => file.name === selectedAudioFile.name
      );

      // If it's not a duplicate, add it to the array
      if (!isDuplicate) {
        setAudioFilesArray((prevArray) => {
          const updatedArray = [...prevArray, selectedAudioFile];

          // Calculate and set the total audio duration
          const totalDuration = updatedArray.reduce(
            (acc, file) => acc + file.duration,
            0
          );
          setTotalAudioDuration(totalDuration);

          return updatedArray;
        });
      }
    }
  }, [selectedAudioFile, audioFilesArray, setTotalAudioDuration]);

  // Calculate markingInterval
  const markingsDiv = document.getElementById("markerScale");
  let markingInterval = 0;
  if (markingsDiv) {
    const scaleWidth = markingsDiv.clientWidth;
    markingInterval = scaleWidth / initialMarkings;
  }

  // Calculate the cumulative width of previous files
  let cumulativeX = 0;

  function handleDragStop(e, d, audioFileIndex) {
    const markingsDiv = document.getElementById("markerScale");
    if (markingsDiv) {
      const scaleWidth = markingsDiv.clientWidth;
      const markingInterval = scaleWidth / initialMarkings;
      let reqRead = d.x / markingInterval;

      // Initialize the updatedXCoordinate array before using it
      const updatedXCoordinate = [...xCoordinate];

      // Calculate the position and width of the dragged file
      const draggedFileX = d.x;
      const draggedFileWidth =
        markingInterval * audioFilesArray[audioFileIndex].duration;
      const draggedFileRightX = draggedFileX + draggedFileWidth;

      // Check for collisions with other files
      audioFilesArray.forEach((file, index) => {
        if (index !== audioFileIndex) {
          // Calculate the position and width of the other file
          const otherFileX = updatedXCoordinate[index] || 0;
          const otherFileWidth = markingInterval * file.duration;
          const otherFileRightX = otherFileX + otherFileWidth;

          // Check if the dragged file overlaps with the other file in either direction
          if (
            draggedFileX >= otherFileRightX ||
            draggedFileRightX <= otherFileX
          ) {
            // No collision, continue checking others
          } else {
            // Collision detected, adjust positions
            if (draggedFileX < otherFileX) {
              // Dragged file is to the left, align its start position with the end of the other file
              d.x = otherFileX - draggedFileWidth;
            } else {
              // Dragged file is to the right, align its end position with the start of the other file
              d.x = otherFileRightX;
            }
            reqRead = d.x / markingInterval; // Recalculate reqRead
          }
        }
      });

      // Update the xCoordinate array
      updatedXCoordinate[audioFileIndex] = d.x;

      // Update the reqAudioMarking array
      const updatedReqAudioMarking = [...reqAudioMarking];
      updatedReqAudioMarking[audioFileIndex] = reqRead;
      setReqAudioMarking(updatedReqAudioMarking);

      // Update the state with the modified xCoordinate array
      setXCoordinate(updatedXCoordinate);
    }
  }

  //   function handleDrag(e,d,audioIndex){
  //     audioFilesArray.forEach((file, index) => {
  //     if(index===0&& audioIndex===0)
  //     {
  // if(d.x===0){
  //   zoomIn();
  // }
  //     }
  //     if(index===audioFilesArray.length-1&& audioIndex===audioFilesArray.length-1)
  //     {
  //       const markingsDiv = document.getElementById('markerScale');
  //       if (markingsDiv) {
  //         const scaleWidth = markingsDiv.clientWidth;
  //       console.log(scaleWidth)
  // if(d.x===scaleWidth-){
  //   zoomOut();
  //   console.log('hello')
  // }
  //   }
  //   }
  // })
  // }

  return (
    <div className="audioFileContainer" id="audio">
      {audioFilesArray.length > 0 ? (
        audioFilesArray.map((file, index) => {
          // Calculate the width of the current file based on markingInterval and duration
          const fileWidth = markingInterval * file.duration;

          // Set the x position based on the cumulative width
          const xPosition = xCoordinate[index] || 0 + cumulativeX;

          // Update cumulativeX for the next file
          cumulativeX += fileWidth;
          const animationDuration = fileWidth / animationSpeed;

          return (
            <Rnd
              key={file.name}
              id={file.name}
              size={{
                width: `${fileWidth}px`, // Set the width of the current file
                height: "60px",
              }}
              position={{ x: xPosition, y: 2 }}
              // onDrag={(e, d) => handleDrag(e, d, index)}
              onDragStop={(e, d) => handleDragStop(e, d, index)}
              bounds="parent"
              dragAxis="x"
              style={{
                backgroundColor: "rgb(102 103 106)",
                overflowX: "hidden",
                overflowY: "hidden",
                borderRadius: "5px",
                // textAlign:'center',
                border: "1px solid orange",
              }}
            >
              <p
                className="mt-3"
                style={{
                  fontSize: "14px",
                  color: "white",
                  animation: `moveLeftToRight ${animationDuration}s linear infinite`,
                }}
              >
                {file.name}
              </p>
              {/* <p style={{ fontSize: '14px', color: 'white' }}>{file.duration}</p> */}
            </Rnd>
          );
        })
      ) : (
        <div className="audioContainerMessage">
          <p
            style={{
              verticalAlign: "middle",
              transform: "translate(0,50%)",
              fontSize: "20px",
            }}
          >
            audio{" "}
            <span style={{ marginLeft: "10px" }}>
              <FaVolumeUp />
            </span>
          </p>
        </div>
      )}
    </div>
  );
}
