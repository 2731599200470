import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row,
} from 'react-bootstrap';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { FaDownload, FaCirclePlay, FaUpload } from 'react-icons/fa6';
import './audioSegments.css';
import AudioSegmentPage2 from './AudioSegmentPage2';
import loading from '../../../multimedia/loading.gif';
import Spinner from 'react-bootstrap/Spinner';
import UploadAudio from './UploadAudio';
import { FaUserCircle, FaPause, FaPlay } from 'react-icons/fa';
import { RiUserVoiceFill } from 'react-icons/ri';
import { HiInformationCircle } from 'react-icons/hi2';
import ReactGA from 'react-ga';
import { FaMicrophone } from 'react-icons/fa';
import RecordAudio from './RecordAudio';
import { IoArrowBack } from 'react-icons/io5';
import { FaEdit } from 'react-icons/fa';
import { LuSplit } from 'react-icons/lu';
import { ToastContainer, toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';

export default function AudioSegmentPage1({
  audioSegments,
  setAudioSegments,
  setSelectedSegmentComponent,
  videoUrl,
  setProjectName,
  setProjectId,
  setLanguageId,
  projectName,
  setSelectedTranscendComponent,
  flag,
  setFlag,
  flag2,
  setFlag2,
  projectId,
  languageId,
  handleDownloadClick,
  pauseGeneration,
  setPauseGeneration,
  currentStatus,
  setCurrentStatus,
}) {
  const navigate = useNavigate();
  const apiURL = process.env.REACT_APP_API_URL;
  const [show, setShow] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [difference, setDifference] = useState(false);
  const [proceed, setProceed] = useState(false);
  // states for upload audio
  const [fileName, setFileName] = useState('');
  const [audioId, setAudioId] = useState('');
  const [audioDuration, setAudioDuration] = useState(0);
  const [showWarning, setShowWarning] = useState(false);
  const [showRecord, setShowRecord] = useState(false);
  const [segmentName, setSegmentName] = useState('');
  const handleWarningClose = () => setShowWarning(false);
  const handleWarningShow = () => setShowWarning(true);

  const [speakerShow, setSpeakerShow] = useState(false);
  const [speakers, setSpeakers] = useState([]);
  const [speakerCount, setSpeakerCount] = useState(speakers.length);

  let voicesArray = JSON.parse(localStorage.getItem('voiceList'));

  const handleSpeakerClose = () => setSpeakerShow(false);
  const handleSpeakerShow = () => {
    setSpeakerShow(true);
    ReactGA.event({
      category: 'Modal',
      action: 'Open',
      label: 'audio_segments/select_voice',
    });
  };

  const [nextClicked, setNextClicked] = useState(false);
  const [segmentData, setSegmentData] = useState({});
  const [segmentId, setSegmentId] = useState('');
  const handleClose = () => setShow(false);
  const handleUploadClose = () => setShowUpload(false);
  const handleRecordClose = () => {
    setShowRecord(false);
    setSegmentData({});
  };
  const handleShow = (index) => {
    ReactGA.event({
      category: 'Modal',
      action: 'Open',
      label: 'audio_segments/edit_segment',
    });
    setShow(true);
  };
  const handleRecordShow = (name, id, duration) => {
    ReactGA.event({
      category: 'Modal',
      action: 'Open',
      label: 'audio_segments/record_segment',
    });
    setShowRecord(true);
    setSegmentName(name);
    setAudioId(id);
    setAudioDuration(duration);
    dataForRecord(id);
  };
  const handleShowUpload = (index) => {
    setShowUpload(true);
    ReactGA.event({
      category: 'Modal',
      action: 'Open',
      label: 'audio_segments/upload_audio',
    });
  };
  useEffect(() => {
    document.addEventListener(
      'play',
      (event) => {
        const audios = [...document.getElementsByTagName('audio')];
        audios.forEach((audio) => audio !== event.target && audio.pause());
      },
      true
    );
  }, []);

  const InformationCircleTooltip = ({ id, text }) => (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip style={{ fontSize: '12px' }} id={`tooltip-${id}`}>
          {text}
        </Tooltip>
      }
    >
      <span
        style={{
          fontSize: '16px',
          marginTop: '12px',
          marginLeft: '6px',
          color: 'gray',
        }}
      >
        <HiInformationCircle />
      </span>
    </OverlayTrigger>
  );

  // get speakers function for api call

  function getSpeakers() {
    const req = {
      ProjectId: projectId,
    };

    fetch(`${apiURL}/web/get_project_speaker`, {
      method: 'POST',

      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(req),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.IsValid) {
          setSpeakers(res.SpeakerList);
        }
      });
  }

  useEffect(() => {
    getSpeakers();
  }, []);

  // fucntion for checking highest number in speaker.Name

  const findHighestNumber = (speakers) => {
    let highestNumber = 0;

    for (const speaker of speakers) {
      if (speaker.Name.startsWith('Speaker ')) {
        const number = parseInt(speaker.Name.split(' ')[1]);

        if (!isNaN(number) && number > highestNumber) {
          highestNumber = number;
        }
      }
    }

    return highestNumber;
  };
  // voice needs to be changed to voice id
  const handleAddSpeaker = () => {
    // setAddClicked(true);
    const highestNumber = findHighestNumber(speakers);
    const newSpeaker = {
      Id: uuidv4(),
      Name:
        highestNumber > 0
          ? `Speaker ${highestNumber + 1}`
          : `Speaker ${speakers.length + 1}`,
      // VoiceId: voice1.Id,
      ProjectId: projectId,
      LangId: languageId,
      FaceIds: [],
      VoiceId: voicesArray[0].Id,
    };

    // update speaker api call when new speaker is added

    const request = {
      ProjectId: projectId,
      SpeakerList: [newSpeaker],
    };

    fetch(`${apiURL}/web/update_project_speaker`, {
      method: 'POST',

      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(request),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.IsValid === true) {
          // setFlag(true);
          setSpeakerCount(speakerCount + 1);
          setSpeakers([...speakers, data.SpeakerData[0]]);
          // setAddClicked(false);
        } else {
          // setAddClicked(false);
          console.error('Error:', data.Error);
          // setNextClicked(false);
        }
      });
    // .catch((error) => {
    //   setAddClicked(false);
    //   console.error("API call failed:", error);
    //   setNextClicked(false);
    // });
  };

  // function Call to pause generation api

  function pauseSegmentsGeneration() {
    const req = {
      ProjectId: projectId,
      TargetLanguageId: languageId,
    };
    fetch(`${apiURL}/web/pause_audio_segment_generation`, {
      method: 'POST',

      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(req),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.IsValid) {
          setPauseGeneration(true);
        }
      });
  }
  // function Call to resume generation api

  function resumeSegmentsGeneration() {
    const req = {
      ProjectId: projectId,
      TargetLanguageId: languageId,
    };
    fetch(`${apiURL}/web/resume_audio_segment_generation`, {
      method: 'POST',

      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(req),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.IsValid) {
          setPauseGeneration(false);
          reFectchSegments();
        }
      });
  }

  function viewProject(name, id, duration) {
    handleShowUpload();
    setAudioId(id);
    setAudioDuration(duration);
    setFileName(name);
  }

  const mergeDubs = async () => {
    const request = {
      ProjectId: projectId,
      TargetLanguageId: languageId,
    };
    setNextClicked(true);
    try {
      const response = await fetch(`${apiURL}/dub/merge_dubs`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(request),
      });

      if (response.ok) {
        const result = await response.json();

        if (result.IsValid) {
          if (showWarning === true) {
            handleWarningClose();
          }
          setSelectedTranscendComponent('download');
          navigate(`/transcend/${projectId.toLowerCase()}/download`);
        }
      } else {
        setNextClicked(false);
        throw new Error('Failed to fetch project data');
      }
    } catch (error) {
      setNextClicked(false);
      console.error('Error:', error);
    }
  };
  function handleNext() {
    if (flag === true) {
      if (difference === true) {
        handleWarningShow();
      } else {
        mergeDubs();
      }
    }
  }
  const editAudioSegment = async (id, index) => {
    const request = {
      AudioSegmentId: id,
    };
    try {
      const response = await fetch(`${apiURL}/web/get_audio_segment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(request),
      });

      if (response.ok) {
        const result = await response.json();

        if (result.IsValid) {
          setSegmentData(result.AudioSegment);
          setSegmentId(id);
          handleShow(index);
        }
      } else {
        throw new Error('Failed to fetch project data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  async function dataForRecord(id) {
    const request = {
      AudioSegmentId: id,
    };
    try {
      const response = await fetch(`${apiURL}/web/get_audio_segment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(request),
      });

      if (response.ok) {
        const result = await response.json();

        if (result.IsValid) {
          setSegmentData(result.AudioSegment);
        }
      } else {
        throw new Error('Failed to fetch project data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  useEffect(() => {
    if (proceed === true) {
      mergeDubs();
    }
  }, [proceed]);

  useEffect(() => {
    // Check for the difference between TranscriptDuration and Duration
    const hasDifference = audioSegments.some(
      (data) =>
        data.Status === 2 &&
        Math.abs(data.TranscriptDuration - data.Duration) > 0.2
    );

    // Update the difference state
    setDifference(hasDifference);
  }, [audioSegments]);

  // speaker foice change using speaker select

  function voiceChange(speakerId, newVoiceId) {
    // Update the state directly with the new voiceId for the selected speaker
    const updatedSpeakers = speakers.map((speaker) =>
      speaker.Id === speakerId ? { ...speaker, VoiceId: newVoiceId } : speaker
    );

    // Update the state with the modified speakers array
    setSpeakers(updatedSpeakers);
  }

  function nameChange(speakerId, newSpeakerName) {
    // Update the state directly with the new Name for the selected speaker
    const updatedSpeakers = speakers.map((speaker) =>
      speaker.Id === speakerId ? { ...speaker, Name: newSpeakerName } : speaker
    );

    // Update the state with the modified speakers array
    setSpeakers(updatedSpeakers);
  }

  // function for update_project_speaker api call

  function updateProjectSpeaker() {
    setPauseGeneration(false);
    const request = {
      ProjectId: projectId,
      SpeakerList: speakers.map((speaker) => ({
        ...speaker,
        FaceIds: [],
      })),
    };

    fetch(`${apiURL}/web/update_project_speaker`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(request),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.IsValid === true) {
          reFectchSegments();
        } else {
          console.error('Error:', data.Error);
        }
      })
      .catch((error) => {
        console.error('API call failed:', error);
      });
  }

  // function to make api call to fetch audio segments again for every 5 seconds

  function reFectchSegments() {
    setFlag(false);
    setFlag2(false);
    const fetchAudioSegments = async () => {
      const reqBody = {
        ProjectId: projectId,
        TargetLanguageId: languageId,
      };
      try {
        const response = await fetch(`${apiURL}/web/get_audio_segments`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(reqBody),
        });

        if (response.ok) {
          const newData = await response.json();

          if (newData.IsValid) {
            setCurrentStatus(newData.Status);
            if (
              newData.Status === 10 ||
              newData.Status === 2 ||
              newData.Status === 11
            ) {
              clearInterval(intervalId);
              setFlag(true);
            }
            if (newData.Status === 10) {
              clearInterval(intervalId);
              setFlag2(true);
              console.log(flag2);
            }
            if (newData.Status === 101) {
              setPauseGeneration(true);
              clearInterval(intervalId);
            }
            setAudioSegments([...(newData.ResultAudioSegments || [])]);
            handleSpeakerClose();
          }
        } else {
          throw new Error('Failed to fetch project data');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchAudioSegments();
    const intervalId = setInterval(fetchAudioSegments, 5000);

    return () => clearInterval(intervalId);
  }

  const notify = (error) =>
    toast.error(error, {
      className: 'custom-error-toast',
    });

  async function change_project_result_status() {
    const req = {
      ProjectId: projectId,
      CurrentStatus: currentStatus,
      TargetStatus: 8,
    };
    try {
      const response = await fetch(
        `${apiURL}/web/change_project_result_status`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            // 'ApiKey': apiKey,
          },
          body: JSON.stringify(req),
        }
      );
      const res = await response.json();

      if (res.IsValid) {
        setProjectId(projectId);
        setProjectName(projectName);
        setLanguageId(languageId);
        setSelectedTranscendComponent('translate');
        navigate(`/transcend/${projectId.toLowerCase()}/translate`);

        console.log(projectId, projectName, languageId);
      } else {
        notify('Redirection to previous stage was unsuccessful');
      }
    } catch (error) {
      console.error(error);
      notify('Redirection to previous stage was unsuccessful');
    }
  }

  function back() {
    // console.log('back clicked');
    change_project_result_status();
  }

  return (
    <div style={{ overflowY: 'auto' }}>
      <div className="segments-table-div" style={{ position: 'relative' }}>
        <span>
          {flag === true ? (
            <span style={{ position: 'absolute', left: 10, top: 10 }}>
              <Button
                style={{ fontSize: '20px', fontWeight: 500 }}
                variant="text"
                onClick={(e) => back()}
              >
                <IoArrowBack />
                <span className="ms-1" style={{ fontSize: '14px' }}>
                  Back
                </span>
              </Button>
            </span>
          ) : null}
        </span>
        <span>
          {' '}
          <h3 className="text-center">Audio Segments</h3>
        </span>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'row',
            alignItems: 'baseline',
          }}
        >
          {pauseGeneration === false &&
            (flag === false && nextClicked === false ? (
              <div
                className="me-5"
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  flexDirection: 'row',
                }}
              >
                <span
                  style={{
                    marginRight: '10px',
                    fontSize: '14px',
                    fontWeight: 500,
                  }}
                >
                  Audio Generation is in progress
                </span>
                <Spinner size="sm" animation="border" variant="success" />
              </div>
            ) : null)}
          {pauseGeneration === false && flag === false ? (
            <Button
              className="ms-2 me-2 mb-2"
              variant="outline-dark"
              style={{ fontWeight: 500 }}
              onClick={(e) => pauseSegmentsGeneration()}
            >
              <span className="me-1">
                <FaPause />
              </span>
              Pause
            </Button>
          ) : pauseGeneration === true && flag === false ? (
            <Button
              className="ms-2 me-2 mb-2"
              variant="outline-dark"
              style={{ fontWeight: 500 }}
              onClick={(e) => resumeSegmentsGeneration()}
            >
              <span className="me-1">
                <FaPlay />
              </span>
              Resume
            </Button>
          ) : null}

          {flag === true || pauseGeneration === true ? (
            <Button
              variant="outline-dark"
              style={{ fontWeight: 500 }}
              onClick={handleSpeakerShow}
            >
              <span className="me-1">
                <RiUserVoiceFill />
              </span>
              Speakers
            </Button>
          ) : null}
          {flag === true ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'baseline',
              }}
            >
              <Button
                className="ms-2 mb-2"
                variant="outline-dark"
                style={{ fontWeight: 500 }}
                onClick={(e) => handleDownloadClick()}
              >
                <span>
                  <FaDownload />
                </span>{' '}
                Download All
              </Button>
            </div>
          ) : null}
        </div>
        <div className="tableDiv">
          <Table striped bordered className="mt-2">
            <thead>
              <tr>
                <th>No.</th>
                <th>Speaker</th>
                <th>FileName</th>
                <th>Status</th>
                <th>
                  <span>Duration</span>
                  <span style={{ fontSize: '16px' }}>
                    <InformationCircleTooltip
                      id="more-variable"
                      text="( dubbed / original )"
                    />
                  </span>
                </th>
                <th>Play</th>
                <th>Actions</th>
                {/* <th>Download</th>
                <th>Upload</th> */}
              </tr>
            </thead>
            <tbody>
              {audioSegments.map((data, index) => (
                <tr key={data.Id}>
                  <td style={{ verticalAlign: 'middle' }}>{index + 1}</td>
                  {data.FaceUrl !== '' ? (
                    <td style={{ verticalAlign: 'middle' }}>
                      <div>
                        <Image
                          src={data.FaceUrl}
                          roundedCircle
                          style={{
                            height: '40px',
                            width: '40px',
                            marginRight: '5px',
                          }}
                        />
                        <span style={{ verticalAlign: 'middle' }}>
                          {data.SpeakerName}
                        </span>
                      </div>
                    </td>
                  ) : (
                    <td>
                      <span
                        style={{
                          fontSize: '40px',
                          marginRight: '5px',
                          verticalAlign: 'middle',
                        }}
                      >
                        <FaUserCircle />
                      </span>
                      <span style={{ verticalAlign: 'middle' }}>
                        {data.SpeakerName}
                      </span>
                    </td>
                  )}

                  <td style={{ verticalAlign: 'middle' }}>{data.FileName}</td>
                  {data.Status === 0 ? (
                    <td style={{ verticalAlign: 'middle' }}>Pending</td>
                  ) : data.Status === 1 ? (
                    <td style={{ verticalAlign: 'middle' }}>Processing</td>
                  ) : (
                    <td style={{ verticalAlign: 'middle' }}>Ready</td>
                  )}
                  {data.Status === 2 ? (
                    <td style={{ verticalAlign: 'middle' }}>
                      {parseFloat(data.Duration.toFixed(2))}
                      <span
                        style={{
                          color: 'grey',
                          marginLeft: '3px',
                          fontWeight: 500,
                          fontSize: '14px',
                        }}
                      >
                        s
                      </span>{' '}
                      / {parseFloat(data.TranscriptDuration.toFixed(2))}
                      <span
                        style={{
                          color: 'grey',
                          marginLeft: '3px',
                          fontWeight: 500,
                          fontSize: '14px',
                        }}
                      >
                        s
                      </span>
                    </td>
                  ) : (
                    <td></td>
                  )}
                  <td style={{ verticalAlign: 'middle', width: '25%' }}>
                    {flag2 === true ? (
                      <div>
                        {data.Status === 2 ? (
                          <audio
                            preload="none"
                            style={{ height: '40px' }}
                            key={data.Id}
                            className="audioFiles"
                            src={`${data.AudioSegmentUrl.trim()}?random=${Math.random()}`}
                            controls
                            disablepictureinpicture
                            controlslist="nodownload noplaybackrate"
                          />
                        ) : null}
                      </div>
                    ) : (
                      <div>
                        {data.Status === 2 ? (
                          <audio
                            preload="none"
                            style={{ height: '40px' }}
                            key={data.Id}
                            className="audioFiles"
                            // src={`${data.AudioSegmentUrl.trim()}?random=${Math.random()}`}
                            controls
                            disablepictureinpicture
                            controlslist="nodownload noplaybackrate"
                          />
                        ) : null}
                      </div>
                    )}
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <div style={{ display: 'flex' }}>
                      {flag2 === true ? (
                        <div style={{ border: 'none' }}>
                          {data.Status === 2 ? (
                            <Button
                              style={{ border: 'none' }}
                              className="custom-button"
                              variant="text"
                              onClick={(e) => editAudioSegment(data.Id, index)}
                            >
                              <FaEdit />
                            </Button>
                          ) : null}
                        </div>
                      ) : (
                        <div style={{ border: 'none' }}>
                          {data.Status === 2 ? (
                            <Button
                              style={{ border: 'none' }}
                              disabled
                              className="custom-button"
                              variant="text"
                              onClick={(e) => editAudioSegment(data.Id, index)}
                            >
                              <FaEdit />
                            </Button>
                          ) : null}
                        </div>
                      )}
                      {flag2 === true ? (
                        <div>
                          {' '}
                          {data.Status === 2 ? (
                            <span
                              style={{
                                display: 'flex',
                                justifyContent: 'space-evenly',
                              }}
                            >
                              <Button
                                style={{ border: 'none' }}
                                className="custom-button"
                                variant="text"
                                onClick={(e) =>
                                  viewProject(
                                    data.FileName,
                                    data.Id,
                                    data.Duration
                                  )
                                }
                              >
                                <FaUpload />
                              </Button>
                              <Button
                                style={{ border: 'none' }}
                                className="custom-button"
                                variant="text"
                                onClick={(e) => {
                                  handleRecordShow(
                                    data.FileName,
                                    data.Id,
                                    data.TranscriptDuration
                                  );
                                }}
                              >
                                <FaMicrophone />
                              </Button>
                            </span>
                          ) : null}
                        </div>
                      ) : (
                        <div>
                          {' '}
                          {data.Status === 2 ? (
                            <span
                              style={{
                                display: 'flex',
                                justifyContent: 'space-evenly',
                                border: 'none',
                              }}
                            >
                              <Button
                                style={{ border: 'none' }}
                                className="custom-button"
                                disabled
                                variant="text"
                                onClick={(e) =>
                                  viewProject(
                                    data.FileName,
                                    data.Id,
                                    data.Duration
                                  )
                                }
                              >
                                <FaUpload />
                              </Button>
                              <Button
                                style={{ border: 'none' }}
                                className="custom-button"
                                disabled
                                variant="text"
                                onClick={(e) => {
                                  handleRecordShow(
                                    data.FileName,
                                    data.Id,
                                    data.TranscriptDuration
                                  );
                                }}
                              >
                                <FaMicrophone />
                              </Button>
                            </span>
                          ) : null}
                        </div>
                      )}
                      {flag2 === true ? (
                        <div>
                          {data.Status === 2 ? (
                            <a href={data.AudioSegmentUrl}>
                              <Button className="custom-button" variant="text">
                                <FaDownload />
                              </Button>
                            </a>
                          ) : null}
                        </div>
                      ) : (
                        <div>
                          {data.Status === 2 ? (
                            <Button
                              className="custom-button"
                              disabled
                              variant="text"
                            >
                              <FaDownload />
                            </Button>
                          ) : null}
                        </div>
                      )}
                    </div>
                  </td>

                  {/* <td style={{ verticalAlign: 'middle' }}>{data.Status === 2 ? <Button variant='text' onClick={(e) => viewProject(data.FileName, data.Id, data.Duration)}><FaUpload /></Button> : ""}</td> */}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <Modal
          size="xl"
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ textAlign: 'center' }}>
              {segmentData.FileName}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            className="edit-audio"
            style={{ height: '65vh', overflowY: 'auto' }}
          >
            <AudioSegmentPage2
              setSelectedSegmentComponent={setSelectedSegmentComponent}
              audioSegments={audioSegments}
              segmentData={segmentData}
              projectId={projectId}
              segmentId={segmentId}
              setAudioSegments={setAudioSegments}
              handleClose={handleClose}
            />
          </Modal.Body>
        </Modal>

        {/* Modal for upload----------------------------------------------------------------------------------------->*/}
        <Modal
          size="lg"
          show={showUpload}
          onHide={handleUploadClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ textAlign: 'center' }}>
              {fileName}
            </Modal.Title>
          </Modal.Header>

          <UploadAudio
            handleUploadClose={handleUploadClose}
            audioId={audioId}
            projectId={projectId}
            audioDuration={audioDuration}
            languageId={languageId}
            fileName={fileName}
            audioSegments={audioSegments}
            setAudioSegments={setAudioSegments}
          />
        </Modal>
      </div>
      <Modal show={showWarning} onHide={handleWarningClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Speech durations are longer than original which could yield undesired
          results. Are you sure you want to continue?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleWarningClose}>
            Cancel
          </Button>
          <Button
            variant="warning"
            onClick={(e) => {
              setProceed(true);
            }}
          >
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="nxtBtnContainer">
        {flag === true && pauseGeneration === false ? (
          <button
            className="nxtBtn "
            onClick={(e) => {
              handleNext();
            }}
          >
            Next
          </button>
        ) : flag === true && nextClicked === true ? (
          <div>
            {' '}
            <button className="nxtBtn" disabled>
              Next
            </button>{' '}
            <span>
              <img
                style={{ width: '50px', height: '50px', marginLeft: '10px' }}
                src={loading}
                alt="loading"
              />
            </span>{' '}
          </div>
        ) : (
          <button className="nxtBtn" disabled>
            Next
          </button>
        )}
      </div>

      <Modal show={speakerShow} onHide={handleSpeakerClose}>
        <Modal.Header closeButton>
          <Modal.Title>Speakers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ textAlign: 'center' }}>
            <Col md={6}>
              <span style={{ fontWeight: 500 }}>Speaker</span>
            </Col>
            <Col md={6}>
              <span style={{ fontWeight: 500 }}>Voice</span>
            </Col>
          </Row>
          {speakers &&
            speakers.map((speaker) => (
              <div key={speaker.Id}>
                <Row>
                  <Col md={6}>
                    <Form.Control
                      className="mt-4"
                      type="text"
                      value={speaker.Name}
                      onChange={(e) => {
                        nameChange(speaker.Id, e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={6}>
                    <Form.Select
                      className="mt-4"
                      onChange={(e) => {
                        voiceChange(speaker.Id, e.target.value);
                      }}
                    >
                      <option value={speaker.VoiceId}>
                        {(voicesArray &&
                          (
                            voicesArray.find(
                              (voice) => voice.Id === speaker.VoiceId
                            ) || voicesArray[0].Id
                          ).Name) ||
                          speaker.VoiceId}
                      </option>

                      {voicesArray &&
                        voicesArray.map((voice) => (
                          <option key={voice.Id} value={voice.Id}>
                            {voice.Name}
                          </option>
                        ))}
                    </Form.Select>
                  </Col>
                </Row>
              </div>
            ))}
          <Button
            variant="link"
            style={{
              marginRight: '1rem',
              marginTop: '1rem',
              // backgroundColor: 'blue',
              border: 'none',
            }}
            size="sm"
            onClick={handleAddSpeaker}
          >
            <span style={{ cursor: 'pointer' }}>+ Add Speaker</span>
          </Button>
          <Row className="mt-4 mb-2">
            <Col md={6}>
              <Button
                variant="secondary"
                onClick={handleSpeakerClose}
                style={{ width: '100%' }}
              >
                Cancel
              </Button>
            </Col>
            <Col md={6}>
              <Button
                variant="primary"
                onClick={(e) => {
                  updateProjectSpeaker();
                }}
                style={{ width: '100%' }}
              >
                Confirm
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      {/* Modal for record----------------------------------------------------------------------------------------->*/}
      <Modal
        size="xl"
        show={showRecord}
        onHide={handleRecordClose}
        backdrop="static"
        keyboard={false}
        style={{ overflow: 'auto' }}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ textAlign: 'center' }}>
            Record audio
          </Modal.Title>
        </Modal.Header>
        <RecordAudio
          handleRecordClose={handleRecordClose}
          segmentName={segmentName}
          segmentData={segmentData}
          languageId={languageId}
          projectId={projectId}
          audioId={audioId}
          audioDuration={audioDuration}
          videoUrl={videoUrl}
          setAudioSegments={setAudioSegments}
        />
      </Modal>
      <ToastContainer />
    </div>
  );
}
