import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { IoArrowBack } from 'react-icons/io5';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from 'yup';

import 'react-toastify/dist/ReactToastify.css';
import { Phone } from '@mui/icons-material';
import { Organizations } from 'aws-sdk';
import { useNavigate } from 'react-router-dom';

export default function EditUser({
  user,
  setUser,
  setSelectedAdminComponent,
  addUserClicked,
  setAddUserClicked,
}) {
  const navigate = useNavigate();
  const apiURL = process.env.REACT_APP_API_URL;
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState(null);

  const [limitCredits, setLimitCredits] = useState(0);
  const [dubbingClient, setDubbingClient] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [limitVideoLength, setLimitVideoLength] = useState(0);
  const [limitVoices, setLimitVoices] = useState(0);
  const [subscriptionAmount, setSubscriptionAmount] = useState(0);
  const [subscriptionDuration, setSubscriptionDuration] = useState(0);
  const [subscriptionName, setSubscriptionName] = useState('');
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState(0);
  const [usageCredits, setUsageCredits] = useState(0);
  const [usageVoices, setUsageVoices] = useState(0);
  const [userStatus, setUserStatus] = useState(0);
  const [voiceDeleteAllowed, setVoiceDeleteAllowed] = useState(false);
  const [voiceEditAllowed, setVoiceEditAllowed] = useState(false);
  const [transcendAllowed, setTranscendAllowed] = useState(false);
  const [voiceTransferAllowed, setVoiceTransferAllowed] = useState(false);
  const [translateAllowed, setTranslateAllowed] = useState(false);
  const [voiceLibraryAllowed, setVoiceLibraryAllowed] = useState(false);
  const [voiceLabAllowed, setVoiceLabAllowed] = useState(false);
  const [voiceCreationAllowed, setVoiceCreationAllowed] = useState(false);
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [organizationName, setOrganizationName] = useState('');
  const [orgs, setOrgs] = useState([]);
  const [orgId, setOrgId] = useState(null);
  const [phone, setPhone] = useState('');
  const [country, setCountry] = useState('India');
  const [useCase, setUseCase] = useState('');

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const notifySuccess = () => {
    toast.success('User info is updated successfully.', {
      className: 'custom-success-toast',
    });
  };

  const getAllOrg = async () => {
    const reqBody = {
      UserId: localStorage.getItem('userSessionId'),
    };
    try {
      const response = await fetch(`${apiURL}/web/get_all_organizations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reqBody),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.IsValid) {
          setOrgs(data.OrgList);
          // setOrgId(data.OrgList.length > 0 ? data.OrgList[0].OrgId : '');
        }
      } else {
        throw new Error('Failed to fetch project data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  //////////
  useEffect(() => {
    getAllOrg();
    const request = {
      UserId: localStorage.getItem('userSessionId'),
    };
    fetch(`${apiURL}/web/get_all_subscriptions`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers if needed
      },
      body: JSON.stringify(request),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the data from the API
        if (data.IsValid) {
          setSubscriptionList(data.SubscriptionList);
          if (addUserClicked === true) {
            if (data.SubscriptionList.length > 0) {
              const firstSubscription = data.SubscriptionList[0];
              setSubscriptionName(firstSubscription.Name);
              setSubscriptionId(firstSubscription.Id);
              setSubscriptionAmount(firstSubscription.Amount);
              setSubscriptionDuration(firstSubscription.Duration);
              setLimitVideoLength(firstSubscription.LimitVideoLength);
              setLimitCredits(firstSubscription.LimitCredits);
              setLimitVoices(firstSubscription.LimitVoices);
              setVoiceEditAllowed(firstSubscription.VoiceEditAllowed);
              setVoiceDeleteAllowed(firstSubscription.VoiceDeleteAllowed);
              setTranscendAllowed(true);
              setTranslateAllowed(true);
              setVoiceLibraryAllowed(true);
              setVoiceTransferAllowed(true);
              setVoiceTransferAllowed(false);
              setVoiceLabAllowed(false);
              setVoiceCreationAllowed(false);
              setDubbingClient(false);
              setAdmin(false);
              setSubscriptionStatus(1);
              const currentDate = new Date().toISOString().split('T')[0];
              setStartDate(currentDate);
              setUserStatus(2);
            }
          }

          // setFilteredUserList(data.AllUsers); // Set filteredUserList initially with all users
        }
      })
      .catch((error) => {
        // Handle errors
        console.error('Error:', error);
      });
  }, []);
  ///////

  const options = orgs.map((data) => ({
    value: data.OrgId,
    label: data.OrgName,
  }));

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate().toString().padStart(2, '0')}-${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, '0')}-${date.getFullYear()}`;
  };

  useEffect(() => {
    setName(user.Name);
    setOrganizationName(user.OrganizationName);
    setOrgId(user.OrganizationId);
    setEmail(user.Email);
    setPhone(user.Phone);
    setCountry(user.Country);
    setLimitCredits(user.LimitCredits);
    setDubbingClient(user.IsDubbingClient);
    setAdmin(user.IsAdmin);
    setLimitVideoLength(user.LimitVideoLength);
    setLimitVoices(user.LimitVoices);
    setSubscriptionAmount(user.SubscriptionAmount);
    setSubscriptionDuration(user.SubscriptionDuration);
    setSubscriptionId(user.SubscriptionId);
    setSubscriptionName(user.SubscriptionName);
    setSubscriptionStatus(user.SubscriptionStatus);
    setUsageCredits(user.UsageCredits);
    setUsageVoices(user.UsageVoices);
    setUserStatus(user.UserStatus);
    setVoiceDeleteAllowed(user.VoiceDeleteAllowed);
    setVoiceEditAllowed(user.VoiceEditAllowed);
    setTranscendAllowed(user.TranscendAccess);
    setTranslateAllowed(user.TranslateAccess);
    setVoiceTransferAllowed(user.VTAccess);
    setVoiceLibraryAllowed(user.VoiceLibAccess);
    setVoiceLabAllowed(user.VoiceLabAccess);
    setVoiceCreationAllowed(user.VoiceLabAccess);
    setStartDate(formatDate(user.StartDate).split('-').reverse().join('-'));
    setEndDate(formatDate(user.EndDate).split('-').reverse().join('-'));
  }, [user]);

  useEffect(() => {
    console.log(
      'start date and end date are ',
      startDate,
      endDate,
      typeof startDate
    );
  }, [startDate, endDate]);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    switch (name) {
      case 'dubbingClient':
        setDubbingClient(checked);
        break;
      case 'admin':
        setAdmin(checked);
        break;
      case 'voiceDeleteAllowed':
        setVoiceDeleteAllowed(checked);
        break;
      case 'voiceEditAllowed':
        setVoiceEditAllowed(checked);
        break;
      case 'transcendAllowed':
        setTranscendAllowed(checked);
        break;
      case 'voiceTransferAllowed':
        setVoiceTransferAllowed(checked);
        break;
      case 'translateAllowed':
        setTranslateAllowed(checked);
        break;
      case 'voiceLibraryAllowed':
        setVoiceLibraryAllowed(checked);
        break;
      case 'voiceLabAllowed':
        setVoiceLabAllowed(checked);
        break;
      case 'voiceCreationAllowed':
        setVoiceCreationAllowed(checked);
        break;
      default:
        break;
    }
  };

  function cancelChanges() {
    setUser({});
    setSelectedAdminComponent('manage-users');
    navigate('/admin/manage-users');
  }

  function updateUser() {
    const request = {
      AdminUserId: localStorage.getItem('userSessionId'),
      UserId: user.Id,
      UserName: name,
      OrganizationName: organizationName,
      OrganizationId: orgId,
      SubscriptionId: subscriptionId,
      Email: email,
      Password: password,
      Phone: phone,
      Country: country,
      UseCase: useCase,
      LimitCredits: limitCredits,
      IsDubbingClient: dubbingClient,
      IsAdmin: admin,
      LimitVideoLength: limitVideoLength,
      LimitVoices: limitVoices,
      SubscriptionAmount: subscriptionAmount,
      SubscriptionDuration: subscriptionDuration,
      SubscriptionName: subscriptionName,
      SubscriptionStatus: subscriptionStatus,
      UsageCredits: usageCredits,
      UsageVoices: usageVoices,
      UserStatus: userStatus,
      VoiceDeleteAllowed: voiceDeleteAllowed,
      VoiceLabAccess: voiceLabAllowed,
      VoiceLibAccess: voiceLibraryAllowed,
      VTAccess: voiceTransferAllowed,
      VoiceCreationAccess: voiceCreationAllowed,
      VoiceEditAllowed: voiceEditAllowed,
      TranscendAccess: transcendAllowed,
      TranslateAccess: translateAllowed,
      StartDate: startDate + ' 00:00:00.000000',
      EndDate: endDate + ' 00:00:00.000000',
    };
    fetch(`${apiURL}/web/edit_user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers if needed
      },
      body: JSON.stringify(request),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the data from the API
        if (data.IsValid) {
          notifySuccess();
          setSelectedAdminComponent('manage-users');
          navigate('/admin/manage-users');
        }
      })
      .catch((error) => {
        // Handle errors
        console.error('Error:', error);
      });
  }

  function addUser() {
    const request = {
      AdminUserId: localStorage.getItem('userSessionId'),
      UserId: null,
      UserName: name,
      OrganizationName: organizationName,
      OrganizationId: orgId,
      SubscriptionId: subscriptionId,
      Email: email,
      Password: password,
      Phone: phone,
      Country: country,
      UseCase: useCase,
      LimitCredits: limitCredits,
      IsDubbingClient: dubbingClient,
      IsAdmin: admin,
      LimitVideoLength: limitVideoLength,
      LimitVoices: limitVoices,
      SubscriptionAmount: subscriptionAmount,
      SubscriptionDuration: subscriptionDuration,
      SubscriptionName: subscriptionName,
      SubscriptionStatus: subscriptionStatus,
      UsageCredits: usageCredits,
      UsageVoices: usageVoices,
      UserStatus: userStatus,
      VoiceDeleteAllowed: voiceDeleteAllowed,
      VoiceLabAccess: voiceLabAllowed,
      VoiceLibAccess: voiceLibraryAllowed,
      VTAccess: voiceLibraryAllowed,
      VoiceCreationAccess: voiceCreationAllowed,
      VoiceEditAllowed: voiceEditAllowed,
      TranscendAccess: transcendAllowed,
      TranslateAccess: translateAllowed,
      StartDate: startDate + ' 00:00:00.000000',
      // EndDate: endDate + ' 00:00:00.000000',
    };
    fetch(`${apiURL}/web/add_user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers if needed
      },
      body: JSON.stringify(request),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the data from the API
        if (data.IsValid) {
          notifySuccess();
          setSelectedAdminComponent('manage-users');
          navigate('/admin/manage-users');
        }
      })
      .catch((error) => {
        // Handle errors
        console.error('Error:', error);
      });
  }

  const handleSubscriptionChange = (e) => {
    const selectedSubscription = subscriptionList.find(
      (subscription) => subscription.Name === e.target.value
    );

    if (selectedSubscription) {
      setSubscriptionName(selectedSubscription.Name);
      setSubscriptionId(selectedSubscription.Id);
      setSubscriptionAmount(selectedSubscription.Amount);
      setSubscriptionDuration(selectedSubscription.Duration);
      setLimitVideoLength(selectedSubscription.LimitVideoLength);
      setLimitCredits(selectedSubscription.LimitCredits);
      setLimitVoices(selectedSubscription.LimitVoices);
      setVoiceEditAllowed(selectedSubscription.VoiceEditAllowed);
      setVoiceDeleteAllowed(selectedSubscription.VoiceDeleteAllowed);
    }
  };
  function validateEmail(email) {
    if (!email) {
      notifyError('Email is required');
      return false;
    } else if (!email.includes('@')) {
      notifyError('Invalid email format');
      return false;
    }
    return true;
  }

  function validatePassword(password) {
    const passwordRegex =
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    if (!password) {
      notifyError('Password is required');
      return false;
    } else if (!passwordRegex.test(password)) {
      notifyError(
        'Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character'
      );
      return false;
    }
    return true;
  }

  const notifyError = (message) => {
    toast.error(message, {
      className: 'custom-error-toast',
    });
  };

  function handleSubmit(e) {
    e.preventDefault();
    if (addUserClicked === true) {
      if (
        !validateEmail(email) ||
        !validatePassword(password) ||
        (!validateEmail(email) && !validatePassword(password))
      ) {
        return;
      }
      addUser();
    } else {
      updateUser();
    }
  }

  useEffect(() => {
    console.log(organizationName);
    console.log(orgId);
  }, [orgId]);

  return (
    <div
      className="edit-user-details mt-4 ms-5 me-5"
      style={{ position: 'relative' }}
    >
      <span>
        <span style={{ position: 'absolute', left: 20, top: 10 }}>
          <Button
            style={{ fontSize: '20px', fontWeight: 500 }}
            variant="text"
            onClick={(e) => cancelChanges()}
          >
            <IoArrowBack />
            <span className="ms-1" style={{ fontSize: '14px' }}>
              Back
            </span>
          </Button>
        </span>
      </span>
      <Form className="mt-4" onSubmit={(e) => handleSubmit(e)}>
        <Form.Group
          className="mb-4"
          controlId="formBasicName"
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '20px',
            alignItems: 'center',
          }}
        >
          <Form.Label style={{ width: '10%' }}>Name:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{ width: '30%' }}
          />
        </Form.Group>

        <Form.Group
          className="mb-4"
          controlId="formBasicName"
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '20px',
            alignItems: 'center',
          }}
        >
          {/* <Form.Label style={{ width: '10%' }}>Organization Name:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Organization"
            value={organizationName}
            onChange={(e) => setOrganizationName(e.target.value)}
            style={{ width: '30%' }}
          /> */}

          <Form.Label style={{ width: '10%' }}>Organization Name:</Form.Label>
          {orgs.length > 0 && (
            <Form.Select
              value={orgId || ''}
              onChange={(e) => {
                const selectedOrg = orgs.find(
                  (org) => org.OrgId === e.target.value
                );
                if (selectedOrg) {
                  setOrganizationName(selectedOrg.OrgName);
                  setOrgId(selectedOrg.OrgId);
                } else {
                  setOrganizationName('');
                  setOrgId('');
                }
              }}
              style={{ width: '30%' }}
            >
              <option value="" disabled hidden>
                Select one
              </option>
              {orgs.map((data) => (
                <option key={data.OrgId} value={data.OrgId}>
                  {data.OrgName}
                </option>
              ))}
            </Form.Select>
          )}
        </Form.Group>

        <Form.Group
          className="mb-4"
          controlId="formBasicEmail"
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '20px',
            alignItems: 'center',
          }}
        >
          <Form.Label style={{ width: '10%' }}>Email address:</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ width: '30%' }}
            // readOnly={!addUserClicked}
          />

          <>
            <Form.Label style={{ width: '10%' }}>Password:</Form.Label>
            <Form.Control
              type="password"
              placeholder={
                addUserClicked ? 'Enter Password' : 'Set New Password'
              }
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{ width: '30%' }}
            />
          </>
        </Form.Group>

        <>
          <Form.Group
            className="mb-4"
            controlId="formBasicName"
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '20px',
              alignItems: 'center',
            }}
          >
            <Form.Label style={{ width: '10%' }}>Phone:</Form.Label>
            <Form.Control
              type="text/number"
              placeholder="ex: 999999999"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              style={{ width: '30%' }}
              // disabled={!addUserClicked}
            />

            <Form.Label style={{ width: '10%' }}>Country:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              style={{ width: '10%' }}
            />
          </Form.Group>
        </>

        <Form.Group
          className="mb-4"
          controlId="formBasicUserStatus"
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '20px',
            alignItems: 'center',
          }}
        >
          <Form.Label style={{ width: '10%' }}>User Status:</Form.Label>
          <Form.Select
            value={userStatus}
            onChange={(e) => setUserStatus(parseInt(e.target.value))}
            style={{ width: '10%' }}
          >
            <option value={0}>New</option>
            <option value={1}>Verified</option>
            <option value={2}>Active</option>
            <option value={3}>Inactive</option>
            <option value={4}>Deleted</option>
          </Form.Select>
        </Form.Group>

        <Form.Group
          className="mb-3"
          controlId="formBasicSubscriptionName"
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '20px',
            alignItems: 'center',
          }}
        >
          <Form.Label style={{ width: '10%' }}>Subscription Name:</Form.Label>
          {subscriptionList.length > 0 && (
            <Form.Select
              type="text"
              placeholder="Subscription Name"
              value={subscriptionName}
              // onChange={(e) => setSubscriptionName(e.target.value)}
              onChange={handleSubscriptionChange}
              style={{ width: '30%' }}
            >
              {subscriptionList.map((data, index) => (
                <option key={data.Id}>{data.Name || ''}</option>
              ))}
            </Form.Select>
          )}
        </Form.Group>

        <Form.Group
          className="mb-3"
          controlId="formBasicSubscriptionAmount"
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '20px',
            alignItems: 'center',
          }}
        >
          <Form.Label style={{ width: '10%' }}>Subscription Status:</Form.Label>
          <Form.Select
            value={subscriptionStatus}
            onChange={(e) => setSubscriptionStatus(parseInt(e.target.value))}
            style={{ width: '10%' }}
          >
            <option value={0}>Inactive</option>
            <option value={1}>Active</option>
          </Form.Select>

          <Form.Label className="ms-4" style={{ width: '8%' }}>
            Subscription Amount:
          </Form.Label>
          <Form.Control
            type="number"
            placeholder="Subscription Amount"
            value={subscriptionAmount}
            onChange={(e) => setSubscriptionAmount(parseInt(e.target.value))}
            style={{ width: '10%', textAlign: 'center' }}
            readOnly={!addUserClicked}
          />
          <Form.Label className="ms-4" style={{ width: '8%' }}>
            Subscription Duration:
          </Form.Label>
          <Form.Control
            type="number"
            placeholder="Subscription Duration"
            value={subscriptionDuration}
            onChange={(e) => setSubscriptionDuration(parseInt(e.target.value))}
            style={{ width: '10%', textAlign: 'center' }}
          />
        </Form.Group>

        <Form.Group
          className="mb-4"
          controlId="formBasicDates"
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '20px',
            alignItems: 'center',
          }}
        >
          <Form.Label style={{ width: '10%' }}>Start Date:</Form.Label>
          <Form.Control
            type="date"
            value={startDate}
            style={{ width: '10%' }}
            onChange={(e) => setStartDate(e.target.value)}
          />

          <>
            <Form.Label className="ms-4" style={{ width: '8%' }}>
              End Date:
            </Form.Label>
            <Form.Control
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              style={{ width: '10%' }}
              disabled={addUserClicked}
            />
          </>
        </Form.Group>

        <Form.Group
          className="mb-4"
          controlId="formBasicLimitCredits"
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '20px',
            alignItems: 'center',
          }}
        >
          <Form.Label style={{ width: '10%' }}>Limit Credits:</Form.Label>
          <Form.Control
            type="number"
            placeholder="Limit Credits"
            value={limitCredits}
            onChange={(e) => setLimitCredits(parseInt(e.target.value))}
            style={{ width: '10%', textAlign: 'center' }}
          />

          <Form.Label className="ms-4" style={{ width: '8%' }}>
            Limit Voices:
          </Form.Label>
          <Form.Control
            type="number"
            placeholder="Limit Voices"
            value={limitVoices}
            onChange={(e) => setLimitVoices(parseInt(e.target.value))}
            style={{ width: '10%', textAlign: 'center' }}
          />

          <Form.Label className="ms-4" style={{ width: '8%' }}>
            Limit Video Length:
          </Form.Label>
          <Form.Control
            type="number"
            placeholder="Limit Video Length"
            value={limitVideoLength}
            onChange={(e) => setLimitVideoLength(parseInt(e.target.value))}
            style={{ width: '10%' }}
          />
        </Form.Group>

        <Form.Group
          className="mb-4"
          controlId="formBasicUsageCredits"
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '20px',
            alignItems: 'center',
          }}
        >
          <Form.Label style={{ width: '10%' }}>Usage Credits:</Form.Label>
          <Form.Control
            type="number"
            placeholder="Usage Credits"
            value={usageCredits}
            onChange={(e) => setUsageCredits(parseInt(e.target.value))}
            style={{ width: '10%', textAlign: 'center' }}
            readOnly={!addUserClicked}
          />
          <Form.Label className="ms-4" style={{ width: '8%' }}>
            Usage Voices:
          </Form.Label>
          <Form.Control
            type="number"
            placeholder="Usage Voices"
            value={usageVoices}
            onChange={(e) => setUsageVoices(parseInt(e.target.value))}
            style={{ width: '10%', textAlign: 'center' }}
            readOnly={!addUserClicked}
          />
        </Form.Group>

        <Form.Group
          className="mb-4"
          controlId="formBasicPErmissions"
          style={{
            display: 'flex',
            flexDirection: 'row',
            // flexWrap: 'wrap',
            gap: '20px',
            // alignItems: 'center',
          }}
        >
          <Form.Label style={{ width: '10%' }}>Permissions:</Form.Label>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: '20px',
              alignItems: 'center',
            }}
          >
            <Form.Check
              type="checkbox"
              label="Voice Delete"
              checked={voiceDeleteAllowed}
              name="voiceDeleteAllowed"
              onChange={handleCheckboxChange}
            />
            <Form.Check
              type="checkbox"
              label="Voice Edit"
              checked={voiceEditAllowed}
              name="voiceEditAllowed"
              onChange={handleCheckboxChange}
            />

            <Form.Check
              type="checkbox"
              label="Dubbing Client"
              checked={dubbingClient}
              name="dubbingClient"
              onChange={handleCheckboxChange}
            />

            <Form.Check
              type="checkbox"
              label="Admin"
              checked={admin}
              name="admin"
              onChange={handleCheckboxChange}
            />
            <Form.Check
              type="checkbox"
              label="Transcend"
              checked={transcendAllowed}
              name="transcendAllowed"
              onChange={handleCheckboxChange}
            />
            <Form.Check
              type="checkbox"
              label="Voice Transfer"
              checked={voiceTransferAllowed}
              name="voiceTransferAllowed"
              onChange={handleCheckboxChange}
            />
            <Form.Check
              type="checkbox"
              label="Translate"
              checked={translateAllowed}
              name="translateAllowed"
              onChange={handleCheckboxChange}
            />
            <Form.Check
              type="checkbox"
              label="Voice lib"
              checked={voiceLibraryAllowed}
              name="voiceLibraryAllowed"
              onChange={handleCheckboxChange}
            />
            <Form.Check
              type="checkbox"
              label="Voice Lab"
              checked={voiceLabAllowed}
              name="voiceLabAllowed"
              onChange={handleCheckboxChange}
            />
            <Form.Check
              type="checkbox"
              label="Voice Creation"
              checked={voiceCreationAllowed}
              name="voiceCreationAllowed"
              onChange={handleCheckboxChange}
            />
          </div>
        </Form.Group>
        <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
          {/* <Button variant="danger" onClick={(e)=>cancelChanges()}>Cancel</Button> */}
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </div>
      </Form>
      <ToastContainer />
    </div>
  );
}
