import Dropdown from "react-bootstrap/Dropdown";
import AspectRatio from "./AspectRatio";
import Background from "./Background";
// import Upload from './Upload';
import BackgroundAndUpload from "./Upload";
import Duration from "./Duration";
import { Col, Row } from "react-bootstrap";

export default function ProjectSettings() {
  return (
    <div>
      <div>
        <h3 className="mt-4">Project Settings</h3>
      </div>

      {/* <div className='scrollableDiv'
          
              >
            <AspectRatio/>
           <BackgroundAndUpload/>
           <Duration/>
            </div> */}
    </div>
  );
}
