import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import Spinner from "react-bootstrap/Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function SelectSpeakerPic({
  handleCloseDp,
  projectId,
  speakerId,
  setFaceList,
  speakerVoiceId,
  speaker_Name,
  getSpeakers,
  languageId,
}) {
  const apiURL = process.env.REACT_APP_API_URL;
  const [photos, setPhotos] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [flag, setFlag] = useState(false);

  const notify = (error) =>
    toast.error(error, {
      className: "custom-error-toast",
    });

  const handleImageClick = (id, url) => {
    console.log("id and url are ", id, url);
    // Check if the image is already selected
    const isSelected = selectedImages.some((image) => image.id === id);

    if (isSelected) {
      // If selected, remove it from the selectedImages state
      const updatedImages = selectedImages.filter((image) => image.id !== id);
      setSelectedImages(updatedImages);
    } else {
      // If not selected, add it to the selectedImages state
      setSelectedImages((prevImages) => [...prevImages, { id, url }]);
    }
  };

  useEffect(() => {
    const requestBody = {
      ProjectId: projectId,
    };

    // Fetch images from the API

    function getAllFaces() {
      fetch(`${apiURL}/web/get_all_faces`, {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(requestBody),
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.IsValid) {
            console.log("response for get all faces is ", res);
            if (res.AllFacesReady === true) {
              setFlag(false);
              clearInterval(intervalId);
            } else {
              setFlag(true);
            }
            setPhotos([...(res.Faces || [])]);
          }
        })
        .catch((error) => console.error("Error fetching photos:", error));
    }
    getAllFaces();
    const intervalId = setInterval(getAllFaces, 5000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    console.log("selected images are :", selectedImages);
  }, [selectedImages]);

  // update face api call function-------------------------------------------------------------------------->
  function mapFaces() {
    const request = {
      ProjectId: projectId,
      SpeakerList: [
        {
          Id: speakerId,
          FaceIds: selectedImages.map((image) => image.id),
          Name: speaker_Name,
          VoiceId: speakerVoiceId !== undefined ? speakerVoiceId : null,
          ProjectId: projectId,
          LangId: languageId,
        },
      ],
    };
    console.log("request for updating speaker face is : ", request);
    fetch(`${apiURL}/web/update_project_speaker`, {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(request),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.IsValid) {
          console.log("update speaker faces response is ", res);
          getSpeakers();
          handleCloseDp();
        }
      })
      .catch((error) => console.error("Error mapping photos:", error));
  }

  function handleClick() {
    if (selectedImages.length > 0) {
      mapFaces();
    } else {
      notify("* Please select atleast one face");
    }
  }

  return (
    <div>
      <Modal.Body>
        {flag === true ? (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <span
              style={{ marginRight: "10px", fontSize: "14px", fontWeight: 500 }}
            >
              Face extraction is in progress
            </span>{" "}
            <Spinner size="sm" animation="border" variant="success" />
          </div>
        ) : null}
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "20px",
            marginLeft: "7%",
          }}
        >
          {photos.map((data) => (
            <Image
              src={data.FaceUrl}
              key={data.Id}
              className="selectSpeakerPic"
              thumbnail
              style={
                selectedImages.some((image) => image.id === data.Id)
                  ? { boxShadow: "rgba(3, 102, 214, 0.3) 0px 0px 0px 3px" }
                  : {}
              }
              onClick={() => handleImageClick(data.Id, data.FaceUrl)}
            />
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseDp}>
          Close
        </Button>
        <Button
          variant="primary"
          style={{ backgroundColor: "rgb(0 151 254)", color: "white" }}
          onClick={handleClick}
        >
          Submit
        </Button>
      </Modal.Footer>
    </div>
  );
}
