import React, { useState } from 'react';
import './landingPage.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useNavigate } from 'react-router-dom';
import logo from '../multimedia/spectral_logo_final_red_white.png';
import { Col, Row } from 'react-bootstrap';

export default function LandingPage({ selectRegComponent, setSelectedRegComponent }) {
    const navigate = useNavigate();
    
    const [shake, setShake] = useState(false);

    function setSignUp() {
        setSelectedRegComponent('signup');
        navigate('/register');
    }

    function setLogIn() {
        setSelectedRegComponent('login');
        navigate('/register');
    }

    const handleShake = () => {
        setShake(true);
        setTimeout(() => {
            setShake(false);
        }, 1000); // Set the duration to match the animation duration
    };

    return (
        <div style={{backgroundImage: 'url("https://www.pixelstalk.net/wp-content/uploads/2016/06/Free-Download-Gif-Pictures.gif")', backgroundSize: 'cover', height: '100vh'}}>
            <Row>
                <Col md={12} sm={12}>
                    <Navbar expand="lg" className="customLandNav" data-bs-theme="dark">
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="ms-auto me-5 customLandNavLinks">
                                <Nav.Link onClick={setSignUp} className='me-5'>SIGN UP</Nav.Link>
                                <Nav.Link onClick={setLogIn}>LOG IN</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </Col>
            </Row>
            <Row>
                <Col md={12} sm={12}>
                <div className='landingPage' style={{  display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                        <div style={{display: 'flex', justifyContent: 'center' }}>
                            <img
                                style={{ height: '300px', width: '70vw', animation: shake ? 'shakeAnimation 1s ease-in-out' : 'none' }}
                                src={logo}
                                alt='logo'
                                onClick={handleShake}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}
