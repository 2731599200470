import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { IoFilterSharp } from 'react-icons/io5';
import { FaUpload, FaGoogleDrive, FaDropbox } from 'react-icons/fa6';
import { Container, InputGroup, OverlayTrigger, Tooltip, Table, Pagination } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { HiInformationCircle } from 'react-icons/hi2';
import ReactGA from 'react-ga';
import './spectralDRM.css';

export default function AuditLog({
  setSearchText,
  searchText,
  userId,
  userInfo,
  setUserInfo,
}) {
  const [activeTab, setActiveTab] = useState('textToSpeech');
  const [activeTab2, setActiveTab2] = useState('settings');
  const [voicesArray, setVoicesArray] = useState([]);
  const [drmAuditLog, setDrmAudiLog] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;

  const Severity = { 1: 'Info', 2: 'Warning', 3: 'Critical', 4: 'Error' };
  const apiURL = process.env.REACT_APP_API_URL;

  const InformationCircleTooltip = ({ id, text }) => (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip style={{ fontSize: '12px' }} id={`tooltip-${id}`}>
          {text}
        </Tooltip>
      }
    >
      <span
        style={{
          fontSize: '16px',
          marginTop: '12px',
          marginLeft: '6px',
          color: 'gray',
        }}
      >
        <HiInformationCircle />
      </span>
    </OverlayTrigger>
  );

  // google analytics
  const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
  ReactGA.initialize(TRACKING_ID);

  const getDrmAuditLog = async () => {
    const reqBody = {
      UserId: localStorage.getItem('userSessionId'),
      TopN: 100,
    };
    try {
      const response = await fetch(`${apiURL}/web/get_drm_audit_log`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reqBody),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.IsValid) {
          setDrmAudiLog(data.LogList);
          console.log(drmAuditLog);
        }
      } else {
        throw new Error('Failed to fetch project data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    ReactGA.pageview('/voice_lab');
    ReactGA.set({ page: '/voice_lab' });
  }, []);

  useEffect(() => {
    document.title = 'Audit Log - Spectral Studio';
    getDrmAuditLog();
  }, []);

  const totalPages = Math.ceil(drmAuditLog.length / itemsPerPage);
  const paginatedAuditLog = drmAuditLog.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <div className="digital-twin-container">
      <header>
        <h2 className="">Audit Log</h2>
      </header>
      <div
        style={{ position: 'relative', overflowY: 'auto' }}
        className="dt-voice-content"
      >
        <div className="tableDiv2">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <span
              style={{
                fontSize: '18px',
                fontWeight: '500',
                background: '#fff',
              }}
              className=""
            >
              Logs
            </span>
          </div>

          <Table striped bordered className="mt-2">
            <thead>
              <tr>
                <th>No.</th>
                <th>Date</th>
                <th>User</th>
                <th>LogType</th>
                <th>Severity</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              {paginatedAuditLog.map((log, index) => (
                <tr key={log.UserName}>
                  <td style={{ verticalAlign: 'middle' }}>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                  <td style={{ verticalAlign: 'middle' }}>
                    {new Date(log.Date).toISOString().replace(/[a-zA-Z]/g, ' ')}
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>{log.UserName}</td>
                  <td style={{ verticalAlign: 'middle' }}>{log.LogType}</td>
                  <td style={{ verticalAlign: 'middle' }}>
                    {Severity[log.Severity]}
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>{log.Message}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination>
            <Pagination.First onClick={() => setCurrentPage(1)} disabled={currentPage === 1} />
            <Pagination.Prev onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} disabled={currentPage === 1} />
            {[...Array(totalPages).keys()].map(number => (
              <Pagination.Item
                key={number + 1}
                active={number + 1 === currentPage}
                onClick={() => setCurrentPage(number + 1)}
              >
                {number + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages} />
            <Pagination.Last onClick={() => setCurrentPage(totalPages)} disabled={currentPage === totalPages} />
          </Pagination>
        </div>
      </div>
    </div>
  );
}
