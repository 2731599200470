import React, { useEffect, useState , useRef } from 'react';

const ThumbnailUploader = ({selectedVideoFile,setSelectedVideoFile,videoFiles,handleFileClick}) => {
  const [videos, setVideos] = useState([]);
  const [contextMenu, setContextMenu] = useState(null);
  const contextMenuRef = useRef(null);
  const apiKey = 'fTj2NO2kZFTkNnrk9FLHz8dpMidvGbtBy3feCBOuorkVrGyRcX3o2AOd';
  const apiURL = `https://api.pexels.com/videos/search?query=mountains&per_page=3&page=1`;

  const fetchVideos = async () => {
    try {
      const response = await fetch(apiURL, {
        headers: {
          Authorization: apiKey,
        },
      });
      const data = await response.json();
      setVideos(data.videos);
      
    } catch (error) {
      console.error(error);
    }
  };
  fetchVideos();

  // const handleThumbnailClick = (videoName) => {
    // const iframe = document.createElement('iframe');
    // iframe.src = videoUrl;
    // iframe.width = 900;
    // iframe.height = 600;
    // const newTab = window.open();
    // newTab.document.body.appendChild(iframe);
    // setSelectedVideoFile({name:name,duration:durationInSeconds})
  //  console.log('on t click',videoName)
  // };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedTime = `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    return formattedTime;
  };

  const handleContextMenu = (event, file) => {
    event.preventDefault();
    setContextMenu({
      file: file,
      top: event.clientY,
      left: event.clientX,
    });
  };

  const handleDeleteClick = () => {
    console.log('Delete clicked for:', contextMenu.file);
    setContextMenu(null);
  };
  const handleClickOutsideContextMenu = (event) => {
    if (
      contextMenuRef.current &&
      !contextMenuRef.current.contains(event.target) &&
      event.target.className !== 'dotsButton'
    ) {
      setContextMenu(null);
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleClickOutsideContextMenu);

    return () => {
      window.removeEventListener('click', handleClickOutsideContextMenu);
    };
  }, []);
  return (
    <div>
      
      <div className='vidAssets'>
        {videos.map((video, index) => (
          <div key={video.id} className='videoInfo'>
            <div className='videoOptions'>
            <img
              src={video.image}
              alt={video.url}
              onClick={() => setSelectedVideoFile({name:video.id,duration:video.duration})}
              style={{ width: '100%',borderRadius:'8px',height:'100px' }}
            />
           <div  className='dotsButton' onClick={(event) => handleContextMenu(event, video)} >...</div>
           
            </div>
            
            
            <div style={{
              position: 'absolute',
              bottom: '5px',
              right: '5px',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              color: '#fff',
              fontSize: '12px',
              padding: '2px 4px',
            }}>
              {formatTime(video.duration)}
            </div>
            <div style={{
              position: 'absolute',
              bottom: '0',
              left: '0',
              width: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              color: '#fff',
              fontSize: '8px',
              padding: '10px 0',
              textAlign: 'center',
              borderBottomRightRadius:'8px',
              borderBottomLeftRadius:'8px'
            }}>
              Project Name - {index + 1}
            </div>
          </div>
          
        ))}
      
        {videoFiles.map((file, index) => (
            <div key={index} id={file.name} className="videoInfo" onClick={() => handleFileClick(file.name)}>
              <div  className='dotsButton' onClick={(event) => handleContextMenu(event, file)} >...
              </div>
              {/* <p style={{ fontSize: '20px', color: 'GrayText' }}>{file.name}</p> */}
              <div style={{
              position: 'absolute',
              bottom: '5px',
              right: '5px',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              color: '#fff',
              fontSize: '12px',
              padding: '2px 4px',
            }}>{file.duration}</div>
            <div style={{
              position: 'absolute',
              bottom: '0',
              left: '0',
              width: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              color: '#fff',
              fontSize: '8px',
              padding: '10px 0',
              textAlign: 'center',
              borderBottomRightRadius:'8px',
              borderBottomLeftRadius:'8px'
            }}>
           {file.name}
            </div>
            </div>
          ))}
       
      </div>
      
      {contextMenu && (
        <div
          className='contextMenu'
          style={{ position: 'absolute', top: contextMenu.top, left: contextMenu.left,backgroundColor:'#ededed' ,
          borderRadius:'3px',height:'40px',width:'90px', display: 'flex',  // Added flex display
          justifyContent: 'center',  // Center horizontally
          alignItems: 'center',paddingRight:'25px',paddingTop:'10px',fontWeight:500,cursor: 'pointer',}}
          ref={contextMenuRef}
        >
          <ul style={{listStyleType: 'none ' }}>
            <li onClick={handleDeleteClick}>delete</li>
            {/* Add more context menu options as needed */}
          </ul>
        </div>
      )}

    </div>
  );
};

export default ThumbnailUploader;
