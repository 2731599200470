import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./registration.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { formatISO, differenceInMinutes } from "date-fns";

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    backgroundColor: "black",
    color: "lightblue",
    "& fieldset": {
      borderColor: "lightblue",
    },
    "&:hover fieldset": {
      borderColor: "lightblue",
    },
    "&.Mui-focused fieldset": {
      borderColor: "lightblue",
    },
    "& .MuiInputBase-input": {
      backgroundColor: "black !important", // Set the background color to black
    },
  },
  "& .MuiInputLabel-root": {
    color: "lightblue",
  },
  // Set margin to 20px
  width: "90%", // Set width to 80%
  height: "40px",
  margin: "5%",
}));

const CustomCheckbox = styled(Checkbox)({
  color: "lightblue",
  // Set checkbox color to lightblue
});

const CustomSelect = styled(Select)(({ theme }) => ({
  backgroundColor: "black",
  color: "lightblue",
  "&:focus": {
    backgroundColor: "black",
  },
  "& .MuiSelect-icon": {
    color: "lightblue", // Change the arrow color
  },
}));

const CustomMenu = styled("div")(({ theme }) => ({
  backgroundColor: "black",
  color: "lightblue",
}));

const CustomFormControl = styled(FormControl)(({ theme }) => ({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    backgroundColor: "black",
    "& fieldset": {
      borderColor: "lightblue",
    },
    "&:hover fieldset": {
      borderColor: "lightblue",
    },
    "&.Mui-focused fieldset": {
      borderColor: "lightblue",
    },
  },
  "& .MuiInputLabel-root": {
    color: "lightblue",
  },
  width: "90%",
  height: "40px",
  margin: "5%",
}));
const countries = [
  "Select Country",
  "India",
  "United States",
  "United Kingdom",
  "Canada",
  "Australia",
];
// Validation schema for User Form Page of Signup
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  companyName: Yup.string().required("Company Name is required"),
  phone: Yup.string()
    .matches(/^\d+$/, "Phone number must contain only digits")
    .test(
      "phone-length",
      "Invalid phone length. For the UK, it must be 11 digits; for other countries, it must be 10 digits.",
      function (value) {
        const isUK = this.parent.country === "United Kingdom";
        return (isUK && value.length === 11) || (!isUK && value.length === 10);
      }
    )
    .required("Phone is required"),
  checkbox: Yup.object().test(
    "checkboxes",
    "Select at least one option",
    (value) => {
      return Object.values(value).some((isChecked) => isChecked);
    }
  ),
  country: Yup.string().required("Select a country"),
});

// UserForm component starts here

export default function UserForm({
  setSelectedRegComponent,
  vCode,
  regEmail,
  regPwd,
  setVCode,
  userId,
  setUserId,
}) {
  const apiURL = process.env.REACT_APP_API_URL;
  const [selectedCountry, setSelectedCountry] = useState("");
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState(false);
  const navigate = useNavigate();
  const notify = () =>
    toast.info(
      "Please use the verification code sent to registered email address",
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      }
    );

  useEffect(() => {
    notify();
  }, []);

  // languages api call
  function fetchLanguageName() {
    fetch(`${apiURL}/web/get_language_list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Add any other headers if needed
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the data from the API
        if (data.IsValid) {
          localStorage.setItem(
            "languagesList",
            JSON.stringify(data.LanguageList)
          );
        }
      })
      .catch((error) => {
        // Handle errors
        console.error("Error:", error);
      });
  }

  let timeStamp;

  // login api call
  function login() {
    const reqBody = {
      Email: regEmail,
      Password: regPwd,
    };
    fetch(`${apiURL}/web/login`, {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reqBody),
    })
      .then((response) => response.json())
      .then((data) => {
        if (
          data.IsValid === true &&
          data.IsVerified === true &&
          data.IsActive === true &&
          data.Error === false
        ) {
          localStorage.setItem("userSessionId", data.UserId);
          timeStamp = new Date();
          localStorage.setItem("lastTimeStamp", formatISO(timeStamp));
          setUserId(data.UserId);
          fetchLanguageName();
          fetchUserInfo(data.UserId);
        } else {
          if (
            data.IsValid === false &&
            data.Error === false &&
            data.IsVerified === false &&
            data.IsActive === false
          ) {
            setMsg("* Invalid credentials, please try again");
          }
          if (
            data.IsVerified === false &&
            data.IsValid === true &&
            data.IsActive === false &&
            data.Error === false
          ) {
            setMsg("* Your account is not verified");
          }
          if (
            data.IsValid === true &&
            data.IsVerified === true &&
            data.IsActive === false &&
            data.Error === false
          ) {
            setMsg("* Your acount is not activated");
          }
          setErrorMsg(true);
        }
      })
      .catch((error) => {
        console.error("API call failed:", error);
      });
  }

  // function for get user info api call after login
  function fetchUserInfo(id) {
    const reqBody = {
      UserId: id,
    };

    fetch(`${apiURL}/web/get_user_info`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Add any other headers if needed
      },
      body: JSON.stringify(reqBody),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the data from the API
        if (data.IsValid) {
          localStorage.setItem("userInfo", JSON.stringify(data.UserInfo));
          navigate("/");
        }
      })
      .catch((error) => {
        // Handle errors
        console.error("Error:", error);
      });
  }
  const formik = useFormik({
    initialValues: {
      name: "",
      companyName: "",
      phone: "",
      checkbox: {
        marketing: false,
        filmmaking: false,
        explainerVideos: false,
        socialMedia: false,
      },
      country: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (isFormValid) {
        const selectedKeys = [];
        for (const key in values.checkbox) {
          if (values.checkbox[key] === true) {
            selectedKeys.push(key);
          }
        }
        const result = selectedKeys.join(",");

        if (regEmail !== "" && regPwd !== "" && vCode !== "") {
          var reqBody = {
            Name: values.name,
            OrgName: values.companyName,
            Email: regEmail,
            Password: regPwd,
            Phone: values.phone,
            Country: values.country,
            UseCase: result,
            VerificationCode: vCode,
          };

          fetch(`${apiURL}/web/update_user`, {
            method: "POST",

            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify(reqBody),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data.IsValid === true) {
                login();
              } else {
                console.error("Error:", data.Error);
                setErrorMsg(true);
              }
            })
            .catch((error) => {
              console.error("API call failed:", error);
            });
        } else if (vCode === "") {
          notify(
            "* Please enter verication code sent to your registerd email address"
          );
        }
      }
    },
  });
  const isFormValid = formik.dirty && formik.isValid;
  const handleCountryChange = (event) => {
    const selectedCountryValue = event.target.value;
    setSelectedCountry(selectedCountryValue);
    formik.setFieldValue("country", selectedCountryValue);
  };

  const [checkedItems, setCheckedItems] = useState({
    marketing: false,
    filmmaking: false,
    explainerVideos: false,
    socialMedia: false,
  });

  const handleCheckboxChange = (name) => (event) => {
    formik.setFieldValue(`checkbox.${name}`, event.target.checked);
  };

  return (
    <div
      className="userForm"
      style={{ margin: "5%", height: "55vh", overflow: "auto" }}
    >
      <h4 style={{ marginTop: "5%", marginLeft: "5%" }}>
        Sign up - Spectral Studio
      </h4>
      <form onSubmit={formik.handleSubmit}>
        <CustomTextField
          label="Verification Code"
          variant="outlined"
          name="verification"
          onChange={(e) => {
            setVCode(e.target.value);
          }}
          fullWidth
        />
        <CustomTextField
          className={
            formik.touched.name && Boolean(formik.errors.name) ? "mb-5" : "mb-4"
          }
          label="Name"
          variant="outlined"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          fullWidth
        />

        <CustomTextField
          className={
            formik.touched.companyName && Boolean(formik.errors.companyName)
              ? "mb-5"
              : "mb-4"
          }
          label="Company Name"
          variant="outlined"
          name="companyName"
          value={formik.values.companyName}
          onChange={formik.handleChange}
          error={
            formik.touched.companyName && Boolean(formik.errors.companyName)
          }
          helperText={formik.touched.companyName && formik.errors.companyName}
          fullWidth
        />

        <CustomTextField
          className={
            formik.touched.phone && Boolean(formik.errors.phone)
              ? "mb-5"
              : "mb-4"
          }
          label="Phone"
          variant="outlined"
          name="phone"
          value={formik.values.phone}
          onChange={formik.handleChange}
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          helperText={formik.touched.phone && formik.errors.phone}
          fullWidth
        />

        <CustomFormControl
          variant="outlined"
          className={
            formik.touched.country && Boolean(formik.errors.country)
              ? "mb-5"
              : "mb-4"
          }
        >
          <InputLabel id="country-label">Country</InputLabel>
          <CustomSelect
            labelId="country-label"
            id="country"
            value={formik.values.country}
            onChange={handleCountryChange}
            label="Country"
            MenuProps={{
              classes: { paper: CustomMenu },
            }}
            error={formik.touched.country && Boolean(formik.errors.country)}
          >
            <MenuItem value="" disabled>
              Select Country
            </MenuItem>
            {countries.slice(1).map((country, index) => (
              <MenuItem key={index} value={country}>
                {country}
              </MenuItem>
            ))}
          </CustomSelect>
          {formik.touched.country && formik.errors.country && (
            <p style={{ color: "red", marginLeft: "3%", fontSize: "13px" }}>
              {formik.errors.country}
            </p>
          )}
        </CustomFormControl>
        <p style={{ color: "white", marginLeft: "5%", marginTop: "5%" }}>
          How do you want to use Spectral Studio?
        </p>
        <div className="checkboxes">
          <FormControlLabel
            control={
              <CustomCheckbox
                checked={formik.values.checkbox.marketing}
                onChange={handleCheckboxChange("marketing")}
              />
            }
            label="Marketing"
          />
          <FormControlLabel
            control={
              <CustomCheckbox
                checked={formik.values.checkbox.filmmaking}
                onChange={handleCheckboxChange("filmmaking")}
              />
            }
            label="Film-making"
          />
          <FormControlLabel
            control={
              <CustomCheckbox
                checked={formik.values.checkbox.explainerVideos}
                onChange={handleCheckboxChange("explainerVideos")}
              />
            }
            label="Explainer Videos"
          />
          <FormControlLabel
            control={
              <CustomCheckbox
                checked={formik.values.checkbox.socialMedia}
                onChange={handleCheckboxChange("socialMedia")}
              />
            }
            label="Social Media"
          />

          {formik.touched.checkbox && formik.errors.checkbox && (
            <p
              style={{
                color: "red",
                marginLeft: "5%",
                marginTop: "5%",
                fontSize: "13px",
              }}
            >
              {formik.errors.checkbox}
            </p>
          )}
        </div>
        <button
          type="submit"
          className="continueBtn"
          style={{ marginBottom: "5%" }}
        >
          Continue
        </button>
        {errorMsg === true ? (
          <p style={{ color: "red", marginLeft: "5%" }}>
            * Registraion Failed. Please try again
          </p>
        ) : null}
      </form>
      <ToastContainer style={{ fontSize: "14px", fontWeight: 600 }} />
    </div>
  );
}
