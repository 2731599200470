import React, { useEffect, useRef, useState } from "react";
import { FiUploadCloud } from "react-icons/fi";
import "./media.css";
import ThumbnailUploader from "../AssetsDisplay";

export default function AddMedia({ selectedVideoFile, setSelectedVideoFile }) {
  const fileInputRef = useRef(null);
  const [videoFiles, setVideoFiles] = useState([]);

  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const newFiles = Array.from(files);
      setVideoFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  const formatDuration = (durationInSeconds) => {
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = Math.floor(durationInSeconds % 60);
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };
  const handleFileClick = (name) => {
    const selectedFile = videoFiles.find((file) => file.name === name);
    if (selectedFile) {
      const videoElement = document.createElement("video");
      videoElement.onloadedmetadata = () => {
        const durationInSeconds = Math.floor(videoElement.duration);
        selectedFile.duration = formatDuration(durationInSeconds); // Update the duration in the correct format
        setVideoFiles([...videoFiles]);
        console.log("Clicked file name:", name);
        console.log("Calculated duration (seconds):", durationInSeconds);
        setSelectedVideoFile({ name: name, duration: durationInSeconds });
      };
      videoElement.src = URL.createObjectURL(selectedFile);
    }
  };
  useEffect(() => {
    if (Object.keys(selectedVideoFile).length !== 0) {
      console.log(selectedVideoFile);
    }
  }, [selectedVideoFile]);

  return (
    <div style={{ textAlign: "center", width: "100%" }}>
      <h3 className="mt-4">Add Media</h3>

      <button className="uploadMedia" onClick={handleUploadButtonClick}>
        <p style={{ fontSize: "16px", color: "gray" }}>
          <FiUploadCloud />
        </p>
        <p style={{ fontWeight: 500, fontSize: "20px", color: "GrayText" }}>
          Upload a File
        </p>
        <p style={{ color: "grey" }}>Click to browse</p>
      </button>

      {/* {videoFiles.length > 0 && (
        <button className="addMore" style={{ color: 'grey' }} onClick={handleUploadButtonClick}>
          Add more files
        </button>
      )} */}
      <input
        type="file"
        accept=".mp4"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      <ThumbnailUploader
        videoFiles={videoFiles}
        selectedVideoFile={selectedVideoFile}
        setSelectedVideoFile={setSelectedVideoFile}
        handleFileClick={handleFileClick}
      />
    </div>
  );
}
