import React, { useEffect, useState } from 'react';
// import UserList from './UserList';
// import EditUser from "./EditUser";
// import ManageUserProjects from "./ManageUserProjects";
import { Button } from 'react-bootstrap';
import { IoFilterSharp } from 'react-icons/io5';
import ProjectList from './ProjectList';
import Transaction from './Transaction';
import { Routes, Route, Link, Outlet, useLocation } from 'react-router-dom';

export default function Projects({
  selectedProjectsComponent,
  setSelectedProjectsComponent,
  searchText,
  setSearchText,
}) {
  const [userList, setUserList] = useState([]);
  const [filteredUserList, setFilteredUserList] = useState([]);
  const [user, setUser] = useState({});
  const [filterOn, setFilterOn] = useState(false);

  useEffect(() => {
    document.title = 'Project List - Spectral Studio';
  }, []);

  const renderSelectedProjectsComponent = () => {
    switch (selectedProjectsComponent) {
      case 'project-list':
        return (
          <ProjectList
            // searchText={searchText}
            // userList={userList}
            // setUserList={setUserList}
            // filteredUserList={filteredUserList}
            // setFilteredUserList={setFilteredUserList}
            // user={user}
            // setUser={setUser}
            setSelectedProjectsComponent={setSelectedProjectsComponent}
          />
        );
      case 'project-transaction':
        return (
          <Transaction
            // searchText={searchText}
            // userList={userList}
            // setUserList={setUserList}
            // filteredUserList={filteredUserList}
            // setFilteredUserList={setFilteredUserList}
            // user={user}
            // setUser={setUser}
            setSelectedProjectsComponent={setSelectedProjectsComponent}
          />
        );
      //   case 'manage-lip-sync-requests':
      //     return (
      //       <ManageLipSyncRequests searchText={searchText} filterOn={filterOn} />
      //     );
      //   case 'manage-projects':
      //     return (
      //       <ManageUserProjects searchText={searchText} filterOn={filterOn} />
      //     );
      //   case 'user-edit':
      //     return (
      //       <EditUser
      //         filteredUserList={filteredUserList}
      //         setFilteredUserList={setFilteredUserList}
      //         user={user}
      //         setUser={setUser}
      //         setSelectedAdminComponent={setSelectedAdminComponent}
      //       />
      //     );
      default:
        return null;
    }
  };

  return (
    <div className="projects-page">
      {/* {renderSelectedProjectsComponent()} */}
      <Routes>
        <Route
          path="/"
          element={
            <ProjectList
              setSelectedProjectsComponent={setSelectedProjectsComponent}
            />
          }
        />
        <Route
          path="list"
          element={
            <ProjectList
              setSelectedProjectsComponent={setSelectedProjectsComponent}
            />
          }
        />
        <Route
          path="transaction"
          element={
            <Transaction
              setSelectedProjectsComponent={setSelectedProjectsComponent}
            />
          }
        />
      </Routes>
    </div>
  );
}
