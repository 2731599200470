import React, { useEffect, useState } from 'react';
import TranscendCreate from './TranscendCreate';
import TranscendSpeakers from './TranscendSpeakers';
import TranscendTranscribe from './TranscendTranscribe';
import TranscendTranslate from './TranscendTranslate';
import AudioSegments from './AudioSegments';
import DubbedVideo from './DubbedVideo';
import TranscendHome from './TranscendHome';
import SpectralSyncVideo from './SpectralSyncVideo';
import FileUploadMessage from './FileUploadMessage';
import { Routes, Route, Link, Outlet, useLocation } from 'react-router-dom';

export default function SpectralTranscend({
  setSearchText,
  searchText,
  setUserInfo,
  selectedTranscendComponent,
  setSelectedTranscendComponent,
  menuLock,
  setMenuLock,
  projectName,
  setProjectName,
}) {
  const [projectId, setProjectId] = useState('');
  const projectIdLc = projectId.toLowerCase();
  const [languageId, setLanguageId] = useState('');
  const [speakersList, setSpeakersList] = useState([]);
  const [videoUrl, setVideoUrl] = useState('');
  const [selectedLangArray, setSelectedLangArray] = useState([]);
  const [voice1, setVoice1] = useState({});
  const [voicesArray, setVoicesArray] = useState([]);
  const [speakers, setSpeakers] = useState([]);
  const apiURL = process.env.REACT_APP_API_URL;
  useEffect(() => {
    document.title = 'Transcend - Spectral Studio';
    setSearchText('');
  }, []);

  const location = useLocation();

  useEffect(() => {
    // Check if the pathname matches 'voiceTransfer' and not 'voiceTransfer/...'

    if (location.pathname !== '/transcend') {
      setMenuLock(true);
    } else {
      setMenuLock(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (selectedTranscendComponent !== 'transcendHome') {
      setMenuLock(true);
    }
  }, [selectedTranscendComponent]);

  function fetchUserInfo() {
    const reqBody = {
      UserId: localStorage.getItem('userSessionId'),
    };
    fetch(`${apiURL}/web/get_user_info`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers if needed
      },
      body: JSON.stringify(reqBody),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the data from the API
        if (data.IsValid) {
          localStorage.setItem('userInfo', JSON.stringify(data.UserInfo));
        }
      })
      .catch((error) => {
        // Handle errors
        console.error('Error:', error);
      });
  }
  useEffect(() => {
    fetchUserInfo();
  }, []);

  const renderSelectedTranscendComponent = () => {
    switch (selectedTranscendComponent) {
      case 'transcendHome':
        return (
          <TranscendHome
            searchText={searchText}
            setSelectedTranscendComponent={setSelectedTranscendComponent}
            setUserInfo={setUserInfo}
            menuLock={menuLock}
            setMenuLock={setMenuLock}
            setProjectId={setProjectId}
            setLanguageId={setLanguageId}
            setVideoUrl={setVideoUrl}
            setProjectName={setProjectName}
            setSelectedLangArray={setSelectedLangArray}
            setVoice1={setVoice1}
            setVoicesArray={setVoicesArray}
          />
        );
      case 'create':
        return (
          <TranscendCreate
            setSelectedTranscendComponent={setSelectedTranscendComponent}
            projectId={projectId}
            setUserInfo={setUserInfo}
            setProjectId={setProjectId}
            projectName={projectName}
            voice1={voice1}
            setVoice1={setVoice1}
            setSpeakers={setSpeakers}
            setProjectName={setProjectName}
            languageId={languageId}
            setLanguageId={setLanguageId}
            videoUrl={videoUrl}
            setVideoUrl={setVideoUrl}
            setSelectedLangArray={setSelectedLangArray}
            setVoicesArray={setVoicesArray}
          />
        );
      case 'speaker':
        if (
          JSON.parse(localStorage.getItem('userInfo')).IsDubbingClient === true
        ) {
          return <FileUploadMessage />;
        } else {
          return (
            <TranscendSpeakers
              setSelectedTranscendComponent={setSelectedTranscendComponent}
              projectId={projectId}
              setProjectId={setProjectId}
              languageId={languageId}
              videoUrl={videoUrl}
              selectedLangArray={selectedLangArray}
              voice1={voice1}
              voicesArray={voicesArray}
              setSpeakers={setSpeakers}
              speakers={speakers}
            />
          );
        }
      case 'transcribe':
        return (
          <TranscendTranscribe
            setSelectedTranscendComponent={setSelectedTranscendComponent}
            projectName={projectName}
            speakersList={speakersList}
            setSpeakersList={setSpeakersList}
            projectId={projectId}
            setProjectId={setProjectId}
            videoUrl={videoUrl}
            setProjectName={setProjectName}
            setLanguageId={setLanguageId}
            languageId={languageId}
            setVoicesArray={setVoicesArray}
          />
        );
      case 'translate':
        return (
          <TranscendTranslate
            setSelectedTranscendComponent={setSelectedTranscendComponent}
            projectName={projectName}
            setProjectName={setProjectName}
            setProjectId={setProjectId}
            languageId={languageId}
            projectId={projectId}
            speakersList={speakersList}
            setSpeakersList={setSpeakersList}
            videoUrl={videoUrl}
            setVideoUrl={setVideoUrl}
            setLanguageId={setLanguageId}
          />
        );
      case 'audio_segments':
        return (
          <AudioSegments
            setSelectedTranscendComponent={setSelectedTranscendComponent}
            projectId={projectId}
            setProjectId={setProjectId}
            setLanguageId={setLanguageId}
            languageId={languageId}
            videoUrl={videoUrl}
            setProjectName={setProjectName}
            projectName={projectName}
          />
        );
      case 'download':
        return (
          <DubbedVideo
            setProjectId={setProjectId}
            setSelectedTranscendComponent={setSelectedTranscendComponent}
            projectId={projectId}
            setLanguageId={setLanguageId}
            languageId={languageId}
            voicesArray={voicesArray}
            setVoicesArray={setVoicesArray}
          />
        );
      case 'spectral-sync':
        return (
          <SpectralSyncVideo
            setSelectedTranscendComponent={setSelectedTranscendComponent}
            videoUrl={videoUrl}
            projectId={projectId}
            languageId={languageId}
            setProjectId={setProjectId}
            setLanguageId={setLanguageId}
            setVideoUrl={setVideoUrl}
            setProjectName={setProjectName}
          />
        );
      default:
        return null;
    }
  };
  return (
    <div className="spectralTranscend">
      {(location.pathname === '/transcend' ||
        location.pathname === '/transcend/home') && (
        <h2 className="mt-4 ms-5">Spectral Transcend</h2>
      )}
      {(location.pathname === '/transcend' ||
        location.pathname === '/transcend/home') && (
        <span className="mt-2 ms-5" style={{ color: 'grey' }}>
          Fully flexible end-to-end localization workflow for powerful, dynamic
          and human-in-the-loop localization.
        </span>
      )}
      <div>
        {/* {renderSelectedTranscendComponent()} */}
        <Routes>
          <Route
            path="/"
            element={
              <TranscendHome
                searchText={searchText}
                setSelectedTranscendComponent={setSelectedTranscendComponent}
                setUserInfo={setUserInfo}
                menuLock={menuLock}
                setMenuLock={setMenuLock}
                projectId={projectId}
                setProjectId={setProjectId}
                setLanguageId={setLanguageId}
                setVideoUrl={setVideoUrl}
                setProjectName={setProjectName}
                setSelectedLangArray={setSelectedLangArray}
                setVoice1={setVoice1}
                setVoicesArray={setVoicesArray}
              />
            }
          />
          <Route
            path="home"
            element={
              <TranscendHome
                searchText={searchText}
                setSelectedTranscendComponent={setSelectedTranscendComponent}
                setUserInfo={setUserInfo}
                menuLock={menuLock}
                setMenuLock={setMenuLock}
                projectId={projectId}
                setProjectId={setProjectId}
                setLanguageId={setLanguageId}
                setVideoUrl={setVideoUrl}
                setProjectName={setProjectName}
                setSelectedLangArray={setSelectedLangArray}
                setVoice1={setVoice1}
                setVoicesArray={setVoicesArray}
              />
            }
          />
          <Route
            path="create"
            element={
              <TranscendCreate
                setSelectedTranscendComponent={setSelectedTranscendComponent}
                projectId={projectId}
                setUserInfo={setUserInfo}
                setProjectId={setProjectId}
                projectName={projectName}
                voice1={voice1}
                setVoice1={setVoice1}
                setSpeakers={setSpeakers}
                setProjectName={setProjectName}
                languageId={languageId}
                setLanguageId={setLanguageId}
                videoUrl={videoUrl}
                setVideoUrl={setVideoUrl}
                setSelectedLangArray={setSelectedLangArray}
                setVoicesArray={setVoicesArray}
              />
            }
          />
          <Route
            path=":projectIdLc/speaker"
            element={
              <TranscendSpeakers
                setSelectedTranscendComponent={setSelectedTranscendComponent}
                projectId={projectId}
                setProjectId={setProjectId}
                languageId={languageId}
                videoUrl={videoUrl}
                selectedLangArray={selectedLangArray}
                voice1={voice1}
                voicesArray={voicesArray}
                setSpeakers={setSpeakers}
                speakers={speakers}
              />
            }
          />
          <Route
            path=":projectIdLc/transcribe"
            element={
              <TranscendTranscribe
                setSelectedTranscendComponent={setSelectedTranscendComponent}
                projectName={projectName}
                speakersList={speakersList}
                setSpeakersList={setSpeakersList}
                projectId={projectId}
                setProjectId={setProjectId}
                videoUrl={videoUrl}
                setProjectName={setProjectName}
                setLanguageId={setLanguageId}
                languageId={languageId}
                setVoicesArray={setVoicesArray}
              />
            }
          />
          <Route
            path=":projectIdLc/translate"
            element={
              <TranscendTranslate
                setSelectedTranscendComponent={setSelectedTranscendComponent}
                projectName={projectName}
                setProjectName={setProjectName}
                setProjectId={setProjectId}
                languageId={languageId}
                projectId={projectId}
                speakersList={speakersList}
                setSpeakersList={setSpeakersList}
                videoUrl={videoUrl}
                setVideoUrl={setVideoUrl}
                setLanguageId={setLanguageId}
              />
            }
          />
          <Route
            path=":projectIdLc/audio-segments"
            element={
              <AudioSegments
                setSelectedTranscendComponent={setSelectedTranscendComponent}
                projectId={projectId}
                setProjectId={setProjectId}
                setLanguageId={setLanguageId}
                languageId={languageId}
                videoUrl={videoUrl}
                setProjectName={setProjectName}
                projectName={projectName}
              />
            }
          />
          <Route
            path=":projectIdLc/download"
            element={
              <DubbedVideo
                setProjectId={setProjectId}
                setSelectedTranscendComponent={setSelectedTranscendComponent}
                projectId={projectId}
                setLanguageId={setLanguageId}
                languageId={languageId}
                voicesArray={voicesArray}
                setVoicesArray={setVoicesArray}
              />
            }
          />
          <Route
            path=":projectIdLc/sync"
            element={
              <SpectralSyncVideo
                setSelectedTranscendComponent={setSelectedTranscendComponent}
                videoUrl={videoUrl}
                projectId={projectId}
                languageId={languageId}
                setProjectId={setProjectId}
                setLanguageId={setLanguageId}
                setVideoUrl={setVideoUrl}
                setProjectName={setProjectName}
              />
            }
          />
        </Routes>
      </div>
    </div>
  );
}
