import React, { useEffect, useState } from "react";
import { Button, Form, Pagination, Table } from "react-bootstrap";

export default function ManageLipSyncRequests({ searchText, filterOn }) {
  const apiURL = process.env.REACT_APP_API_URL;
  const [projectList, setProjectList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [projectsPerPage] = useState(10);
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(false);

  function getAllUsers() {
    const request = {
      UserId: localStorage.getItem("userSessionId"),
    };
    fetch(`${apiURL}/web/get_all_users`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Add any other headers if needed
      },
      body: JSON.stringify(request),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the data from the API
        if (data.IsValid) {
          let users = Array.from(
            data.AllUsers.map((user) => `${user.Name || ""} (${user.Email})`)
          );
          users.unshift("All users");
          setUsers(users);
          setAllUsers(data.AllUsers);
        }
      })
      .catch((error) => {
        // Handle errors
        console.error("Error:", error);
      });
  }

  function getAllProjects() {
    setLoading(true);
    const request = {
      UserId: userId,
      Type: 3,
    };
    fetch(`${apiURL}/web/get_filtered_project_list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Add any other headers if needed
      },
      body: JSON.stringify(request),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the data from the API
        if (data.IsValid) {
          //  console.log(data);
          setProjectList(data.ProjectList);
          setLoading(false);
        } else {
          setLoading(false);
          setProjectList([]);
        }
      })
      .catch((error) => {
        // Handle errors
        console.error("Error:", error);
      });
  }

  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    getAllProjects(userId);
  }, [userId]);

  // Filter projects before pagination
  const filteredProjects = projectList.filter((project) => {
    const searchTextLowerCase = searchText.toLowerCase();

    return (
      searchText === "" ||
      project.Name.toLowerCase().includes(searchTextLowerCase) ||
      project.Email.toLowerCase().includes(searchTextLowerCase) ||
      project.UserName.toLowerCase().includes(searchTextLowerCase)
    );
  });

  // Pagination logic
  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = filteredProjects.slice(
    indexOfFirstProject,
    indexOfLastProject
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(); // Change the format as needed
  };

  const sourceLanguage = (langId) => {
    const languagesList = JSON.parse(localStorage.getItem("languagesList"));
    if (langId !== null) {
      const matchingLanguage = languagesList.find(
        (language) => language.Id === langId
      );
      return matchingLanguage ? matchingLanguage.Name : "";
    } else {
      const matchingLanguage = "";
      return matchingLanguage;
    }
  };

  async function approveRequest(projectId) {
    const req = {
      ProjectId: projectId,
      CurrentStatus: 12,
      TargetStatus: 13,
    };
    try {
      const response = await fetch(
        `${apiURL}/web/change_project_result_status`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // 'ApiKey': apiKey,
          },
          body: JSON.stringify(req),
        }
      );
      const res = await response.json();

      if (res.IsValid) {
        getAllProjects();
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function rejectRequest(projectId) {
    const req = {
      ProjectId: projectId,
      CurrentStatus: 12,
      TargetStatus: 16,
    };
    try {
      const response = await fetch(
        `${apiURL}/web/change_project_result_status`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // 'ApiKey': apiKey,
          },
          body: JSON.stringify(req),
        }
      );
      const res = await response.json();

      if (res.IsValid) {
        getAllProjects();
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="ms-5 me-5">
      {filterOn && (
        <Form.Select
          size="sm"
          style={{ width: "20%" }}
          onChange={(e) => {
            // console.log('the dropdown value is',e.target.value)
            if (e.target.value !== "All users") {
              let email = e.target.value.match(/\(([^)]+)\)/)?.[1];
              let matchingUser = allUsers.find((user) => user.Email === email);

              if (matchingUser) {
                setCurrentPage(1);
                // console.log(matchingUser.Id,matchingUser.Name);
                setUserId(matchingUser.Id);
              }
            } else {
              setUserId(null);
            }
          }}
        >
          {users.map((val, index) => (
            <option key={index}>{val}</option>
          ))}
        </Form.Select>
      )}

      {filteredProjects.length === 0 && loading === false ? (
        <p className="no-projects-txt">No records found.</p>
      ) : null}
      <Table className="mt-4" striped bordered hover>
        {filteredProjects.length !== 0 && (
          <thead>
            <tr>
              <th style={{ height: "50px", width: "18%" }}>Project Name</th>
              <th style={{ height: "50px", width: "18%" }}>User</th>
              <th style={{ height: "50px", width: "15%" }}>Source Language</th>
              <th style={{ height: "50px", width: "15%" }}>Target Language</th>
              <th style={{ height: "50px", width: "8%" }}>Created Date</th>
              <th style={{ height: "50px", width: "14%" }}>Status</th>
              <th style={{ height: "50px", width: "12%" }}>Action</th>
            </tr>
          </thead>
        )}
        {loading === false ? (
          <tbody>
            {currentProjects.map((project, index) => (
              <tr key={project.Id}>
                <td
                  style={{
                    height: "50px",
                    width: "18%",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {project.Name || ""}
                </td>
                <td
                  style={{
                    height: "50px",
                    width: "18%",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "10vw",
                    overflow: "hidden",
                  }}
                >
                  {project.UserName} ({project.Email})
                </td>
                <td
                  style={{
                    height: "50px",
                    width: "15%",
                    maxWidth: "10vw",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {sourceLanguage(project.SourceLangId)}
                </td>
                <td
                  style={{
                    height: "50px",
                    width: "15%",
                    maxWidth: "10vw",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {project.ResultVideos[0].TargetLangName || ""}
                </td>
                <td style={{ height: "50px", width: "8%" }}>
                  {formatDate(project.CreatedAt) || ""}
                </td>
                <td
                  style={{
                    height: "50px",
                    width: "14%",
                    maxWidth: "10vw",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {project.ResultVideos[0].Status === 12
                    ? "LipSync video requested"
                    : project.ResultVideos[0].Status === 13
                    ? "LipSync video pending"
                    : project.ResultVideos[0].Status === 14
                    ? "LipSync video processing"
                    : project.ResultVideos[0].Status === 15
                    ? "LipSync video processed"
                    : project.ResultVideos[0].Status === 1499
                    ? "LipSync video approval pending"
                    : null}
                </td>
                <td style={{ height: "50px", width: "12%" }}>
                  {project.ResultVideos[0].Status === 12 ? (
                    <span>
                      <Button
                        variant="success"
                        size="sm"
                        onClick={(e) => approveRequest(project.Id)}
                      >
                        Approve
                      </Button>
                      <Button
                        className="ms-3"
                        size="sm"
                        variant="danger"
                        onClick={(e) => rejectRequest(project.Id)}
                      >
                        Reject
                      </Button>
                    </span>
                  ) : (
                    ""
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        ) : null}
      </Table>
      {loading && (
        <p
          className="mt-5"
          style={{
            fontWeight: 600,
            textAlign: "center",
            width: "100%",
            height: "50px",
          }}
        >
          loading...
        </p>
      )}
      {filteredProjects.length >= projectsPerPage || currentPage > 1 ? (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {loading === false && (
            <Pagination>
              <Pagination.First onClick={() => setCurrentPage(1)} />
              <Pagination.Prev
                onClick={() =>
                  setCurrentPage((prev) => (prev > 1 ? prev - 1 : 1))
                }
              />
              {Array.from(
                {
                  length: Math.min(
                    Math.ceil(filteredProjects.length / projectsPerPage),
                    5
                  ),
                },
                (_, i) => {
                  const lastPage = Math.ceil(
                    filteredProjects.length / projectsPerPage
                  );
                  let startPage;
                  if (lastPage <= 5 || currentPage <= 3) {
                    startPage = 1;
                  } else if (currentPage + 1 >= lastPage) {
                    startPage = lastPage - 4;
                  } else {
                    startPage = currentPage - 2;
                  }
                  const pageNumber = startPage + i;
                  return (
                    <Pagination.Item
                      key={pageNumber}
                      onClick={() => paginate(pageNumber)}
                      active={pageNumber === currentPage}
                    >
                      {pageNumber}
                    </Pagination.Item>
                  );
                }
              )}
              <Pagination.Next
                onClick={() =>
                  setCurrentPage((next) =>
                    next < Math.ceil(filteredProjects.length / projectsPerPage)
                      ? next + 1
                      : Math.ceil(filteredProjects.length / projectsPerPage)
                  )
                }
              />
              <Pagination.Last
                onClick={() =>
                  setCurrentPage(
                    Math.ceil(filteredProjects.length / projectsPerPage)
                  )
                }
              />
            </Pagination>
          )}
        </div>
      ) : null}
    </div>
  );
}
