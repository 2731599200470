import React, { useEffect, useState } from 'react';
import { IoCut } from 'react-icons/io5';
import { FiMoreVertical } from 'react-icons/fi';
import { Button, Card } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import thumbnail from '../../../multimedia/thumbnail1200.png';
import ReactTimeAgo from 'react-time-ago';
import { MdOutlineEdit } from 'react-icons/md';
import { RiDeleteBin6Line } from 'react-icons/ri';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import ReactGA from 'react-ga';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function TranslateHome({
  searchText,
  setSelectedTranslateComponent,
  projectId,
  setProjectId,
  projectData,
  setProjectData,
  setDubbedData,
  setPName,
  processingData,
  setProcessingData,
  setMenuLock,
  menuLock,
  projectName,
  setProjectName,
}) {
  const apiURL = process.env.REACT_APP_API_URL;
  const [myTranslateProjects, setMyTranslateProjects] = useState([]);
  const [reName, setReName] = useState('');
  const [projectUserId, setProjectUserId] = useState('');
  const [projectIdFromUrl, setProjectIdFromUrl] = useState('');
  const [pid, setPid] = useState('');
  const [displayedCards, setDisplayedCards] = useState(9); // Number of cards to display initially
  const cardsPerPage = 9;
  const projectsApiUrl = `${apiURL}/web/get_project_list`;
  const getSelectedProjectUrl = `${apiURL}/web/get_project`;
  const [openMenuId, setOpenMenuId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const location = useLocation();

  const notify = (error) =>
    toast.error(error, {
      className: 'custom-error-toast',
    });

  useEffect(() => {
    setMenuLock(false);
  }, []);

  useEffect(() => {
    console.log(projectId);
    setProjectIdFromUrl(projectId);
  }, [location]);

  useEffect(() => {
    if (projectIdFromUrl) {
      fetchSelectedProject(projectIdFromUrl);
    }
  }, [projectUserId]);

  const openModal = (project_id) => {
    setPid(project_id);
    setShowModal(true);
    ReactGA.event({
      category: 'Modal',
      action: 'Open',
      label: 'projects/delete_project',
    });
  };

  const closeModal = () => setShowModal(false);

  // google analytics

  const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview('/spectral-translate/projects');
  }, []);

  const toggleOptions = (id) => {
    setOpenMenuId(openMenuId === id ? null : id);
  };

  const handleShowMore = () => {
    setDisplayedCards((prev) => prev + cardsPerPage);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (project_id) => {
    setPid(project_id);
    setShow(true);
    ReactGA.event({
      category: 'Modal',
      action: 'Open',
      label: 'projects/rename_project',
    });
  };

  async function handleDeleteProject(project_id) {
    const req = {
      ProjectId: project_id,
    };
    console.log(req, 'is req for delete');
    try {
      const response = await fetch(`${apiURL}/web/delete_project`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // 'ApiKey': apiKey,
        },
        body: JSON.stringify(req),
      });
      const data = await response.json();

      if (data.IsValid) {
        console.log(data);
        fetchAllProjects();
        closeModal();
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function projectReName(project_id, SourceLangId, videoSrc) {
    console.log(reName, 'id: ', project_id);
    const req = {
      ProjectId: project_id,
      Name: reName,
      SourceLangId: SourceLangId,
      VideoUrl: videoSrc,
    };
    console.log(req, 'is rename');
    try {
      const response = await fetch(`${apiURL}/web/update_project`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // 'ApiKey': apiKey,
        },
        body: JSON.stringify(req),
      });
      const data = await response.json();
      if (data.IsValid) {
        setReName('');
        console.log(data);
        fetchAllProjects();
        handleClose();
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function handleProjectReName() {
    const req = {
      ProjectId: pid,
    };
    console.log('required req is', req);
    try {
      const response = await fetch(`${apiURL}/web/get_project`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // 'ApiKey': apiKey,
        },
        body: JSON.stringify(req),
      });
      const data = await response.json();

      if (data.IsValid) {
        projectReName(data.Id, data.SourceLangId, data.VideoUrl);
      }
    } catch (error) {
      console.error(error);
    }
  }
  const navigate = useNavigate();
  const fetchAllProjects = async () => {
    const requestBody = {
      UserId: localStorage.getItem('userSessionId'),
      Type: 1,
    };
    try {
      const response = await fetch(projectsApiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // 'ApiKey': apiKey,
        },
        body: JSON.stringify(requestBody),
      });
      const data = await response.json();

      if (data.IsValid) {
        setMyTranslateProjects(data.ProjectList);
        setProjectUserId(data.ProjectList[0].UserId);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchAllProjects();
  }, []);

  async function fetchSelectedProject(id) {
    const request = {
      ProjectId: id,
    };

    try {
      const response = await fetch(getSelectedProjectUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // 'ApiKey': apiKey,
        },
        body: JSON.stringify(request),
      });
      const data = await response.json();

      if (data.IsValid) {
        if (data.Status === 0 || data.status === 3) {
          // setProjectData is used to set request data for process api
          setProjectData({
            ProjectId: data.Id,
            ProjectName: data.Name,
            VideoUrl: data.VideoUrl,
          });
          setProjectName(data.Name);
          setSelectedTranslateComponent('translate1');
          navigate('/translate/create');
        } else if (data.Status === 6) {
          setProjectId(data.Id);
          setDubbedData(data.ResultVideos);
          setPName(data.Name);
          setProjectName(data.Name);
          localStorage.removeItem('duration');
          setSelectedTranslateComponent('translate2');
          navigate(`/translate/${data.Id.toLowerCase()}/result`);
        } else if (data.Status === 1) {
          setProcessingData(data.ResultVideos);
          setPName(data.Name);
          setProjectName(data.Name);
          setProjectId(data.Id);
          localStorage.removeItem('duration');
          setSelectedTranslateComponent('translate2');
          navigate(`/translate/${data.Id.toLowerCase()}/result`);
        } else if (data.Status === 2) {
          setProjectId(data.Id);
          setDubbedData(data.ResultVideos);
          setPName(data.Name);
          setProjectName(data.Name);
          localStorage.removeItem('duration');
          setSelectedTranslateComponent('translate2');
          navigate(`/translate/${data.Id.toLowerCase()}/result`);
        } else if (data.Status === 7) {
          setProjectId(data.Id);
          setDubbedData(data.ResultVideos);
          setProjectName(data.Name);
          setPName(data.Name);
          localStorage.removeItem('duration');
          setSelectedTranslateComponent('translate2');
          navigate(`/translate/${data.Id.toLowerCase()}/result`);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  function handleEdit(id) {
    fetchSelectedProject(id);
  }

  const filteredProjects = myTranslateProjects.filter((project) =>
    project.Name.toLowerCase().includes(searchText.toLowerCase())
  );

  const getTooltipText = (langCode) => {
    const languagesList = JSON.parse(localStorage.getItem('languagesList'));
    const matchingLanguage = languagesList.find(
      (language) => language.LanguageCode === langCode
    );
    return matchingLanguage ? matchingLanguage.Name : '';
  };

  return (
    <div onMouseDown={() => setOpenMenuId(null)}>
      <div className="user-btns">
        <button
          onClick={() => {
            const userInfo = JSON.parse(localStorage.getItem('userInfo'));
            if (
              userInfo &&
              userInfo.LimitCredits > 0 &&
              userInfo.LimitCredits > userInfo.UsageCredits
            ) {
              setSelectedTranslateComponent('translate1');
              navigate('/translate/create');
            } else {
              if (userInfo.LimitCredits <= 0) {
                notify(
                  'Subscription has expired. Renew your subscription to keep using Spectral.'
                );
              } else if (userInfo.LimitCredits <= userInfo.UsageCredits) {
                notify('Insufficient credits available for project creation!');
              }
            }
          }}
          className="create-btn"
        >
          <span className="create-icon">
            <IoCut />
          </span>
          Create Project
        </button>
      </div>
      <div
        className="mt-5 ms-5 mb-3"
        style={{ display: 'flex', flexDirection: 'row' }}
      >
        <span style={{ fontWeight: 500, fontSize: '18px' }}>
          Recent Projects
        </span>
      </div>
      {filteredProjects.length === 0 && searchText !== '' ? (
        <p className="no-projects-txt">
          No projects found with the given search text.
        </p>
      ) : null}
      <div className="translate-cards-container">
        {filteredProjects
          .filter((project) => project.Status !== 4)
          .sort((a, b) => new Date(b.CreatedAt) - new Date(a.CreatedAt))
          .slice(0, displayedCards)
          .map((projects, index) => {
            // Parse the timestamp string into a Date object
            const createdAtDate = new Date(projects.CreatedAt + 'Z');

            return (
              <Card
                className="tanslateProjectsCard mt-4 ms-5 "
                key={projects.Id}
                style={{
                  border: 'none',
                  backgroundColor: '#f7f7f9',
                }}
                onMouseEnter={(e) => {
                  const editButton =
                    e.currentTarget.querySelector('.edit-button');
                  editButton.style.opacity = 1;
                }}
                onMouseLeave={(e) => {
                  const editButton =
                    e.currentTarget.querySelector('.edit-button');
                  editButton.style.opacity = 0;
                }}
              >
                <div
                  style={{
                    position: 'relative',
                    flex: '1',
                  }}
                >
                  {projects.ThumbnailUrl !== null ? (
                    <Card.Img
                      className="translateCardThumbnail"
                      src={projects.ThumbnailUrl}
                      alt={projects.Name}
                    />
                  ) : (
                    <Card.Img
                      className="translateCardThumbnail"
                      src={thumbnail}
                      alt={projects.Name}
                    />
                  )}
                  <div
                    className="langInfo-status"
                    style={{ position: 'absolute', top: 15, left: 10 }}
                  >
                    {projects.ResultVideos.map((project, index) => (
                      <span className="status-label" key={index}>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip>
                              {getTooltipText(project.TargetLanguageCode)}
                            </Tooltip>
                          }
                        >
                          <span>{project.TargetLanguageCode}</span>
                        </OverlayTrigger>
                      </span>
                    ))}
                  </div>
                  <div
                    className="edit-button"
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      opacity: 0,
                      transition: 'opacity 0.3s',
                      backgroundColor: 'rgba(0, 0, 0, 0.7)',
                      color: '#fff',
                      fontSize: '16px',
                      fontWeight: 500,
                      padding: '8px 16px',
                      borderRadius: '5px',
                      cursor: 'pointer',
                    }}
                    onClick={(e) => handleEdit(projects.Id)}
                  >
                    Edit
                  </div>
                  <div
                    className="status-label"
                    style={{ position: 'absolute', bottom: 15, left: 10 }}
                  >
                    <span>
                      {projects.Status === 6
                        ? projects.ResultVideos.length > 1
                          ? 'Dubbed videos ready'
                          : 'Dubbed video ready'
                        : projects.Status === 1
                        ? 'Video processing'
                        : projects.Status === 7
                        ? 'LipSync processing'
                        : projects.Status === 2
                        ? 'LipSync processed'
                        : projects.Status === 0
                        ? 'Video uploaded'
                        : null}
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    color: 'black',
                    fontWeight: 500,
                    marginTop: '8px',
                  }}
                >
                  <div>
                    <span
                      title={projects.Name.length > 30 ? projects.Name : null}
                    >
                      {' '}
                      {projects.Name.length > 30
                        ? projects.Name.slice(0, 30) + '...'
                        : projects.Name}
                    </span>
                    <br />
                    <span style={{ color: 'grey', fontWeight: 400 }}>
                      Created:{' '}
                      <ReactTimeAgo date={createdAtDate} timeStyle="round" />
                    </span>
                  </div>
                  {/* css for options button in usepage.css */}
                  <Button
                    variant="text"
                    style={{
                      fontSize: '25px',
                      padding: 0,
                      height: '26px',
                      border: 'none',
                      outline: 'none',
                      position: 'relative',
                    }}
                    className="options-Button"
                    onClick={(e) => toggleOptions(projects.Id)}
                    onMouseDown={(e) => e.stopPropagation()}
                  >
                    <FiMoreVertical />
                    {openMenuId === projects.Id && (
                      <div className="options-menu">
                        <button
                          style={{ fontWeight: 500 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleShow(projects.Id);
                          }}
                        >
                          <span className="me-1">
                            <MdOutlineEdit />
                          </span>
                          Rename
                        </button>
                        <button
                          style={{ fontWeight: 500 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            openModal(projects.Id);
                          }}
                        >
                          <span className="me-1">
                            <RiDeleteBin6Line />
                          </span>
                          Delete
                        </button>
                      </div>
                    )}
                  </Button>
                </div>
                <Modal
                  show={show}
                  onHide={handleClose}
                  backdrop="static"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Rename project</Modal.Title>
                  </Modal.Header>
                  <Modal.Body style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                    <Form.Label column sm="2">
                      Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Project name"
                      defaultValue={reName}
                      onBlur={(e) => setReName(e.target.value)}
                    />
                    <div className="mt-4 mb-2">
                      <Row>
                        <Col>
                          <Button
                            variant="secondary"
                            block
                            onClick={handleClose}
                            style={{ width: '100%' }}
                          >
                            Cancel
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            variant="primary"
                            block
                            onClick={(e) => {
                              handleProjectReName();
                              console.log('rename pid is ', pid);
                            }}
                            style={{ width: '100%' }}
                          >
                            Save
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Modal.Body>
                </Modal>

                {/* Modal for delete project warning */}

                <Modal show={showModal} onHide={closeModal} size="md">
                  <Modal.Header closeButton>
                    <Modal.Title>Confirm delete</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Are you sure you want to delete the project
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="warning"
                      onClick={(e) => handleDeleteProject(pid)}
                    >
                      Confirm
                    </Button>
                    <Button variant="secondary" onClick={closeModal}>
                      Cancel
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Card>
            );
          })}
        {myTranslateProjects.length === 0 ? (
          <div className="translate-welcome">
            <p>Welcome to Spectral. Let's create a project.</p>
          </div>
        ) : null}
      </div>
      <div
        className="mb-5"
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        {filteredProjects.length > displayedCards && (
          <Button
            style={{ fontSize: '14px', fontWeight: 500 }}
            variant="outline-primary"
            onClick={handleShowMore}
          >
            Show More
          </Button>
        )}
      </div>
      <ToastContainer />
    </div>
  );
}
