import React, { useEffect, useState } from 'react';
import Signup from './Signup';
import Login from './Login';
import './registration.css';
import { Col, Row } from 'react-bootstrap';
import logo from '../../multimedia/favicon.png';
import background from '../../multimedia/spectral_bg.jpg';
import UserForm from './UserForm';
import ForgotPassword from './ForgotPassword';
import Confirmation from './Confirmation';
import { useParams } from 'react-router-dom';

export default function UserRegistration({
  selectRegComponent,
  setSelectedRegComponent,
  userId,
  setUserId,
}) {
  const { component } = useParams();
  const [regEmail, setRegEmail] = useState('');
  const [regPwd, setRegPwd] = useState('');
  const [vCode, setVCode] = useState('');
  const renderSelectedRegComponent = () => {
    switch (selectRegComponent) {
      case 'signup':
        // return <Signup setSelectedRegComponent={setSelectedRegComponent} vCode={vCode} setVCode={setVCode} setRegEmail={setRegEmail} setRegPwd={setRegPwd} />;
        return (
          <Login
            setSelectedRegComponent={setSelectedRegComponent}
            userId={userId}
            setUserId={setUserId}
          />
        );
      case 'login':
        return (
          <Login
            setSelectedRegComponent={setSelectedRegComponent}
            userId={userId}
            setUserId={setUserId}
          />
        );
      // case 'uform':
      //   return <UserForm setSelectedRegComponent={setSelectedRegComponent} vCode={vCode} setVCode={setVCode} regEmail={regEmail} regPwd={regPwd} userId={userId} setUserId={setUserId} />;
      case 'fpwd':
        return (
          <ForgotPassword setSelectedRegComponent={setSelectedRegComponent} />
        );
      case 'confirm':
        return (
          <Confirmation setSelectedRegComponent={setSelectedRegComponent} />
        );
      default:
        return null;
    }
  };
  useEffect(() => {
    if (
      component &&
      component !== 'uform' &&
      component !== 'fpwd' &&
      component !== 'confirm'
    ) {
      setSelectedRegComponent(component); // Set the selected component based on the URL parameter
    }
    // Apply the style to prevent scrolling when the component mounts
    document.documentElement.style.overflow = 'hidden';
    document.body.style.overflow = 'hidden';

    // Remove the style to re-enable scrolling when the component unmounts
    return () => {
      document.documentElement.style.overflow = 'visible';
      document.body.style.overflow = 'visible';
    };
  }, [component, setSelectedRegComponent]);
  return (
    <div
      className="regPage"
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Row
        style={{
          display: 'flex',
          marginLeft: '55%',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Col md={12}>
          <Col md={10}>
            <div className="regFormContainer">
              <img
                style={{
                  height: '70px',
                  width: '70px',
                  marginLeft: '5%',
                  marginTop: '20px',
                }}
                src={logo}
                alt="logo"
              />
              {renderSelectedRegComponent()}
            </div>
          </Col>
        </Col>
      </Row>
    </div>
  );
}
