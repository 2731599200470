import React, { useEffect, useState } from 'react';
// import VoicePage1 from './VoicePage1';
import { Button } from 'react-bootstrap';
import { IoFilterSharp } from 'react-icons/io5';
import { FaUpload, FaGoogleDrive, FaDropbox } from 'react-icons/fa6';
import { IoArrowBack } from 'react-icons/io5';
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';

import {
  Container,
  InputGroup,
  OverlayTrigger,
  Tooltip,
  Table,
} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { HiInformationCircle } from 'react-icons/hi2';
import ReactGA from 'react-ga';
import { useNavigate } from 'react-router-dom';
import '../spectralDRM.css';

export default function EditVoice({
  setSearchText,
  searchText,
  userId,
  userInfo,
  setUserInfo,
  setUser,
  voiceData,
  setVoiceData,
  editClicked,
  setEditClicked,
  drmVoiceId,
  setDrmVoiceId,
  selectedRowData,
  setSelectedRowData,
  setSelectedDigitalTwinComponent,
}) {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('textToSpeech');
  const [activeTab2, setActiveTab2] = useState('settings');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [orgs, setOrgs] = useState([]);
  const [orgId, setOrgId] = useState();
  const [status, setStatus] = useState(0);
  const [errors, setErrors] = useState({});

  const [voicesArray, setVoicesArray] = useState([]);
  const apiURL = process.env.REACT_APP_API_URL;

  const InformationCircleTooltip = ({ id, text }) => (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip style={{ fontSize: '12px' }} id={`tooltip-${id}`}>
          {text}
        </Tooltip>
      }
    >
      <span
        style={{
          fontSize: '16px',
          marginTop: '12px',
          marginLeft: '6px',
          color: 'gray',
        }}
      >
        <HiInformationCircle />
      </span>
    </OverlayTrigger>
  );
  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: '300px',
      margin: '0 ',
    }),
    control: (provided) => ({
      ...provided,
      borderColor: '#ccc',
      minHeight: '40px',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#aaa',
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#ffffff',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#007bff' : '#ffffff',
      color: state.isSelected ? '#ffffff' : '#333333',
      '&:hover': {
        backgroundColor: state.isSelected ? '#0056b3' : '#f1f1f1',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#333333',
    }),
  };

  // google analytics
  const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview('/voice_lab');
    ReactGA.set({ page: '/voice_lab' });
  }, []);

  const getAllOrg = async () => {
    const reqBody = {
      UserId: localStorage.getItem('userSessionId'),
      VoiceId: drmVoiceId,
    };
    try {
      const response = await fetch(`${apiURL}/web/get_all_organizations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reqBody),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.IsValid) {
          setOrgs(data.OrgList);
          setOrgId(data.OrgList.length > 0 ? data.OrgList[0].OrgId : '');
        }
      } else {
        throw new Error('Failed to fetch project data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const AddNewAccess = async () => {
    const reqBody = {
      UserId: localStorage.getItem('userSessionId'),
      VoiceId: drmVoiceId,
      AccessStatus: status,
      OrgId: orgId,
      StartDate: `${startDate} 00:00:00`,
      EndDate: `${endDate} 00:00:00`,
    };
    try {
      const response = await fetch(`${apiURL}/web/update_drm_voice_access`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reqBody),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.IsValid) {
          setSelectedDigitalTwinComponent('manage-voice');
          navigate('/drm/digital-twin/manage-voice');

          // setVoiceData(data.AccessList);
          // console.log(voiceData);
        }
      } else {
        throw new Error('Failed to fetch project data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const EditAccess = async () => {
    const reqBody = {
      UserId: localStorage.getItem('userSessionId'),
      VoiceId: drmVoiceId,
      AccessId: selectedRowData.AccessId,
      AccessStatus: status,
      OrgId: selectedRowData.OrganizationId,
      StartDate: `${startDate} 00:00:00`,
      EndDate: `${endDate} 00:00:00`,
    };
    try {
      const response = await fetch(`${apiURL}/web/update_drm_voice_access`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reqBody),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.IsValid) {
          setSelectedDigitalTwinComponent('manage-voice');
          navigate('/drm/digital-twin/manage-voice');

          // setVoiceData(data.AccessList);
          // console.log(voiceData);
        }
      } else {
        throw new Error('Failed to fetch project data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const notifyFailure = (message) => {
    toast.error(message, {
      className: 'custom-failure-toast',
    });
  };
  function validateForm() {
    const newErrors = {};
    if (!startDate) newErrors.startDate = 'Start date is required';
    if (!endDate) newErrors.endDate = 'End date is required';
    if (!orgId) newErrors.orgId = 'Organization is required';
    return newErrors;
  }
  useEffect(() => {
    if (editClicked && selectedRowData) {
      setStartDate(selectedRowData.CreatedOn.substring(0, 10));
      setEndDate(selectedRowData.ExpiringOn.substring(0, 10));
      setStatus(selectedRowData.AccessStatus);
      setOrgId(selectedRowData.OrganizationId);
    }
  }, [editClicked, selectedRowData]);

  function handleSubmit(e) {
    e.preventDefault();

    if (!startDate) {
      notifyFailure('StartDate is required');
    } else if (!endDate) {
      notifyFailure('EndDate is required');
    } else if (editClicked !== true) {
      e.preventDefault();
      AddNewAccess();
    } else if (editClicked === true) {
      EditAccess();
    }
  }

  const options = orgs.map((data) => ({
    value: data.OrgId,
    label: data.OrgName,
  }));

  const handleChange = (selectedOption) => {
    setOrgId(selectedOption.value);
  };

  useEffect(() => {
    document.title = 'Voice - Spectral Studio';
    getAllOrg();
  }, []);
  function cancelChanges() {
    // setUser({});
    setSelectedDigitalTwinComponent('manage-voice');
    navigate('/drm/digital-twin/manage-voice');
  }

  return (
    <div className="digital-twin-container">
      <header>
        <h2 className="">Manage Voice</h2>
      </header>
      <div style={{ position: 'relative' }} className="dt-voice-content">
        <span>
          <span style={{ position: 'absolute', left: 20, top: 10 }}>
            <Button
              style={{ fontSize: '20px', fontWeight: 500 }}
              variant="text"
              onClick={(e) => cancelChanges()}
            >
              <IoArrowBack />
              <span className="ms-1" style={{ fontSize: '14px' }}>
                Back
              </span>
            </Button>
          </span>
        </span>

        <div style={{ padding: '3rem' }}>
          <Form
            className="mt-4"
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <Form.Group
              className="mb-4"
              controlId="formBasicName"
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '20px',
                alignItems: 'center',
              }}
            >
              <Form.Label style={{ width: '10%' }}>Organization:</Form.Label>
              {editClicked !== true ? (
                <Select
                  value={options.find((option) => option.value === orgId)}
                  onChange={handleChange}
                  options={options}
                  styles={customStyles}
                  classNamePrefix="custom-select"
                />
              ) : (
                <Select
                  value={options.find((option) => option.value === orgId)}
                  onChange={handleChange}
                  options={options}
                  styles={customStyles}
                  classNamePrefix="custom-select"
                  isDisabled={true}
                />
              )}
            </Form.Group>

            <Form.Group
              className="mb-4"
              controlId="formBasicEmail"
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '20px',
                alignItems: 'center',
              }}
            ></Form.Group>
            <Form.Group
              className="mb-4"
              controlId="formBasicUserStatus"
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '20px',
                alignItems: 'center',
              }}
            >
              <Form.Label style={{ width: '10%' }}>Status:</Form.Label>
              {editClicked !== true ? (
                <Form.Select
                  value={status}
                  onChange={(e) => setStatus(parseInt(e.target.value))}
                  style={{ width: '10%' }}
                >
                  <option value={0}>Pending</option>
                  <option value={1}>Active</option>
                  <option value={2}>Expired</option>
                  <option value={3}>Revoked</option>
                </Form.Select>
              ) : (
                <Form.Select
                  value={status}
                  onChange={(e) => setStatus(parseInt(e.target.value))}
                  style={{ width: '10%' }}
                >
                  <option value={0}>Pending</option>
                  <option value={1}>Active</option>
                  <option value={2}>Expired</option>
                  <option value={3}>Revoked</option>
                </Form.Select>
              )}
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="formBasicSubscriptionName"
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '20px',
                alignItems: 'center',
              }}
            ></Form.Group>

            <Form.Group
              className="mb-3"
              controlId="formBasicSubscriptionAmount"
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '20px',
                alignItems: 'center',
              }}
            ></Form.Group>

            <Form.Group
              className="mb-4"
              controlId="formBasicDates"
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '20px',
                alignItems: 'center',
              }}
            >
              <Form.Label style={{ width: '10%' }}>Start Date:</Form.Label>
              {editClicked !== true ? (
                <Form.Control
                  type="date"
                  value={startDate}
                  style={{ width: '10%' }}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              ) : (
                <Form.Control
                  type="date"
                  value={startDate}
                  style={{ width: '10%' }}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              )}

              <Form.Label className="ms-4" style={{ width: '8%' }}>
                End Date:
              </Form.Label>
              {editClicked !== true ? (
                <Form.Control
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  style={{ width: '10%' }}
                />
              ) : (
                <Form.Control
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  style={{ width: '10%' }}
                />
              )}
            </Form.Group>

            <Form.Group
              className="mb-4"
              controlId="formBasicLimitCredits"
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '20px',
                alignItems: 'center',
              }}
            ></Form.Group>

            <Form.Group
              className="mb-4"
              controlId="formBasicUsageCredits"
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '20px',
                alignItems: 'center',
              }}
            ></Form.Group>

            <Form.Group
              className="mb-4"
              controlId="formBasicPErmissions"
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '20px',
                alignItems: 'center',
              }}
            ></Form.Group>
            <div
              style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}
            >
              {/* <Button variant="danger" onClick={(e)=>cancelChanges()}>Cancel</Button> */}
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}
