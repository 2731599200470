import React, { useEffect, useState, useRef } from 'react';
// import VoicePage1 from './VoicePage1';
import { Button } from 'react-bootstrap';
import { IoFilterSharp } from 'react-icons/io5';
import { FaDownload } from 'react-icons/fa6';
import { FaUpload, FaGoogleDrive, FaDropbox } from 'react-icons/fa6';
import { ToastContainer, toast } from 'react-toastify';
import loading from '../../../multimedia/loading.gif';

import {
  Container,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { HiInformationCircle } from 'react-icons/hi2';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import axios from 'axios';
import { RiDeleteBin6Line } from 'react-icons/ri';

import ReactGA from 'react-ga';
import './voicelab.css';
import FileValidation from '../../../utils/FileValidation';

export default function VoiceLab({
  setSearchText,
  searchText,
  userId,
  userInfo,
  setUserInfo,
}) {
  const fileInputRef = useRef(null);
  const audioFileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [isYoutube, setISYoutube] = useState(false);
  const [url, setUrl] = useState('');
  const [audioSourceUrl, setAudioSourceUrl] = useState('');
  const isFirefox = navigator.userAgent.indexOf('Firefox') !== -1;
  const [durationError, setDurationError] = useState(false);
  const [timeCost, setTimeCost] = useState(0);
  const [urlVal, setUrlVal] = useState('');
  const [clickedOk, setClickedOk] = useState(false);
  const userDetails = JSON.parse(localStorage.getItem('userInfo'));

  const [activeTab, setActiveTab] = useState('textToSpeech');
  const [activeTab2, setActiveTab2] = useState('settings');
  const [wait, setWait] = useState(false);
  const voices = JSON.parse(localStorage.getItem('voiceList'));
  const [voiceA, setVoiceA] = useState([]);

  const [isChecked, setIsChecked] = useState(true);
  const [stability, setStability] = useState(0.2);
  const [similarity, setSimilarity] = useState(1.0);
  const [text, setText] = useState('Hey, Enter some text here.');
  const [style, setStyle] = useState(0.0);
  const [generatedData, setGeneratedData] = useState([]);
  const [generate, setGenerate] = useState(false);
  const [count, setCount] = useState(0);
  const [speed, setSpeed] = useState(0);

  const SpeechModes = {
    TTS: 1,
    STS: 2,
  };

  // useEffect(() => {
  //   if (file !== null) {
  //     setAudioSourceUrl(URL.createObjectURL(file));
  //   }
  // }, [file]);
  const [selectedVoiceId, setSelectedVoiceId] = useState(null);
  const [audioDuration, setAudioDuration] = useState(null);

  const [ttsUrl, setTtsUrl] = useState(null);
  const [stsUrl, setStsUrl] = useState(null);

  const handleSetDefault = (e) => {
    setIsChecked(true);
    setStyle(0.0);
    // setSimilarity(1.0);
    setStability(0.2);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const [flag, setFlag] = useState(false);

  const [ttsHistoryData, setTtsHistoryData] = useState([]);
  const [stsHistoryData, setStsHistoryData] = useState([]);

  const [uploadPercentage, setUploadPercentage] = useState(0);

  const [voicesArray, setVoicesArray] = useState([]);
  const apiURL = process.env.REACT_APP_API_URL;

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile) {
      console.log('File selected:', uploadedFile);
      // setFile(uploadedFile);
      const url = URL.createObjectURL(uploadedFile);
      const timestamp = new Date().getTime();
      const fileNameWithTimestamp = `${timestamp}_${uploadedFile.name}`;
      const updatedFile = new File([uploadedFile], fileNameWithTimestamp, {
        type: uploadedFile.type,
        lastModified: uploadedFile.lastModified,
      });
      setFile(updatedFile);
      console.log(file);

      console.log('Generated URL:', url);
      setAudioSourceUrl(url);
      setWait(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const uploadedFile = e.dataTransfer.files[0];
    if (uploadedFile) {
      console.log('File dropped:', uploadedFile);
      // setFile(uploadedFile);
      const url = URL.createObjectURL(uploadedFile);
      const timestamp = new Date().getTime();
      const fileNameWithTimestamp = `${timestamp}_${uploadedFile.name}`;
      const updatedFile = new File([uploadedFile], fileNameWithTimestamp, {
        type: uploadedFile.type,
        lastModified: uploadedFile.lastModified,
      });
      setFile(updatedFile);
      console.log('Generated URL:', url);
      setAudioSourceUrl(url);
    }
  };

  const handleDelete = () => {
    setFile(null);
    setAudioSourceUrl('');
  };

  // const InformationCircleTooltip = ({ id, text }) => (
  //   <OverlayTrigger
  //     placement="top"
  //     overlay={
  //       <Tooltip style={{ fontSize: '12px' }} id={`tooltip-${id}`}>
  //         {text}
  //       </Tooltip>
  //     }
  //   >
  //     <span
  //       style={{
  //         fontSize: '16px',
  //         marginTop: '12px',
  //         marginLeft: '6px',
  //         color: 'gray',
  //       }}
  //     >
  //       <HiInformationCircle />
  //     </span>
  //   </OverlayTrigger>
  // );

  // useEffect(() => {
  //   return () => {
  //     if (audioSourceUrl) {
  //       URL.revokeObjectURL(audioSourceUrl);
  //     }
  //   };
  // }, [audioSourceUrl]);

  function uploadFile() {
    if (selectedVoiceId !== null && file !== null) {
      setWait(true);
      // console.log(file.name)
      // Validate the filename
      if (!FileValidation(file.name)) {
        return; // Exit the function if validation fails
      }

      // Continue with the upload process if validation passes
      setFlag(true);
      const formData = new FormData();
      formData.append('filename', file.name);
      formData.append('file', file);

      axios
        .post(`${apiURL}/util/file_upload_aws`, formData, {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadPercentage(percentCompleted); // Assuming setUploadProgress is a function to update the UI with the percentage
          },
        })
        .then((response) => {
          const res = response.data;
          if (res.IsValid) {
            // const id = localStorage.getItem('userSessionId');
            if (res.Url) {
              console.log('kkkkkkkkkkkkkkk');
              getAudioDuration(res.Url);
            }
          } else {
            notify('Upload unsuccessful, please upload a valid file!');
            console.error('Failed to upload file');
          }
        })
        .catch((error) => {
          setFlag(false);
          console.error('Error occurred during upload:', error);
        });
    } else if (selectedVoiceId === null && file === null) {
      notifyDeny('Please select a audio file');
      notifyDeny('Please select a voice');
    } else if (selectedVoiceId === null) {
      notifyDeny('Please select a voice');
    } else if (file === null) {
      notifyDeny('Please select a audio file');
    }
  }

  function calculateTimeCost(duration) {
    const timeInterval = 30;
    const maxDuration = userDetails.LimitVideoLength * 60;

    if (duration <= 0) {
      setTimeCost(0);
    } else if (duration < maxDuration) {
      if (duration <= timeInterval) {
        const timeCostValue = 0.5;
        setTimeCost(timeCostValue);
      } else {
        const intervals = Math.ceil(duration / timeInterval);
        const timeCostValue = intervals * 0.5;
        setTimeCost(timeCostValue);
      }
    } else {
      const timeCostValue = Math.floor(maxDuration / timeInterval) * 0.5;
      setTimeCost(timeCostValue);
    }
  }

  const InformationCircleTooltip = ({ id, text }) => (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip style={{ fontSize: '12px' }} id={`tooltip-${id}`}>
          {text}
        </Tooltip>
      }
    >
      <span
        style={{
          fontSize: '16px',
          marginTop: '12px',
          marginLeft: '6px',
          color: 'gray',
        }}
      >
        <HiInformationCircle />
      </span>
    </OverlayTrigger>
  );

  const getAudioDuration = async (url) => {
    const audio = new Audio(url);

    const loadMetadata = () => {
      return new Promise((resolve) => {
        audio.addEventListener('loadedmetadata', () => {
          resolve(audio.duration);
        });
      });
    };

    try {
      const duration = await loadMetadata();
      setAudioDuration(duration);
      console.log(duration);
      generateSpeechToSpeech(url, duration);
    } catch (error) {
      console.error('Error loading audio metadata:', error);
    }
  };

  // useEffect(() => {
  //   if (audioDuration !== null && selectedVoiceId !== null) {
  //     generateSpeechToSpeech();
  //   }
  // }, [audioDuration]);
  //sts

  function generateSpeechToSpeech(Url, duration) {
    setWait(true);

    const req = {
      UserId: localStorage.getItem('userSessionId'),
      VoiceId: selectedVoiceId,
      AudioSettings: {
        variance: stability,
        // similarity_boost: similarity,
        style: style,
        // use_speaker_boost: isChecked ? 1 : 0,
        // speed: speed,
      },
      AudioUrl: Url,
      Duration: duration,
    };

    fetch(`${apiURL}/dub/generate_voicelab_sts`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(req),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.IsValid) {
          // setGeneratedData((prevData) => {
          //   return [...prevData, result.AudioSegment];
          setStsUrl(result.STSSegmentUrl);
          // });
          setCount(count + 1);
          setGenerate(true);
          setWait(false);
          if (
            result.STSSegmentUrl !== ''
            // result.AudioSegment.AudioSegmentUrl !== null &&
            // result.AudioSegment.AudioSegmentUrl !== undefined
          ) {
            setTimeout(() => {
              playAudio();
            }, 100);
          }
        }
      })
      .catch((error) => {
        // console.error("An error occurred:", error);
        setWait(false);
      });
  }

  // tts
  function generateTextToSpeech() {
    setWait(true);

    const req = {
      UserId: localStorage.getItem('userSessionId'),
      VoiceId: selectedVoiceId,
      AudioSettings: {
        variance: stability,
        // similarity_boost: similarity,
        style: style,
        // use_speaker_boost: isChecked ? 1 : 0,
        // speed: speed,
      },
      Text: text,
    };

    fetch(`${apiURL}/dub/generate_voicelab_tts`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(req),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.IsValid) {
          // setGeneratedData((prevData) => {
          //   return [...prevData, result.AudioSegment];
          setTtsUrl(result.TTSSegmentUrl);
          // });
          setCount(count + 1);
          setGenerate(true);
          setWait(false);
          if (
            result.TTSSegmentUrl !== ''
            // result.AudioSegment.AudioSegmentUrl !== null &&
            // result.AudioSegment.AudioSegmentUrl !== undefined
          ) {
            setTimeout(() => {
              playAudio();
            }, 100);
          }
        }
      })
      .catch((error) => {
        // console.error("An error occurred:", error);
        setWait(false);
      });
  }

  const notify = (error) =>
    toast.error(error, {
      className: 'custom-error-toast',
    });

  const notifyDeny = (message) => {
    toast.error(message, {
      className: 'custom-success-toast1',
    });
  };

  function playAudio() {
    const audio = document.getElementById('audio-Player');
    audio.play();
  }

  const handleChange = (e) => {
    const newVoiceId = e.target.value;
    setSelectedVoiceId(newVoiceId);
    // onChange(newVoiceId);
  };

  function getHistory() {
    setWait(true);

    const req = {
      UserId: localStorage.getItem('userSessionId'),
      Type: selectedVoiceId,
      TopN: 25,
    };

    fetch(`${apiURL}/dub/generate_voicelab_tts`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(req),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.IsValid) {
          // setGeneratedData((prevData) => {
          //   return [...prevData, result.AudioSegment];
          setTtsHistoryData(result);
          // });
          setCount(count + 1);
          setGenerate(true);
          setWait(false);
        }
      })
      .catch((error) => {
        // console.error("An error occurred:", error);
        setWait(false);
      });
  }

  function playAudio() {
    const audio = document.getElementById('audio-Player');
    audio.play();
  }

  useEffect(() => {
    setWait(true);

    const req = {
      UserId: localStorage.getItem('userSessionId'),
      Type: SpeechModes.TTS,
      TopN: 25,
    };

    fetch(`${apiURL}/web/get_voicelab_history`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(req),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.IsValid) {
          // setGeneratedData((prevData) => {
          //   return [...prevData, result.AudioSegment];
          setTtsHistoryData(result.History);
          // });
          setCount(count + 1);
          setGenerate(true);
          setWait(false);
        }
      })
      .catch((error) => {
        // console.error("An error occurred:", error);
        setWait(false);
      });
  }, [ttsUrl]);

  useEffect(() => {
    setWait(true);

    const req = {
      UserId: localStorage.getItem('userSessionId'),
      Type: SpeechModes.STS,
      TopN: 25,
    };

    fetch(`${apiURL}/web/get_voicelab_history`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(req),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.IsValid) {
          // setGeneratedData((prevData) => {
          //   return [...prevData, result.AudioSegment];
          setStsHistoryData(result.History);
          // });
          setCount(count + 1);
          setGenerate(true);
          setWait(false);
        }
      })
      .catch((error) => {
        // console.error("An error occurred:", error);
        setWait(false);
      });
  }, [stsUrl]);

  // google analytics

  const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview('/voice_lab');
    ReactGA.set({ page: '/voice_lab' });
  }, []);

  function fetchUserInfo() {
    const reqBody = {
      UserId: localStorage.getItem('userSessionId'),
    };
    fetch(`${apiURL}/web/get_user_info`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers if needed
      },
      body: JSON.stringify(reqBody),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the data from the API
        if (data.IsValid) {
          setUserInfo(data.UserInfo);
          localStorage.setItem('userInfo', JSON.stringify(data.UserInfo));
        }
      })
      .catch((error) => {
        // Handle errors
        console.error('Error:', error);
      });
  }

  const handleTtsGenerate = (e) => {
    if (selectedVoiceId === null && text === '') {
      notifyDeny('Please enter some text .');
      notifyDeny('Please select a voice.');
    } else if (selectedVoiceId === null) {
      notifyDeny('Please select a voice.');
    } else if (text === null || text === '') {
      notifyDeny('Please enter some text.');
    } else {
      generateTextToSpeech();
    }
  };

  const fetchVoice = async (project_Id) => {
    const reqBody = {
      UserId: localStorage.getItem('userSessionId'),
      ProjectId: project_Id,
    };
    try {
      const response = await fetch(`${apiURL}/web/get_voices`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reqBody),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.IsValid) {
          // console.log("voice list is",data.VoiceList[0]);
          localStorage.setItem('voiceList', JSON.stringify(data.VoiceList));
          setVoiceA(JSON.parse(localStorage.getItem('voiceList')));
        }
      } else {
        // setFlag(false);
        throw new Error('Failed to fetch project data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    document.title = 'Voice Lab - Spectral Studio';
    fetchUserInfo();
    fetchVoice();
    // setSearchText('');
  }, []);
  useEffect(() => {
    // Update userInfo state whenever localStorage userInfo changes
    setUserInfo(JSON.parse(localStorage.getItem('userInfo')));
  }, [localStorage.getItem('userInfo')]);

  return (
    <div className="voice-lab-container">
      <header>
        <h2 className="">Voice Lab</h2>
        <p>
          Unleash the power of our cutting-edge technology to generate
          realistic, captivating speech in a wide range of languages.
        </p>
      </header>
      <div className="content">
        <div className="left">
          <div className="bar-top-left">
            <div className="btnGroup">
              <div
                className={`tab ms-2 ${
                  activeTab === 'textToSpeech' ? 'active' : ''
                }`}
                onClick={() => setActiveTab('textToSpeech')}
              >
                TEXT TO SPEECH
              </div>
              <button
                className={`tab ${
                  activeTab === 'speechToSpeech' ? 'active' : ''
                }`}
                onClick={() => setActiveTab('speechToSpeech')}
              >
                SPEECH TO SPEECH
              </button>
            </div>
          </div>
          <div className="left-content">
            {activeTab === 'textToSpeech' && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justfyContent: 'space-between',
                  height: '100%',
                  gap: '1rem',
                }}
              >
                <textarea
                  className="textToSpeechContainer"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                ></textarea>
                <div className="generate-section">
                  {/* <button className="selectVoice-btn">Select Voice</button> */}
                  <div>
                    <Form.Control
                      style={{
                        background: '#eaeaea',
                        padding: '8px 15px',
                        color: 'rgb(54, 54, 54)',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        transition: 'background-color 0.3s',
                        width: '120px',
                      }}
                      as="select"
                      value={selectedVoiceId}
                      onChange={handleChange}
                    >
                      <option value="">Select Voice</option>
                      {voiceA.map((voice) => (
                        <option key={voice.Id} value={voice.Id}>
                          {voice.Name}
                        </option>
                      ))}
                    </Form.Control>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {' '}
                    <span
                      style={{
                        fontSize: '16px',
                        color: 'grey',
                        marginRight: '.5rem',
                      }}
                    >
                      6/2000
                    </span>{' '}
                    <Button
                      style={{
                        backgroundColor: 'black',
                        border: 'none',
                        outline: 'none',
                      }}
                      // onClick={generateTextToSpeech}
                      onClick={(e) => handleTtsGenerate()}
                      className="generate-btn"
                    >
                      Generate speech
                    </Button>
                  </div>
                </div>
              </div>
            )}
            {activeTab === 'speechToSpeech' && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: '100%',
                  gap: '1rem',
                }}
              >
                {file === null ? (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      height: '100%',
                    }}
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={handleDrop}
                  >
                    <p style={{ textAlign: 'left' }}>Audio file upload :</p>
                    <Button
                      variant="text"
                      style={{ fontSize: '50px', marginBottom: '10px' }}
                      onClick={handleUploadClick}
                    >
                      <FaUpload />
                    </Button>
                    <p style={{ color: 'black' }}>
                      Drop your audio here, or click to browse
                    </p>

                    {wait === true && (
                      <span className="ms-2">
                        <img
                          style={{ width: '40px', height: '40px' }}
                          src={loading}
                          alt="loading"
                        />
                      </span>
                    )}
                    <input
                      ref={fileInputRef}
                      type="file"
                      style={{ display: 'none' }}
                      accept="audio/mpeg, audio/*"
                      onChange={handleFileChange}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <p>Selected Audio</p>
                    <audio
                      style={{
                        width: '600px',
                        height: '200px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                      }}
                      type=""
                      className="sourceVideo"
                      src={audioSourceUrl}
                      controls
                      // disablePictureInPicture
                      // controlsList="nodownload noplaybackrate"
                    />

                    <Button
                      className="mb-2"
                      variant="text"
                      style={{ padding: 0, fontSize: '20px' }}
                      onClick={handleDelete}
                    >
                      <RiDeleteBin6Line />
                    </Button>
                  </div>
                )}

                <div className="generate-section">
                  <div className="">
                    {' '}
                    <Form.Control
                      style={{
                        background: '#eaeaea',
                        padding: '8px 15px',
                        color: 'rgb(54, 54, 54)',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        transition: 'background-color 0.3s',
                        width: '120px',
                      }}
                      as="select"
                      value={selectedVoiceId}
                      onChange={handleChange}
                    >
                      <option value="">Select Voice</option>
                      {voiceA.map((voice) => (
                        <option key={voice.Id} value={voice.Id}>
                          {voice.Name}
                        </option>
                      ))}
                    </Form.Control>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '20px',
                    }}
                  >
                    {wait === true && (
                      <span className="ms-2">
                        <img
                          style={{ width: '25px', height: '25px' }}
                          src={loading}
                          alt="loading"
                        />
                      </span>
                    )}{' '}
                    {wait === true ? (
                      <div>
                        {' '}
                        <Button
                          disabled
                          style={{
                            backgroundColor: 'black',
                            border: 'none',
                            outline: 'none',
                          }}
                          // onClick={(e) => uploadFile(e)}
                          className="generate-btn"
                        >
                          Generate speech
                        </Button>
                      </div>
                    ) : (
                      <div>
                        {' '}
                        <Button
                          onClick={(e) => uploadFile(e)}
                          className="generate-btn"
                          style={{
                            backgroundColor: 'black',
                            border: 'none',
                            outline: 'none',
                          }}
                        >
                          Generate speech
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="right">
          <div className="bar-top-right">
            <div className="btnGroup">
              <div
                className={`tab ms-2 ${
                  activeTab2 === 'settings' ? 'active' : ''
                }`}
                onClick={() => setActiveTab2('settings')}
              >
                SETTINGS
              </div>
              <button
                className={`tab ${activeTab2 === 'history' ? 'active' : ''}`}
                onClick={() => setActiveTab2('history')}
              >
                HISTORY
              </button>
            </div>
          </div>
          <div className="right-content-VL">
            {activeTab2 === 'settings' && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: '100%',
                  gap: '1rem',
                }}
              >
                <div style={{ fontSize: '16px' }} className="settingsVoiceLab">
                  <div>
                    <span>Varience:</span>
                    <Form.Range
                      className="mt-1"
                      min={0}
                      max={1}
                      step={0.05}
                      value={stability}
                      onChange={(e) => setStability(e.target.value)}
                    />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        fontSize: '12px',
                        marginTop: '-10px',
                      }}
                    >
                      <Form.Label style={{ fontWeight: 500, color: 'gray' }}>
                        <span>More Stable</span>
                        <span style={{ fontSize: '16px' }}>
                          <InformationCircleTooltip
                            id="more-stable"
                            text="Increasing variance will make the voice more consistent between re-generations,
         but it can also make it sound a bit monotone. On longer text fragments we recommend lowering this value."
                          />
                        </span>
                      </Form.Label>
                      <Form.Label style={{ fontWeight: 500, color: 'gray' }}>
                        <span>More Variable</span>
                        <span style={{ fontSize: '16px' }}>
                          <InformationCircleTooltip
                            id="more-variable"
                            text="Increasing variability can make speech more expressive
      without output varying between re-generations. It can also lead to instabilities."
                          />
                        </span>
                      </Form.Label>
                    </div>
                  </div>
                  {/* <div className="mt-1">
                    <span>Clarity + Similarity Enhancement:</span>
                    <Form.Range
                      className="mt-1"
                      min={0}
                      max={1}
                      step={0.1}
                      value={similarity}
                      onChange={(e) => setSimilarity(e.target.value)}
                    />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        fontSize: '12px',
                        marginTop: '-10px',
                      }}
                    >
                      <Form.Label style={{ fontWeight: 500, color: 'gray' }}>
                        <span>Low</span>
                        <span style={{ fontSize: '16px' }}></span>
                      </Form.Label>
                      <Form.Label style={{ fontWeight: 500, color: 'gray' }}>
                        <span>High</span>
                        <span style={{ fontSize: '16px' }}></span>
                      </Form.Label>
                    </div>
                  </div> */}
                  <div className="mt-1">
                    <span>Speaker Style:</span>
                    <Form.Range
                      className="mt-1"
                      min={0}
                      max={1}
                      step={0.05}
                      value={style}
                      onChange={(e) => setStyle(e.target.value)}
                    />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        fontSize: '12px',
                      }}
                    >
                      <Form.Label
                        style={{
                          fontWeight: 500,
                          color: 'gray',
                          marginTop: '-6px',
                        }}
                      >
                        Normal (Fastest)
                      </Form.Label>
                      <Form.Label
                        style={{
                          fontWeight: 500,
                          color: 'gray',
                          marginTop: '-10px',
                        }}
                      >
                        <span>Exaggerated</span>
                        <span style={{ fontSize: '16px' }}>
                          <InformationCircleTooltip
                            id="exaggerated"
                            text="High values are recommended if the style of the speech should be exaggerated compared to the uploaded audio.
           Higher values can lead to more instability in the generated speech. Setting to 0.0 will greatly increase generation speed and is the default setting."
                          />
                        </span>{' '}
                      </Form.Label>
                    </div>
                  </div>
                  {/* <div
                    className="mt-2"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Form.Check
                      type="checkbox"
                      label="Speaker Boost"
                      checked={isChecked}
                      onChange={(e) => handleCheckboxChange(e)}
                    />
                    <span
                      className="mb-1"
                      style={{ fontSize: '16px', color: 'gray' }}
                    ></span>
                  </div> */}
                  <div
                    className="mt-2"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  ></div>
                </div>
                <div className="generate-section2">
                  <button
                    onClick={(e) => handleSetDefault(e)}
                    className="setDefault-btn"
                  >
                    To default Settings
                  </button>
                </div>
              </div>
            )}
            {activeTab2 === 'history' &&
              activeTab === 'textToSpeech' &&
              ttsHistoryData && (
                <div className="historyContainer">
                  {ttsHistoryData.map((data, index) => (
                    <div key={index} className="historyRow">
                      {/* Serial Number */}
                      <span className="serialNumber">{index + 1}.</span>

                      {/* Audio Player */}
                      <audio
                        style={{ width: '500px', appearance: 'none' }}
                        controls
                        disablePictureInPicture
                        controlsList="nodownload noplaybackrate"
                        src={`${data.ResultUrl.trim()}?random=${Math.random()}`}
                      ></audio>

                      {/* Download Button */}
                      <a
                        href={data.ResultUrl.trim()}
                        download={`audio_${index + 1}.mp3`}
                      >
                        <Button variant="text">
                          <FaDownload />
                        </Button>{' '}
                      </a>
                    </div>
                  ))}
                </div>
              )}
            {activeTab2 === 'history' &&
              activeTab === 'speechToSpeech' &&
              stsHistoryData && (
                <div className="historyContainer">
                  {stsHistoryData.map((data, index) => (
                    <div key={index} className="historyRow">
                      {/* Serial Number */}
                      <span className="serialNumber">{index + 1}.</span>

                      {/* Audio Player */}
                      <audio
                        style={{ width: '500px', appearance: 'none' }}
                        controls
                        disablePictureInPicture
                        controlsList="nodownload noplaybackrate"
                        src={`${data.ResultUrl.trim()}?random=${Math.random()}`}
                      ></audio>

                      {/* Download Button */}
                      <a
                        href={data.ResultUrl.trim()}
                        download={`audio_${index + 1}.mp3`}
                      >
                        <Button variant="text">
                          <FaDownload />
                        </Button>{' '}
                      </a>
                    </div>
                  ))}
                </div>
              )}
          </div>
        </div>
      </div>
      <footer>
        <div className="playback">
          <span style={{ fontSize: '16px', fontWeight: '600' }}>
            Generated Audio:
          </span>
          {activeTab === 'textToSpeech' ? (
            <div
              style={{
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'center',
                gap: '20px',
              }}
              className=""
            >
              <audio
                id="audio-Player"
                style={{ width: '500px' }}
                controls
                disablePictureInPicture
                controlsList="nodownload noplaybackrate"
                src={ttsUrl}
              ></audio>{' '}
              {ttsUrl !== null ? (
                <a
                  href={ttsUrl.trim()}
                  style={{
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: 'center',
                  }}
                  download={`generated.mp3`}
                >
                  <Button variant="text">
                    <FaDownload />
                  </Button>{' '}
                </a>
              ) : (
                <Button disabled variant="text">
                  <FaDownload />
                </Button>
              )}
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'center',
                gap: '20px',
              }}
              className=""
            >
              <audio
                id="audio-Player"
                style={{ width: '500px' }}
                controls
                disablePictureInPicture
                controlsList="nodownload noplaybackrate"
                src={stsUrl}
              ></audio>
              {stsUrl !== null ? (
                <a
                  href={stsUrl.trim()}
                  style={{
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: 'center',
                  }}
                  download={`generated.mp3`}
                >
                  <Button variant="text">
                    <FaDownload />
                  </Button>{' '}
                </a>
              ) : (
                <Button disabled variant="text">
                  <FaDownload />
                </Button>
              )}
            </div>
          )}
        </div>
      </footer>
    </div>
  );
}
