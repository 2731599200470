import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useProjectIdFromUrl = (projectId, setProjectId) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!projectId) {
      const pathParts = location.pathname.split('/');
      // Split the pathname
      const firstPart = pathParts[1];

      const idFromUrl = pathParts[2];
      if (idFromUrl) {
        if (firstPart === 'transcend') {
          setProjectId(idFromUrl.toUpperCase());
          navigate('/transcend');
        } else if (firstPart === 'voice-transfer') {
          setProjectId(idFromUrl.toUpperCase());
          navigate('/voice-transfer');
        } else if (firstPart === 'translate') {
          setProjectId(idFromUrl.toUpperCase());
          navigate('/translate');
        }
      }
    }
  }, [location]);
};

export default useProjectIdFromUrl;
