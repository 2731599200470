import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Dashboard from './components/DashboardComponents/Dashboard';
import VideoEditor from './components/VideoEditor';
import UserRegistration from './components/Login/UserRegistration';
import { useEffect, useState } from 'react';
import LandingPage from './components/LandingPage';
import { useNavigate } from 'react-router-dom';
import Mobile from './Mobile';
import SessionTimeout from './components/SessionTimeout';
import ReactGA from 'react-ga';

function App() {
  const [selectRegComponent, setSelectedRegComponent] = useState('login');
  // const [selectedDashboardComponent,setSelectedDashboardComponent]=useState('home');
  // revert to 'home' later
  const [selectedDashboardComponent, setSelectedDashboardComponent] =
    useState('spectral-transcend');

  const [userId, setUserId] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(false);

  // google analytics

  const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
  ReactGA.initialize(TRACKING_ID);
  const onUpdatePage = () => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);
    // console.log(window.location.pathname);
  };

  useEffect(() => {
    // ReactGA.pageview('/');
    onUpdatePage();
  }, []);

  // Code to handle back buttonand history on logout----------------------------------->
  useEffect(() => {
    const handleHistoryChange = () => {
      // Check if the user is on a protected route and not logged in
      if (!localStorage.getItem('userSessionId')) {
        // Redirect to the login page
        navigate('/account');
      }
    };

    handleHistoryChange();

    // Listen for changes in the browser's history
    window.addEventListener('popstate', handleHistoryChange);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('popstate', handleHistoryChange);
    };
  }, [navigate]);

  // Redirect to login if user is not logged in and tries to access a protected route
  useEffect(() => {
    if (!localStorage.getItem('userSessionId')) {
      navigate('/account');
    }
  }, [location.pathname, navigate]);

  useEffect(() => {
    const userInfo = localStorage.getItem('userInfo');
    if (userInfo && location.pathname === '/') {
      const parsedUserInfo = JSON.parse(userInfo);
      if (parsedUserInfo.Role === 'DRM') {
        navigate('/drm');
        setSelectedDashboardComponent('drm-dashboard');
      } else {
        // setSelectedDashboardComponent('spectral-transcend');
        navigate('/transcend');
      }
    }
  }, [location.pathname]);
  //---------------------------------------------------------------------------------------------->

  useEffect(() => {
    // Check if the screen size is mobile
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 800);
    };

    // Initial check
    checkIsMobile();

    // Listen for window resize events
    window.addEventListener('resize', checkIsMobile);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  // ----------------------------------------------------------------------------------------------------->

  const logout = () => {
    // Implement your logout logic here
    if (localStorage.getItem('userSessionId') !== null) {
      setUserId('');
      localStorage.clear();
      navigate('/account/login');
      // console.log(localStorage.getItem("userSessionId"))
    }
  };

  useEffect(() => {
    document.title = 'Home - Spectral Studio';
    if (localStorage.getItem('userSessionId') === null) {
      document.title = 'Login - Spectral Studio';
      navigate('/account');
    }
    // Apply the style to prevent scrolling when the component mounts
    document.documentElement.style.overflow = 'hidden';
    document.body.style.overflow = 'hidden';

    // Remove the style to re-enable scrolling when the component unmounts
    return () => {
      document.documentElement.style.overflow = 'visible';
      document.body.style.overflow = 'visible';
    };
  }, [userId]);

  return (
    <div className="App">
      <SessionTimeout logout={logout} userId={userId} />
      {isMobile ? (
        <Mobile />
      ) : (
        <Routes>
          <Route
            path="/landing"
            element={
              <LandingPage
                selectRegComponent={selectRegComponent}
                setSelectedRegComponent={setSelectedRegComponent}
              />
            }
          />
          {localStorage.getItem('userSessionId') !== null &&
          localStorage.getItem('userSessionId') !== undefined ? (
            <Route
              path="/"
              element={
                <>
                  {/* {JSON.parse(localStorage.getItem("userInfo")).Email === "mohib@cynapto.com" && setSelectedDashboardComponent("drm-dashboard")} */}
                  <Dashboard
                    selectedDashboardComponent={selectedDashboardComponent}
                    setSelectedDashboardComponent={
                      setSelectedDashboardComponent
                    }
                    userId={userId}
                    setUserId={setUserId}
                  />
                </>
              }
            />
          ) : null}

          {localStorage.getItem('userSessionId') !== null &&
          localStorage.getItem('userSessionId') !== undefined ? (
            <Route
              // path="/:component"
              path="/*"
              element={
                <Dashboard
                  selectedDashboardComponent={selectedDashboardComponent}
                  setSelectedDashboardComponent={setSelectedDashboardComponent}
                  userId={userId}
                  setUserId={setUserId}
                />
              }
            />
          ) : null}

          <Route
            path="/account"
            element={
              <UserRegistration
                selectRegComponent={selectRegComponent}
                setSelectedRegComponent={setSelectedRegComponent}
                userId={userId}
                setUserId={setUserId}
              />
            }
          />

          <Route
            path="/account/:component"
            element={
              <UserRegistration
                selectRegComponent={selectRegComponent}
                setSelectedRegComponent={setSelectedRegComponent}
                userId={userId}
                setUserId={setUserId}
              />
            }
          />
          <Route path="/editor" element={<VideoEditor />} />
        </Routes>
      )}
      {/* <TimelineEditor/> */}
    </div>
  );
}

export default App;
