import React, { useEffect, useRef, useState, useCallback } from 'react';
import WaveSurfer from 'wavesurfer.js';
import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline.esm.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions.esm.js';
import Hover from 'wavesurfer.js/dist/plugins/hover.esm.js';
import Spinner from 'react-bootstrap/Spinner';
import './spectralTranscend.css';

export default function Timeline({
  data,
  setData,
  newlyAddedId,
  videoRef,
  isPlaying,
  setIsPlaying,
  zoomLevel,
  setZoomLevel,
  setSeekTime,
  setScrollIndex,
  updateSingleTranscription,
  setRegionChange,
  regionIndex,
  count,
  peaks,
  waveDuration,
  reqIdToRemove,
  removeRegionCount,
  statusCount,
  regionClicked,
}) {
  const wsRegionsRef = useRef(null);
  const currentPositionRef = useRef(0);

  const [activeRegion, setActiveRegion] = useState(null);
  const [wavesurferTemp, setWaveSurferTemp] = useState(null);
  const [timelineReady, setTimelineReady] = useState(false);
  const [activeRegionId, setActiveRegionId] = useState(null);

  function removeRegion() {
    if (
      reqIdToRemove !== null &&
      reqIdToRemove !== undefined &&
      reqIdToRemove !== ''
    ) {
      const matchingElement = wsRegionsRef.current.regions.find(
        (region) => region.id === reqIdToRemove
      );
      if (matchingElement !== null && matchingElement !== undefined) {
        matchingElement.remove();
      }
    }
  }

  useEffect(() => {
    if (wsRegionsRef.current) {
      wsRegionsRef.current.clearRegions();

      data.forEach((item) => {
        const contentDiv = document.createElement('div');
        contentDiv.textContent = item.Text;
        contentDiv.style.display = 'flex';
        contentDiv.style.alignItems = 'center';
        contentDiv.style.height = '100%';
        contentDiv.style.overflow = 'hidden';
        contentDiv.style.textOverflow = 'ellipsis';
        contentDiv.style.whiteSpace = 'nowrap';

        const region = wsRegionsRef.current.addRegion({
          start: item.StartTime,
          end: item.EndTime,
          color: '#ebedf0',
          content: contentDiv,
          resize: true,
          drag: true,
          id: item.Id,
        });

        region.data = { ...item };
        region.element.style.borderRadius = '5px';
        region.element.style.height = '5vh';
        region.element.style.marginBottom = '3vh';
        region.element.style.marginTop = '3vh';
        region.element.style.paddingLeft = '20px';
        region.element.style.paddingRight = '20px';
        region.element.style.overflow = 'hidden';
        region.element.style.cursor = 'pointer';

        // triggerRegionClick(region.id);
        // if (data.length > 0) {
        //   const lastRegionId = data[data.length - 1].Id;
        //   triggerRegionClick(lastRegionId);
        // }

        // const mediaQuery1600 = window.matchMedia('(min-width: 1600px)');
        // const mediaQuery1080 = window.matchMedia('(min-width: 1080px)');

        // const handleViewportChange = () => {
        //   if (region && region.element.style) {
        //     if (mediaQuery1600.matches) {
        //       region.element.style.marginTop = '2vh';
        //     }
        //     if (mediaQuery1080.matches) {
        //       region.element.style.marginTop = '3vh';
        //     }
        //   }
        // };

        // handleViewportChange();

        // const viewportChangeHandler = () => {
        //   handleViewportChange();
        // };
        // mediaQuery1600.addEventListener('change', viewportChangeHandler);
        // mediaQuery1080.addEventListener('change', viewportChangeHandler);
      });
    }

    setData(data);

    console.log(data);
  }, [data]);

  useEffect(() => {
    removeRegion();
    setData(data);
  }, [removeRegionCount]);

  function updateRegion() {
    if (wsRegionsRef.current && data && data[regionIndex]) {
      const regionIdToMatch = data[regionIndex].Id;
      if (regionIdToMatch) {
        const matchingElement = wsRegionsRef.current.regions.find(
          (region) => region.id === regionIdToMatch
        );
        if (matchingElement) {
          matchingElement.remove();
        }
      }

      // // Add new region
      // const contentDiv = document.createElement('div');
      // contentDiv.textContent = data[regionIndex].Text;
      // contentDiv.style.display = 'flex';
      // contentDiv.style.alignItems = 'center';
      // contentDiv.style.height = '100%';
      // contentDiv.style.overflow = 'hidden';
      // contentDiv.style.textOverflow = 'ellipsis';
      // contentDiv.style.whiteSpace = 'nowrap';

      // const region = wsRegionsRef.current.addRegion({
      //   start: data[regionIndex].StartTime,
      //   end: data[regionIndex].EndTime,
      //   content: contentDiv,
      //   color: '#ebedf0',
      //   resize: true,
      //   drag: true,
      //   id: data[regionIndex].Id,
      // });

      // region.data = { ...data[regionIndex] };
      // region.element.style.borderRadius = '5px';
      // region.element.style.height = '5vh';
      // region.element.style.marginBottom = '3vh';
      // region.element.style.paddingLeft = '20px';
      // region.element.style.paddingRight = '20px';
      // region.element.style.overflow = 'hidden';
      // region.element.style.cursor = 'pointer';
    }
  }

  useEffect(() => {
    // Function to handle region click

    if (wsRegionsRef.current !== null) {
      // Listen for region-clicked event
      wsRegionsRef.current.on('region-clicked', handleRegionClick);
    }

    return () => {
      // Clean up event listener
      if (wsRegionsRef.current !== null) {
        wsRegionsRef.current.un('region-clicked', handleRegionClick);
      }
    };
  }, [activeRegion, data, setScrollIndex, setSeekTime, wavesurferTemp]);

  useEffect(() => {
    console.log('working');
    console.log(regionClicked);

    const triggerRegionClick = (regionId) => {
      if (wsRegionsRef.current && regionId) {
        const region = wsRegionsRef.current.regions.find(
          (region) => region.id === regionId
        );
        if (region) {
          // Simulate a click event
          const event = new MouseEvent('click', { bubbles: true });
          region.element.dispatchEvent(event);
        }
      }
    };

    triggerRegionClick(regionClicked);
  }, [regionClicked]);

  const triggerRegionClick = (regionId) => {
    if (wsRegionsRef.current && regionId) {
      const region = wsRegionsRef.current.regions.find(
        (region) => region.id === regionId
      );
      if (region) {
        // Simulate a click event
        const event = new MouseEvent('click', { bubbles: true });
        region.element.dispatchEvent(event);
        setActiveRegion(region);
        // region.element.style.outline = '3px solid black';
        // region.element.style.zIndex = '1000';
      }
    }
  };

  // useEffect(() => {
  //   if (activeRegion && activeRegion.element) {
  //     activeRegion.element.style.outline = 'none';
  //     activeRegion.element.style.zIndex = 'auto';
  //   }
  // }, [activeRegion]);
  const handleRegionClick = (region, e) => {
    e.stopPropagation();
    console.log(region);

    // Remove styles from previously active region
    if (activeRegion && activeRegion.element) {
      activeRegion.element.style.outline = 'none';
      activeRegion.element.style.zIndex = 'auto';
    }

    // Apply styles to the clicked region
    setActiveRegion(region);
    if (region && region.element) {
      region.element.style.outline = '3px solid black';
      region.element.style.zIndex = '1000';
    }

    // Other actions you want to perform when a region is clicked
    setSeekTime({ value: region.start });
    wavesurferTemp.setTime(region.start);
    const index = data.findIndex((item) => item.Id === region.data.Id);
    if (index !== -1) {
      setScrollIndex(index);
    }
  };

  // Ensure region.element exists before accessing it
  // const handleRegionOut = (region) => {
  //   if (activeRegion === region && region && region.element) {
  //     region.element.style.outline = 'none'; // Remove styles from the region
  //     setActiveRegion(null);
  //   }
  // };

  // // Ensure region.element exists before accessing it
  // const handleRegionIn = (region) => {
  //   setActiveRegion(region);
  // };

  const formatTimeCallback = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    const milliseconds = Math.floor((seconds % 1) * 1000);

    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${remainingSeconds
      .toString()
      .padStart(2, '0')}.${milliseconds
      .toString()
      .padStart(3, '0')
      .slice(0, 2)}`;
  };

  const handleRegionUpdate = (region) => {
    console.log(data);
    console.log(region);
    const duration = region.end - region.start;
    // Find the index of the region in the data array
    const index = data.findIndex((item) => item.Id === region.data.Id);

    if (index !== -1) {
      // Update the start and end times in the data array
      const arr = [data[index]];
      const temp = arr.map((item) => ({
        ...item,
        StartTime: region.start,
        EndTime: region.end,
        Duration: duration,
      }));

      updateSingleTranscription(temp, index);
      setRegionChange(true);
    }
    // setActiveRegion(index)
  };

  useEffect(() => {
    if (wavesurferTemp) {
      currentPositionRef.current =
        wavesurferTemp.getCurrentTime() / wavesurferTemp.getDuration();
    }
    const wavesurfer = WaveSurfer.create({
      container: '#waveform',
      waveColor: 'lightgrey',
      progressColor: 'grey',
      media: videoRef.current,
      height: 120,
      barAlign: 'bottom',
      barHeight: 1,
      barWidth: 2,
      hideScrollbar: false,
      minPxPerSec: zoomLevel,
      fillParent: true,
      autoScroll: true,
      peaks: peaks,
      // normalize:true,
      duration: waveDuration,
      plugins: [
        Hover.create({
          lineColor: '#ff0000',
          formatTimeCallback: formatTimeCallback,
          lineWidth: 2,
          labelBackground: '#555',
          labelColor: '#fff',
          labelSize: '11px',
        }),
      ],
    });

    wavesurfer.registerPlugin(
      TimelinePlugin.create({
        height: 20,
        insertPosition: 'beforebegin',
        timeInterval: 0.2,
        primaryLabelInterval: 1,
        secondaryLabelInterval: 1,
        style: {
          fontSize: '12px',
          color: 'grey',
        },
      })
    );

    /** When the waveform is scrolled (panned) */
    // wavesurfer.on('scroll', (visibleStartTime, visibleEndTime) => {
    //     console.log('Scroll', visibleStartTime + 's', visibleEndTime + 's')
    // })
    wavesurfer.once('interaction', () => {
      // setActiveRegion(null);
      if (activeRegion && activeRegion.element) {
        activeRegion.element.style.outline = 'none';
        activeRegion.element.style.zIndex = 'auto';
      }

      // setIsPlaying(true);
      // wavesurfer.play();
    });

    /** When the audio has been decoded */
    wavesurfer.on('decode', (duration) => {
      // console.log('audio decoded');
      // console.log('Decode', duration + 's');
      // setData(data);

      if (currentPositionRef.current > 0) {
        wavesurfer.seekTo(currentPositionRef.current);
      }
      setTimelineReady(true);

      wsRegionsRef.current = wavesurfer.registerPlugin(RegionsPlugin.create());

      // Give regions a random color when they are created

      data.forEach((item, index) => {
        const contentDiv = document.createElement('div');
        contentDiv.textContent = item.Text;
        contentDiv.style.display = 'flex';
        contentDiv.style.alignItems = 'center';
        contentDiv.style.height = '100%';
        contentDiv.style.overflow = 'hidden';
        contentDiv.style.textOverflow = 'ellipsis';
        contentDiv.style.whiteSpace = 'nowrap';
        const region = wsRegionsRef.current.addRegion({
          start: item.StartTime,
          end: item.EndTime,
          color: '#ebedf0',
          content: contentDiv,
          // minLength: 1,
          // maxLength: 10,
          resize: true,
          drag: true,
          id: item.Id,
        });

        region.data = { ...item };
        region.element.style.borderRadius = '5px';
        region.element.style.height = '5vh';
        region.element.style.marginBottom = '3vh';
        region.element.style.marginTop = '3vh';
        region.element.style.paddingLeft = '20px';
        region.element.style.paddingRight = '20px';
        region.element.style.overflow = 'hidden';
        region.element.style.cursor = 'pointer';

        // triggerRegionClick(region.id);
      });
      // wavesurfer.on('region-update-end', handleRegionUpdate);
      // const newRegionId = data.length > 0 ? data[data.length - 1].Id : null;

      wsRegionsRef.current.on(
        'region-updated',
        handleRegionUpdate,

        (region) => {}
      );

      // Loop a region on click
      let loop = false;

      //   let activeRegion = null;
      wsRegionsRef.current.on('region-in', (region) => {
        // console.log('region-in', region)
        // activeRegion = region
        setActiveRegion(region);
      });
      wsRegionsRef.current.on('region-out', (region) => {
        // console.log('region-out', region)
        if (activeRegion === region) {
          if (loop) {
            // setIsPlaying(true);
            // region.play()
          } else {
            //   activeRegion = null
            setActiveRegion(null);
          }
        }
      });
      wsRegionsRef.current.on('region-clicked', (region, e) => {
        e.stopPropagation();
        setActiveRegion(region);

        // Change the background color of the clicked region's element
        // region.element.style.border = '5px solid blue';
        // region.element.style.zIndex = '1000';

        // Other actions you want to perform when a region is clicked
        setSeekTime({ value: region.start });
        wavesurfer.setTime(region.start);
        const index = data.findIndex((item) => item.Id === region.data.Id);
        if (index !== -1) {
          setScrollIndex(index);
        }
      });
      // if (newRegionId) {
      //   triggerRegionClick(newRegionId);
      // }
      // console.log('regions are ',wsRegions.regions[0].data);
    });

    // console.log('status count is',statusCount);
    // wavesurfer.once('ready', (region) => {
    //   if (newlyAddedId) {
    //     triggerRegionClick(newlyAddedId);
    //   }
    // });

    wavesurfer.once('decode', () => {
      if (newlyAddedId) {
        triggerRegionClick(newlyAddedId);
        setActiveRegion(newlyAddedId);
      }
      setWaveSurferTemp(wavesurfer);
      document.querySelector('#zoom').oninput = (e) => {
        const minPxPerSec = Number(e.target.value);
        wavesurfer.zoom(minPxPerSec);
      };
      setTimelineReady(true);
    });

    // Reset the active region when the user clicks anywhere in the waveform
    wavesurfer.on('interaction', () => {
      // setActiveRegion(null);
      if (activeRegion && activeRegion.element) {
        activeRegion.element.style.outline = 'none';
        activeRegion.element.style.zIndex = 'auto';
      }
    });

    // Clean up function
    return () => {
      wavesurfer.destroy();
    };
  }, [peaks, statusCount]);

  useEffect(() => {
    if (isPlaying) {
      if (wavesurferTemp) {
        wavesurferTemp.play();
      }
    } else {
      if (wavesurferTemp) {
        wavesurferTemp.pause();
      }
    }
  }, [isPlaying]);

  useEffect(() => {
    if (wsRegionsRef.current !== null) {
      updateRegion();
    }
  }, [count]);

  return (
    <div className="mt-2" style={{ height: 'max-content' }}>
      {timelineReady === false ? (
        <p>
          Loading timeline
          <span className="ms-1">
            <Spinner size="sm" animation="border" variant="secondary" />
          </span>
        </p>
      ) : null}
      <div
        className="mb-2"
        style={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        <span className="me-2">Zoom :</span>
        {timelineReady ? (
          <input
            type="range"
            min={100}
            max={1000}
            value={zoomLevel}
            id="zoom"
            onChange={(e) => setZoomLevel(e.target.value)}
            style={{ width: '150px' }}
          />
        ) : (
          <input
            type="range"
            min={100}
            max={500}
            value={zoomLevel}
            id="zoom"
            onChange={(e) => setZoomLevel(e.target.value)}
            style={{ width: '200px' }}
            disabled
          />
        )}
      </div>
      <div id="waveform" style={{ borderTop: '1px solid grey' }}></div>
    </div>
  );
}
