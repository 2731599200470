import React, { useState, useEffect } from "react";
import { FcGoogle } from "react-icons/fc";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "./registration.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga";

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    backgroundColor: "black",
    color: "lightblue",
    "& fieldset": {
      borderColor: "lightblue",
    },
    "&:hover fieldset": {
      borderColor: "lightblue",
    },
    "&.Mui-focused fieldset": {
      borderColor: "lightblue",
    },
    "& .MuiInputBase-input": {
      backgroundColor: "black !important",
    },
  },
  "& .MuiInputLabel-root": {
    color: "lightblue",
  },
  "& .MuiSelect-root": {
    backgroundColor: "black",
    color: "lightblue",
    "&:focus": {
      backgroundColor: "black",
    },
  },

  width: "90%",
  height: "40px",
  margin: "5%",
}));

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  color: "lightblue", // Change the icon button color to lightblue
}));
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .test(
      "is-public-email",
      "Public email addresses are not allowed",
      (value) => {
        // Check if the email belongs to a public domain
        const publicDomains = [
          "gmail.com",
          "hotmail.com",
          "yahoo.com",
          "outlook.com",
          "yahoo.co.uk",
          "yahoo.ca",
          "yahoo.com.au",
          "yahoo.in",
          "yahoo.com.br",
          "yahoo.fr",
          "yahoo.de",
          "yahoo.it",
          "yahoo.es",
          "fastmail.com",
        ];
        const domain = value.split("@")[1];
        return !publicDomains.includes(domain);
      }
    )
    .required("Work email address is required"),
  password: Yup.string()
    .matches(
      /^(?=.*[A-Z])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "At least 8 charaters with 1 capital letter and 1 special character required"
    )
    .required("Password is required"),
});

// component starts here ------------------------------------------------------------------------/

export default function Signup({
  setSelectedRegComponent,
  vCode,
  setVCode,
  setRegEmail,
  setRegPwd,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const [flag, setFlag] = useState(false);
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState(false);
  const [isActivated, setIsActivated] = useState(false);
  const apiURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    document.title = "SignUp - Spectral Studio";
  }, []);

  // google analytics

  const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview("/account/signup");
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: (values) => {
      setFlag(true);

      const reqBody = {
        Email: values.email,
        Password: values.password,
      };
      fetch(`${apiURL}/web/register`, {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
          // 'Access-Control-Allow-Origin': "*",
        },
        body: JSON.stringify(reqBody),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.AlreadyActivated === true) {
            setIsActivated(data.AlreadyActivated);
          }
          if (data.IsValid === true) {
            setRegEmail(values.email);
            setRegPwd(values.password);
            setFlag(false);
            if (data.AlreadyActivated === false) {
              setSelectedRegComponent("uform");
            }
          } else {
            setFlag(false);
            setErrorMsg(true);
          }
        })
        .catch((error) => {
          setFlag(false);
          console.error("API call failed:", error);
        });
    },
  });

  const isFormValid = formik.dirty && formik.isValid;
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const handleAutofillStyleRemoval = (event) => {
      if (event.animationName === "onAutoFillStart") {
        event.target.style.backgroundColor = "black";
        event.target.style.borderColor = "lightblue";
      }
    };

    document.addEventListener("animationstart", handleAutofillStyleRemoval);

    return () => {
      document.removeEventListener(
        "animationstart",
        handleAutofillStyleRemoval
      );
    };
  }, []);

  return (
    <div
      className="signUpPage"
      style={{ margin: "5%", height: "55vh", overflow: "auto" }}
    >
      <h4 style={{ marginTop: "5%", marginLeft: "5%" }}>
        Sign up to get started
      </h4>
      {/* <button className='googleButton'><span style={{fontSize:'25px',marginRight:'10px'}}><FcGoogle/></span>Continue with Google</button>
                <p style={{textAlign:'center',marginTop:'5%',color:'white'}}> or</p>  */}
      <form onSubmit={formik.handleSubmit}>
        <CustomTextField
          className={
            formik.touched.email && Boolean(formik.errors.email)
              ? "mb-5"
              : "mb-4"
          }
          label="Work email address"
          variant="outlined"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          fullWidth
        />
        <CustomTextField
          className={
            formik.touched.password && Boolean(formik.errors.password)
              ? "mb-5"
              : "mb-4"
          }
          label="Password"
          variant="outlined"
          fullWidth
          type={showPassword ? "text" : "password"}
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CustomIconButton onClick={handleTogglePassword} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </CustomIconButton>
              </InputAdornment>
            ),
          }}
        />

        {flag === false ? (
          <button type="submit" className="continueBtn">
            Continue
          </button>
        ) : (
          <button className="continueBtn" disabled>
            Continue
          </button>
        )}
      </form>
      <p style={{ color: "white", marginLeft: "5%", marginTop: "5%" }}>
        Already have an account?{" "}
        <button
          type="button"
          className="signup"
          onClick={(e) => navigate("/account/login")}
        >
          <strong>Log in</strong>
        </button>
      </p>
      {isActivated === true ? (
        <p style={{ color: "red", marginLeft: "5%" }}>* User already exists!</p>
      ) : errorMsg === true ? (
        <p style={{ color: "red", marginLeft: "5%" }}>
          * Error occurred. Please contact support.
        </p>
      ) : null}
    </div>
  );
}
