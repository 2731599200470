import React from 'react';
import spectral from './multimedia/Spectral Logo_Final_Red.png';
import { BsInfoCircleFill } from "react-icons/bs";

export default function Mobile() {
  return (
    <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
         <img className='mob-logo mt-5' src={spectral} alt='logo'/>
         <p className='mt-5' style={{textAlign:'center',fontSize:'50px'}}><BsInfoCircleFill /></p>
         <p style={{color:'grey',fontWeight:500}}>Device not supported</p>
        <p className='mt-5' style={{textAlign:'center'}}>
        The Spectral Studio is currently not supported on phone devices. Please use a web browser on a computer.
        </p>
    </div>
  )
}
