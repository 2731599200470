import React, { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { MdPauseCircle, MdPlayCircle } from 'react-icons/md';

const AudioWaveform = ({ data, sample, peaks, waveDuration }) => {
  const containerRef = useRef(null);
  const wavesurferRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (containerRef.current) {
      wavesurferRef.current = WaveSurfer.create({
        container: containerRef.current,
        waveColor: 'lightgrey',
        progressColor: 'grey',
        height: 120,
        barHeight: 1,
        barWidth: 2,
        cursorColor: '#454848',
        cursorWidth: 1,
        media: document.querySelector('audio'),
        backend: 'MediaElement',
        mediaControls: true,
        peaks: peaks,
        duration: waveDuration,
      });

      wavesurferRef.current.on('play', () => setIsPlaying(true));
      wavesurferRef.current.on('pause', () => setIsPlaying(false));

      return () => {
        wavesurferRef.current.destroy();
      };
    }
  }, [data, peaks, waveDuration]);

  const togglePlayPause = () => {
    if (wavesurferRef.current) {
      if (isPlaying) {
        wavesurferRef.current.pause();
      } else {
        wavesurferRef.current.play();
      }
    }
  };

  return (
    <>
      <div className="waveForm">
        <div className="exBtn2" onClick={togglePlayPause}>
          {isPlaying ? (
            <MdPauseCircle style={{ width: '60px', height: '60px' }} />
          ) : (
            <MdPlayCircle style={{ width: '60px', height: '60px' }} />
          )}
        </div>
        <audio
          preload="none"
          className="invisible"
          src={data}
          controls
          disablePictureInPicture
          controlsList="nodownload noplaybackrate"
        />
        <div ref={containerRef} className="wf2" id="waveform" />
      </div>
    </>
  );
};

export default AudioWaveform;
