import React, { useEffect, useState } from 'react';
// import UserList from './UserList';
// import EditUser from "./EditUser";
// import ManageUserProjects from "./ManageUserProjects";
import { Button } from 'react-bootstrap';
import { IoFilterSharp } from 'react-icons/io5';
import { Routes, Route, Link, Outlet, useLocation } from 'react-router-dom';
import Voice from './VoiceComponents/Voice';
import ManageVoice from './VoiceComponents/ManageVoice';
import EditVoice from './VoiceComponents/EditVoice';
import Face3D from './Face3D';
import Avatar from './Avatar';
import Face from './Face';

export default function DigitalTwin({
  selectedDigitalTwinComponent,
  setSelectedDigitalTwinComponent,
  searchText,
  setSearchText,
  userInfo,
  setUserInfo,
}) {
  const [userList, setUserList] = useState([]);
  const [filteredUserList, setFilteredUserList] = useState([]);
  const [user, setUser] = useState({});
  const [voiceData, setVoiceData] = useState([]);

  const [drmVoiceId, setDrmVoiceId] = useState();
  const [selectedRowData, setSelectedRowData] = useState(); // State for storing selected row data
  const [editClicked, setEditClicked] = useState(false);

  const [filterOn, setFilterOn] = useState(false);

  useEffect(() => {
    document.title = 'Digital Twin(voice) - Spectral Studio';
  }, []);

  const renderSelectedDigitalTwinComponent = () => {
    switch (selectedDigitalTwinComponent) {
      case 'voice':
        return (
          <Voice
            searchText={searchText}
            setSearchText={setSearchText}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            voiceData={voiceData}
            setVoiceData={setVoiceData}
            drmVoiceId={drmVoiceId}
            setDrmVoiceId={setDrmVoiceId}
            setSelectedDigitalTwinComponent={setSelectedDigitalTwinComponent}
          />
        );
      case 'manage-voice':
        return (
          <ManageVoice
            drmVoiceId={drmVoiceId}
            setDrmVoiceId={setDrmVoiceId}
            voiceData={voiceData}
            setVoiceData={setVoiceData}
            setSelectedDigitalTwinComponent={setSelectedDigitalTwinComponent}
            editClicked={editClicked}
            setEditClicked={setEditClicked}
            selectedRowData={selectedRowData}
            setSelectedRowData={setSelectedRowData}
          />
        );
      case 'edit-voice':
        return (
          <EditVoice
            drmVoiceId={drmVoiceId}
            setDrmVoiceId={setDrmVoiceId}
            voiceData={voiceData}
            setVoiceData={setVoiceData}
            setSelectedDigitalTwinComponent={setSelectedDigitalTwinComponent}
            editClicked={editClicked}
            setEditClicked={setEditClicked}
            selectedRowData={selectedRowData}
            setSelectedRowData={setSelectedRowData}
          />
        );
      case 'face':
        return (
          <Face
            setSelectedDigitalTwinComponent={setSelectedDigitalTwinComponent}
          />
        );
      case 'face-3d':
        return (
          <Face3D
            setSelectedDigitalTwinComponent={setSelectedDigitalTwinComponent}
          />
        );
      case 'avatar':
        return (
          <Avatar
            setSelectedDigitalTwinComponent={setSelectedDigitalTwinComponent}
          />
        );
      //   case 'manage-lip-sync-requests':
      //     return (
      //       <ManageLipSyncRequests searchText={searchText} filterOn={filterOn} />
      //     );
      //   case 'manage-projects':
      //     return (
      //       <ManageUserProjects searchText={searchText} filterOn={filterOn} />
      //     );
      //   case 'user-edit':
      //     return (
      //       <EditUser
      //         filteredUserList={filteredUserList}
      //         setFilteredUserList={setFilteredUserList}
      //         user={user}
      //         setUser={setUser}
      //         setSelectedAdminComponent={setSelectedAdminComponent}
      //       />
      //     );
      default:
        return null;
    }
  };

  return (
    <div>
      {/* {renderSelectedDigitalTwinComponent()} */}
      <Routes>
        <Route
          path="/"
          element={
            <Voice
              searchText={searchText}
              setSearchText={setSearchText}
              userInfo={userInfo}
              setUserInfo={setUserInfo}
              voiceData={voiceData}
              setVoiceData={setVoiceData}
              drmVoiceId={drmVoiceId}
              setDrmVoiceId={setDrmVoiceId}
              setSelectedDigitalTwinComponent={setSelectedDigitalTwinComponent}
            />
          }
        />
        <Route
          path="voice"
          element={
            <Voice
              searchText={searchText}
              setSearchText={setSearchText}
              userInfo={userInfo}
              setUserInfo={setUserInfo}
              voiceData={voiceData}
              setVoiceData={setVoiceData}
              drmVoiceId={drmVoiceId}
              setDrmVoiceId={setDrmVoiceId}
              setSelectedDigitalTwinComponent={setSelectedDigitalTwinComponent}
            />
          }
        />
        <Route
          path="manage-voice"
          element={
            <ManageVoice
              drmVoiceId={drmVoiceId}
              setDrmVoiceId={setDrmVoiceId}
              voiceData={voiceData}
              setVoiceData={setVoiceData}
              setSelectedDigitalTwinComponent={setSelectedDigitalTwinComponent}
              editClicked={editClicked}
              setEditClicked={setEditClicked}
              selectedRowData={selectedRowData}
              setSelectedRowData={setSelectedRowData}
            />
          }
        />
        <Route
          path="edit-voice"
          element={
            <EditVoice
              drmVoiceId={drmVoiceId}
              setDrmVoiceId={setDrmVoiceId}
              voiceData={voiceData}
              setVoiceData={setVoiceData}
              setSelectedDigitalTwinComponent={setSelectedDigitalTwinComponent}
              editClicked={editClicked}
              setEditClicked={setEditClicked}
              selectedRowData={selectedRowData}
              setSelectedRowData={setSelectedRowData}
            />
          }
        />
        <Route
          path="face"
          element={
            <Face
              setSelectedDigitalTwinComponent={setSelectedDigitalTwinComponent}
            />
          }
        />
        <Route
          path="face-3d"
          element={
            <Face3D
              setSelectedDigitalTwinComponent={setSelectedDigitalTwinComponent}
            />
          }
        />
        <Route
          path="avatar"
          element={
            <Avatar
              setSelectedDigitalTwinComponent={setSelectedDigitalTwinComponent}
            />
          }
        />
      </Routes>
    </div>
  );
}
