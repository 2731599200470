import React, { useEffect, useState } from 'react';
import {
  Container,
  InputGroup,
  OverlayTrigger,
  Tooltip,
  Table,
} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import loading from '../../../multimedia/loading.gif';
import { HiInformationCircle } from 'react-icons/hi2';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { RiArrowUpSLine, RiArrowDownSLine } from 'react-icons/ri';
import FormatTime from '../../../utils/FormatTime';

export default function AudioSegmentPage2({
  segmentData,
  segmentHistoryData,
  setSegmentHistoryData,
  addSegmentToHistory,
  flag,
  setFlag,
  setNextEnable,
  projectId,
  segmentId,
  setAudioSegments,
  handleClose,
}) {
  const apiURL = process.env.REACT_APP_API_URL;
  const [selectedVoiceId, setSelectedVoiceId] = useState(segmentData.VoiceId);

  const voices = JSON.parse(localStorage.getItem('voiceList'));
  const [isChecked, setIsChecked] = useState(false);
  const [stability, setStability] = useState(0.0);
  const [similarity, setSimilarity] = useState(0);
  const [text, setText] = useState('');
  const [style, setStyle] = useState(0);
  const [generatedData, setGeneratedData] = useState([]);
  const [generate, setGenerate] = useState(false);
  const [wait, setWait] = useState(false);
  const [count, setCount] = useState(0);
  const [speed, setSpeed] = useState(0);
  const [transferSegmentData, setTransferSegmentData] = useState({});
  const [newSegmentHistoryData, setNewSegmentHistoryData] = useState({});

  const [speakerShow, setSpeakerShow] = useState(false);

  const [speakers, setSpeakers] = useState([]);
  let voicesArray = JSON.parse(localStorage.getItem('voiceList'));

  const maxSpeed = 2;
  const minSpeed = 0.5;
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);
    const milliseconds = Math.floor((time - Math.floor(time)) * 1000);

    const formattedMilliseconds = milliseconds
      .toString()
      .padStart(3, '0')
      .substring(0, 2);

    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}.${formattedMilliseconds}`;
  };

  useEffect(() => {
    if (segmentData) {
      setStability(segmentData.AudioSettings.variance);
      // setSimilarity(segmentData.AudioSettings.similarity_boost);
      setStyle(segmentData.AudioSettings.style);
      setText(segmentData.Text);
      // if (segmentData.AudioSettings.use_speaker_boost === 1) {
      //   setIsChecked(true);
      // }
      setSpeed(segmentData.AudioSettings.speed);
    }
  }, []);

  useEffect(() => {
    var audio = document.getElementById('audio-Player');
    var timeDisplay = document.querySelector('#current-time');
    var totalTimeDisplay = document.querySelector('#total-time');
    if (audio && timeDisplay) {
      audio.addEventListener('timeupdate', function () {
        var currentTime = audio.currentTime;
        var duration = isNaN(audio.duration) ? 0 : audio.duration;
        var currentMinutes = Math.floor(currentTime / 60);
        var currentSeconds = Math.floor(currentTime - currentMinutes * 60);
        var currentMilliseconds = Math.floor(
          (currentTime - Math.floor(currentTime)) * 1000
        );
        var durationMinutes = Math.floor(duration / 60);
        var durationSeconds = Math.floor(duration - durationMinutes * 60);
        var durationMilliseconds = Math.floor(
          (duration - Math.floor(duration)) * 1000
        );

        var currentTimeString =
          currentMinutes +
          ':' +
          (currentSeconds < 10 ? '0' : '') +
          currentSeconds +
          '.' +
          (currentMilliseconds < 100 ? '0' : '') +
          (currentMilliseconds < 10 ? '0' : '') +
          currentMilliseconds;
        var durationString =
          durationMinutes +
          ':' +
          (durationSeconds < 10 ? '0' : '') +
          durationSeconds +
          '.' +
          (durationMilliseconds < 100 ? '0' : '') +
          (durationMilliseconds < 10 ? '0' : '') +
          durationMilliseconds;

        timeDisplay.innerHTML = currentTimeString;
        totalTimeDisplay.innerHTML = durationString;
      });
    }
  }, [segmentData, generatedData]);

  // generate api call here=================================================================================//

  const generateSingleAudioSegment = () => {
    setWait(true);

    const req = {
      ProjectId: projectId,
      TransferAudioSegmentId: segmentId,
      AudioSettings: {
        variance: stability,
        // similarity_boost: similarity,
        style: style,
        // use_speaker_boost: isChecked ? 1 : 0,
      },
      VoiceId: selectedVoiceId,
    };

    fetch(`${apiURL}/dub/generate_single_sts_segment`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(req),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.IsValid) {
          setTransferSegmentData(result.TransferAudioSegmentResult);
          const newSegment = {
            VC_SegmentId: result.TransferAudioSegmentResult.VC_SegmentId,
            VC_SegmentUrl: result.TransferAudioSegmentResult.VC_SegmentUrl,
            VC_FileName: result.TransferAudioSegmentResult.VC_FileName,
            VoiceId: result.TransferAudioSegmentResult.VoiceId,
            VoiceName: result.TransferAudioSegmentResult.VoiceName,
            CreatedAt: result.TransferAudioSegmentResult.CreatedAt,
            Status: result.TransferAudioSegmentResult.Status,
            IsSelected: result.TransferAudioSegmentResult.IsSelected,
          };

          addSegmentToHistory(newSegment);
          setGenerate(true);
          setWait(false);
          if (
            result.TransferAudioSegmentResult.VC_SegmentUrl !== '' &&
            result.TransferAudioSegmentResult.VC_SegmentUrl !== null &&
            result.TransferAudioSegmentResult.VC_SegmentUrl !== undefined
          ) {
            setTimeout(() => {
              playAudio();
            }, 100);
          }
        }
      })
      .catch((error) => {
        setWait(false);
      });
  };

  async function updateAudioSegment() {
    if (generate === true) {
      const req = {
        ProjectId: projectId,
        TransferAudioSegmentId: segmentData.Id,
        VC_SegmentId: transferSegmentData.VC_SegmentId,
      };

      try {
        const response = await fetch(`${apiURL}/dub/replace_VT_audio_segment`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(req),
        });

        const result = await response.json();

        if (result.IsValid) {
          fetchAudioSegments();
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    }
  }

  async function updateAudioSegment2(VC_SegmentId) {
    const segmentToSave = segmentHistoryData.find(
      (data) => data.VC_SegmentId === VC_SegmentId
    );
    if (!segmentToSave) {
      console.error(`Segment with id ${VC_SegmentId} not found.`);
      return;
    }

    const req = {
      ProjectId: projectId,
      TransferAudioSegmentId: segmentData.Id,
      VC_SegmentId: segmentToSave.VC_SegmentId,
    };

    try {
      const response = await fetch(`${apiURL}/dub/replace_VT_audio_segment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(req),
      });

      const result = await response.json();

      if (result.IsValid) {
        fetchAudioSegments(); // Update audio segments after saving
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }

  // Fetch audio segments api call

  const fetchAudioSegments = async () => {
    const reqBody = {
      ProjectId: projectId,
      TargetLanguageId: segmentData.TargetLangId,
    };
    try {
      const response = await fetch(`${apiURL}/web/get_VT_audio_segments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reqBody),
      });

      if (response.ok) {
        const newData = await response.json();

        if (newData.IsValid) {
          if (newData.Status === 204 || newData.Status === 205) {
            setNextEnable(true);
          }
          setAudioSegments([...(newData.ResultAudioSegments || [])]);
          handleClose();
        }
      } else {
        throw new Error('Failed to fetch project data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  function playAudio() {
    const audio = document.getElementById('audio-Player');
    audio.play();
  }

  const InformationCircleTooltip = ({ id, text }) => (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip style={{ fontSize: '12px' }} id={`tooltip-${id}`}>
          {text}
        </Tooltip>
      }
    >
      <span
        style={{
          fontSize: '16px',
          marginTop: '12px',
          marginLeft: '6px',
          color: 'gray',
        }}
      >
        <HiInformationCircle />
      </span>
    </OverlayTrigger>
  );

  function minSpeedChange() {
    let temp = speed;
    if (speed > minSpeed) {
      setSpeed(parseFloat((temp - 0.1).toFixed(1)));
    }
  }
  function maxSpeedChange() {
    let temp = speed;
    if (speed < maxSpeed) {
      setSpeed(parseFloat((temp + 0.1).toFixed(1)));
    }
  }
  return (
    <div className="segmentPage2">
      <Form>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            marginTop: -5,
          }}
        >
          <div style={{ width: '49.4%' }}>
            <Form.Label style={{ fontWeight: 500 }}>Audio:</Form.Label>
            <div className="settings1">
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <div className="audioContainer">
                  <Form.Label style={{ fontWeight: 500 }}>Audio: </Form.Label>

                  <audio
                    preload="none"
                    style={{ height: '60px', width: '100%' }}
                    key={segmentData.Id}
                    className="audioFiles"
                    src={`${segmentData.AudioSegmentUrl.trim()}?random=${Math.random()}`}
                    controls
                    disablePictureInPicture
                    controlsList="nodownload noplaybackrate"
                  />
                </div>
              </Form.Group>

              {/*  */}
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ width: '50%', paddingRight: '2%' }}>
                  <Form.Label className="mt-3" style={{ fontWeight: 500 }}>
                    StartTime:{' '}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={`${FormatTime(
                      parseFloat(segmentData.StartTime.toFixed(2))
                    )} s`}
                    readOnly
                  />
                </span>
                <span style={{ width: '50%', paddingLeft: '2%' }}>
                  <Form.Label className="mt-3" style={{ fontWeight: 500 }}>
                    EndTime:{' '}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={`${FormatTime(
                      parseFloat(segmentData.EndTime.toFixed(2))
                    )} s`}
                    readOnly
                  />
                </span>
                <span style={{ width: '50%', paddingLeft: '2%' }}>
                  <Form.Label className="mt-3" style={{ fontWeight: 500 }}>
                    Duration:{' '}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={`${parseFloat(
                      segmentData.VC_Segment_Duration.toFixed(2)
                    )}s`}
                    readOnly
                  />
                </span>
              </div>

              <div style={{}}>
                <span style={{ display: 'flex', flexDirection: 'column' }}>
                  <Form.Label className="mt-3" style={{ fontWeight: 500 }}>
                    Voice:{' '}
                  </Form.Label>
                  <select
                    style={{
                      width: '100%',
                      forcedColorAdjust: 'none',
                      outlin: 'none',
                      outlineColor: 'transparent',
                      borderRadius: '8px',
                    }}
                    as="select"
                    value={selectedVoiceId}
                    onChange={(e) => setSelectedVoiceId(e.target.value)} // Update the selected Voice ID
                  >
                    {voicesArray.map((voice) => (
                      <option key={voice.Id} value={voice.Id}>
                        {voice.Name}
                      </option>
                    ))}
                  </select>
                </span>
              </div>
            </div>
          </div>
          <div style={{ width: '49.4%' }}>
            <Form.Label style={{ fontWeight: 500 }}>
              Settings{' '}
              <span
                style={{ color: 'gray', cursor: 'pointer' }}
                onClick={(e) =>
                  window.open(
                    'https://spectralstudios.ai/voice-settings/',
                    '_blank'
                  )
                }
              >
                <HiInformationCircle />
              </span>{' '}
              :
            </Form.Label>
            <div className="settings1">
              <div>
                <span>Variance:</span>
                <Form.Range
                  className="mt-1"
                  min={0}
                  max={1}
                  step={0.05}
                  defaultValue={segmentData.AudioSettings.variance}
                  onChange={(e) => setStability(e.target.value)}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    fontSize: '12px',
                    marginTop: '-10px',
                  }}
                >
                  <Form.Label style={{ fontWeight: 500, color: 'gray' }}>
                    <span>More Variable</span>
                    <span style={{ fontSize: '16px' }}>
                      <InformationCircleTooltip
                        id="more-variable"
                        text="Increasing variability can make speech more expressive
      without output varying between re-generations. It can also lead to instabilities."
                      />
                    </span>
                  </Form.Label>
                  <Form.Label style={{ fontWeight: 500, color: 'gray' }}>
                    <span>More Stable</span>
                    <span style={{ fontSize: '16px' }}>
                      <InformationCircleTooltip
                        id="more-stable"
                        text="Increasing stability will make the voice more consistent between re-generations,
         but it can also make it sound a bit monotone. On longer text fragments we recommend lowering this value."
                      />
                    </span>
                  </Form.Label>
                </div>
              </div>
              {/* <div className="mt-1">
                <span>Clarity + Similarity Enhancement:</span>
                <Form.Range
                  className="mt-1"
                  min={0}
                  max={1}
                  step={0.1}
                  defaultValue={segmentData.AudioSettings.similarity_boost}
                  onChange={(e) => setSimilarity(e.target.value)}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    fontSize: '12px',
                    marginTop: '-10px',
                  }}
                >
                  <Form.Label style={{ fontWeight: 500, color: 'gray' }}>
                    <span>Low</span>
                    <span style={{ fontSize: '16px' }}>
                      <InformationCircleTooltip
                        id="low"
                        text="Low values are recommended if background artifacts are present in generated speech."
                      />
                    </span>
                  </Form.Label>
                  <Form.Label style={{ fontWeight: 500, color: 'gray' }}>
                    <span>High</span>
                    <span style={{ fontSize: '16px' }}>
                      <InformationCircleTooltip
                        id="high"
                        text="High enhancement boosts overall voice clarity and target speaker similarity.
         Very high values can cause artifacts, so adjusting this setting to find the optimal value is encouraged."
                      />
                    </span>
                  </Form.Label>
                </div>
              </div> */}
              <div className="mt-1">
                <span>Speaker Style:</span>
                <Form.Range
                  className="mt-1"
                  min={0}
                  max={1}
                  step={0.05}
                  defaultValue={segmentData.AudioSettings.style}
                  onChange={(e) => setStyle(e.target.value)}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    fontSize: '12px',
                  }}
                >
                  <Form.Label
                    style={{
                      fontWeight: 500,
                      color: 'gray',
                      marginTop: '-6px',
                    }}
                  >
                    Normal (Fastest)
                  </Form.Label>
                  <Form.Label
                    style={{
                      fontWeight: 500,
                      color: 'gray',
                      marginTop: '-10px',
                    }}
                  >
                    <span>Exaggerated</span>
                    <span style={{ fontSize: '16px' }}>
                      <InformationCircleTooltip
                        id="exaggerated"
                        text="High values are recommended if the style of the speech should be exaggerated compared to the uploaded audio.
           Higher values can lead to more instability in the generated speech. Setting to 0.0 will greatly increase generation speed and is the default setting."
                      />
                    </span>
                  </Form.Label>
                </div>
              </div>
              {/* <div
                className="mt-2"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Form.Check
                  type="checkbox"
                  label="Speaker Boost"
                  checked={isChecked}
                  onChange={(e) => handleCheckboxChange(e)}
                />
                <span
                  className="mb-1"
                  style={{ fontSize: '16px', color: 'gray' }}
                >
                  <InformationCircleTooltip
                    id="speaker-boost"
                    text="Boost the similarity of the synthesized speech and the voice at the cost of some generation speed."
                  />
                </span>
              </div> */}
              <div
                className="mt-2"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              ></div>
            </div>
          </div>
        </div>
      </Form>

      {/* --------------------------------------------- */}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className="fileToolBar2 mt-3">
          <div
            className=""
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            {wait === false ? (
              <Button
                variant="success"
                onClick={(e) => generateSingleAudioSegment()}
              >
                Generate
              </Button>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button variant="secondary" disabled>
                  Generate
                </Button>
                <span>
                  <img
                    style={{
                      width: '50px',
                      height: '50px',
                      marginLeft: '10px',
                    }}
                    src={loading}
                    alt="loading"
                  />
                </span>
              </div>
            )}
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: '15px',
            }}
          >
            <audio
              id="audio-Player"
              type="audio/wav"
              key={`audio-${count}`}
              className=""
              src={
                transferSegmentData.VC_SegmentUrl == null
                  ? `${segmentData.VC_SegmentUrl.trim()}?random=${Math.random()}`
                  : `${transferSegmentData.VC_SegmentUrl.trim()}?random=${Math.random()}`
              }
              controls
              disablePictureInPicture
              controlsList="nodownload noplaybackrate"
            />
            <span
              id="current-time"
              style={{
                fontWeight: 600,
                fontSize: '14px',
                width: '53px',
                display: 'inline-block',
              }}
            ></span>{' '}
            <span
              className="ms-1 me-1"
              style={{ fontWeight: 600, fontSize: '14px' }}
            >
              /
            </span>{' '}
            <span
              id="total-time"
              style={{ fontWeight: 600, fontSize: '14px' }}
            ></span>
          </div>
          <Button variant="primary" onClick={(e) => updateAudioSegment()}>
            Save
          </Button>
        </div>
      </div>
      {/* --------------------------- */}
      <Form.Label style={{ fontWeight: 500 }}>History:</Form.Label>

      <div className="editTableContainer">
        <Form.Group controlId="exampleForm.ControlTextarea1">
          <div className="tableDiv1">
            <Table striped bordered className="mt-2">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>File Name</th>
                  <th>Audio</th>

                  <th>
                    <span>Voice</span>
                  </th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {segmentHistoryData.map((data, index) => (
                  <tr key={data.VC_SegmentId}>
                    <td style={{ verticalAlign: 'middle' }}>{index + 1}</td>
                    <td style={{ verticalAlign: 'middle' }}>
                      <div>
                        <span style={{ verticalAlign: 'middle' }}>
                          {data.VC_FileName}
                        </span>
                      </div>
                    </td>

                    <td style={{ verticalAlign: 'middle', width: '500px' }}>
                      <audio
                        preload="none"
                        style={{ height: '50px', width: '31.5vw' }}
                        key={data.VC_SegmentId}
                        className="audioFiles"
                        src={`${data.VC_SegmentUrl.trim()}?random=${Math.random()}`}
                        controls
                        disablePictureInPicture
                        controlsList="nodownload noplaybackrate"
                      />
                    </td>

                    <td style={{ verticalAlign: 'middle' }}>
                      {data.VoiceName}
                    </td>

                    <td style={{ verticalAlign: 'middle' }}>
                      {data.VC_SegmentId !== segmentData.VC_SegmentId && (
                        <Button
                          variant="primary"
                          onClick={() => updateAudioSegment2(data.VC_SegmentId)}
                        >
                          Save
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Form.Group>
      </div>
    </div>
  );
}
