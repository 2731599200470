import React from "react";
import confirm from "../../multimedia/tick.gif";

export default function Confirmation({ setSelectedRegComponent }) {
  return (
    <div style={{ margin: "5%", height: "55vh" }}>
      <h4 style={{ marginTop: "5%", marginLeft: "5%" }}>
        Sign Up to get Started
      </h4>
      <img
        src={confirm}
        style={{ width: "75%", height: "220px", marginLeft: "15%" }}
        alt="confirmation"
      />
      <p
        style={{
          color: "white",
          marginLeft: "5%",
          marginTop: "5%",
          width: "90%",
          textAlign: "center",
          marginBottom: "5%",
          lineHeight: "20px",
        }}
      >
        Your account request has been successfully registered. A representative
        will get in touch with you shortly.
      </p>
    </div>
  );
}
