import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import Assets from './Assets'
import Player from './Player'
import Properties from './Properties'
import LeftNavBar from './LeftNavBar'
import ProjectSettings from './ProjectSettings/ProjectSettings'
import MediaFiles from './Media/MediaFiles'
import AddMedia from './Media/AddMedia'
import AddAudio from './Media/AddAudio'




export default function VideoEditor() {
  const [selectedComponent, setSelectedComponent] = useState('settings');
  const [durationInSeconds, setDurationInSeconds] = useState(0);
  const [selectedAudioFile,setSelectedAudioFile]=useState({});
  const [selectedVideoFile,setSelectedVideoFile]=useState({});
  useEffect(()=>{
    document.title = 'Editor - Spectral Studio';
  },[])

  
  const renderSelectedComponent = () => {
    switch (selectedComponent) {
      case 'settings':
        return <ProjectSettings />;
      case 'media':
        return <AddMedia selectedVideoFile={selectedVideoFile} setSelectedVideoFile={setSelectedVideoFile}/>;
      case 'audio':
        return <AddAudio setDurationInSeconds={setDurationInSeconds} selectedAudioFile={selectedAudioFile} setSelectedAudioFile={setSelectedAudioFile}/>;
      case 'subtitles':
          return <h3 className="mt-4">Subtitles</h3>
      case 'text':
        return <h3 className="mt-4">Text</h3>
      case 'elements':
        return <h3 className="mt-4">Elements</h3>
      case 'templates':
      return <h3 className="mt-4">Templates</h3>
      case 'record':
      return <h3 className="mt-4">Record</h3>
      default:
        return null;
    }
  };

  return (
    
     
        <div style={{display:'flex',flexDirection:'row'}}>
{/* LeftNavBar component--------------------------------------------------------------------------------------------------------->*/}

       <div style={{width:'5%'}}>
              <LeftNavBar selectedComponent={selectedComponent} setSelectedComponent={setSelectedComponent} renderSelectedComponent={renderSelectedComponent}/>
              </div>

{/* Eidtor component-----------------------------------------------------------------------------------------------------------------> */}


       <div className='vidEditPage' style={{overflowY:'auto',height:'100vh',width:'95%',backgroundColor:'#f7f7f9',overflowX:'hidden'}}>
<Row style={{height:'65%'}}>

<div className='renderedCompLeft' style={{width:'25%',display:'flex',height:'100%',overflowY:'auto',justifyContent:'center',boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',borderBottomRightRadius:'8px'}}>
   
{renderSelectedComponent()}
          
              </div>    
          {/* <Assets /> */}
    
    <div style={{width:'50%'}}>
            <Player />
            </div>
            <div style={{width:'25%',boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',borderBottomLeftRadius:'8px'}}>
<Properties/>
</div>
            </Row>
      
           
    <Row style={{ height:'35%'}}>
      <Col md={12} style={{marginTop:'30px'}}>
         
            <div style={{display:'flex',justifyContent:'center'}}>
          <MediaFiles durationInSeconds={durationInSeconds} setDurationInSeconds={setDurationInSeconds}  selectedAudioFile={selectedAudioFile} setSelectedAudioFile={setSelectedAudioFile} selectedVideoFile={selectedVideoFile} setSelectedVideoFile={setSelectedVideoFile}/>
              {/* <MediaContainer/> */}
            </div>

        
          </Col>
          </Row>
          </div>
          </div>
     
   
  )
}
