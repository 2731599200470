import React, { useEffect, useState } from 'react';
// import VoicePage1 from './VoicePage1';
import { IoFilterSharp } from 'react-icons/io5';
import { FaUpload, FaGoogleDrive, FaDropbox } from 'react-icons/fa6';

import {
  Container,
  InputGroup,
  OverlayTrigger,
  Tooltip,
  Table,
} from 'react-bootstrap';
import { Button, Card } from 'react-bootstrap';
import { MdOutlineEdit } from 'react-icons/md';
import { GoDownload } from 'react-icons/go';
import { RiDeleteBin6Line } from 'react-icons/ri';
import thumbnail from '../../../multimedia/thumbnail1200.png';
import { FiMoreVertical } from 'react-icons/fi';

import ReactTimeAgo from 'react-time-ago';

import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { HiInformationCircle } from 'react-icons/hi2';

import ReactGA from 'react-ga';
import './spectralDRM.css';

export default function ProjectList({
  setSearchText,
  searchText,
  userId,
  userInfo,
  setUserInfo,
}) {
  const [activeTab, setActiveTab] = useState('textToSpeech');
  const [activeTab2, setActiveTab2] = useState('settings');

  const [voicesArray, setVoicesArray] = useState([]);
  const [drmProjectList, setDrmProjectList] = useState([]);
  const apiURL = process.env.REACT_APP_API_URL;

  const InformationCircleTooltip = ({ id, text }) => (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip style={{ fontSize: '12px' }} id={`tooltip-${id}`}>
          {text}
        </Tooltip>
      }
    >
      <span
        style={{
          fontSize: '16px',
          marginTop: '12px',
          marginLeft: '6px',
          color: 'gray',
        }}
      >
        <HiInformationCircle />
      </span>
    </OverlayTrigger>
  );

  const getDrmProjects = async () => {
    const reqBody = {
      UserId: localStorage.getItem('userSessionId'),
      TopN: 25,
    };
    try {
      const response = await fetch(`${apiURL}/web/get_drm_projects`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reqBody),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.IsValid) {
          setDrmProjectList(data.ProjectList);
          console.log(drmProjectList);
        }
      } else {
        throw new Error('Failed to fetch project data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  useEffect(() => {
    document.title = 'Projects - Spectral Studio';
    getDrmProjects();
    // fetchUserInfo();
    // getVoices();
    // setSearchText('');
  }, []);
  //   useEffect(() => {
  //     // Update userInfo state whenever localStorage userInfo changes
  //     setUserInfo(JSON.parse(localStorage.getItem('userInfo')));
  //   }, [localStorage.getItem('userInfo')]);

  const dummyProjects = [
    {
      id: 1,
      title: 'Project 1',
      createdAt: '2024-06-10',
      thumbnail: 'https://via.placeholder.com/150',
    },
    {
      id: 2,
      title: 'Project 2',
      createdAt: '2024-06-11',
      thumbnail: 'https://via.placeholder.com/150',
    },
    {
      id: 3,
      title: 'Project 3',
      createdAt: '2024-06-12',
      thumbnail: 'https://via.placeholder.com/150',
    },

    // Add more projects as needed
  ];

  return (
    <div>
      <div className="project-list-container">
        <header>
          <h2 className="">Projects</h2>
        </header>
      </div>

      <div style={{ marginTop: '5rem' }} className="transcend-cards-container ">
        <span
          style={{
            fontSize: '18px',
            fontWeight: '500',
            paddingLeft: '3rem',
          }}
          className=""
        >
          Project List
        </span>

        <div className="drm-cards-container">
          {drmProjectList
            .sort((a, b) => new Date(b.CreatedAt) - new Date(a.CreatedAt))
            .map((project) => {
              const createdAtDate = new Date(project.CreatedAt + 'Z');
              return (
                <Card
                  className="tanscendProjectsCard mt-4 ms-5 "
                  key=""
                  style={{
                    border: 'none',
                    backgroundColor: '#f7f7f9',
                  }}
                >
                  <div
                    style={{
                      position: 'relative',
                      flex: '1',
                    }}
                  >
                    {project.ThumbnailUrl !== null ? (
                    <Card.Img
                      className="transcendCardThumbnail"
                      src={project.ThumbnailUrl}
                      alt={project.Name}
                    />
                  ) : (
                    <Card.Img
                      className="transcendCardThumbnail"
                      src={thumbnail}
                      alt={project.Name}
                    />
                  )}

                    <div
                      className="edit-button"
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        opacity: 0,
                        transition: 'opacity 0.3s',
                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                        color: '#fff',
                        fontSize: '16px',
                        fontWeight: 500,
                        padding: '8px 16px',
                        borderRadius: '5px',
                        cursor: 'pointer',
                      }}
                      // onClick={(e) => getProject(projects.Id)}
                    >
                      Edit
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      color: 'black',
                      fontWeight: 500,
                      marginTop: '8px',
                    }}
                  >
                    <div>
                      <span>{project.ProjectName}</span>
                      <br />
                      <span style={{ color: 'grey', fontWeight: 400 }}>
                        Created:{' '}
                        <ReactTimeAgo date={createdAtDate} timeStyle="round" />
                      </span>
                    </div>
                    <Button
                      variant="text"
                      style={{
                        fontSize: '25px',
                        padding: 0,
                        height: '26px',
                        border: 'none',
                        outline: 'none',
                        position: 'relative',
                      }}
                      className="optionsButton"
                    >
                      <FiMoreVertical />
                    </Button>
                  </div>
                </Card>
              );
            })}
        </div>
      </div>
    </div>
  );
}
