import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { GrClose } from 'react-icons/gr';
import { FaUpload } from 'react-icons/fa6';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from 'react-bootstrap/Spinner';
import FileValidation from '../../../utils/FileValidation';

export default function UploadAudio({
  handleUploadClose,
  audioId,
  projectId,
  languageId,
  fileName,
  setAudioSegments,
  audioDuration,
}) {
  const apiURL = process.env.REACT_APP_API_URL;
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [playUrl, setPlayUrl] = useState('');
  const [flag, setFlag] = useState(false);
  const notify = (error) =>
    toast.error(error, {
      className: 'custom-error-toast',
    });

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };
  const handleDelete = () => {
    setFile(null);
  };
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    const audio = new Audio(URL.createObjectURL(selectedFile));
    audio.addEventListener('loadedmetadata', () => {
      const durationInSeconds = Math.floor(audio.duration);

      // Compare the new duration with the existing audioDuration
      if (
        audioDuration !== null &&
        (durationInSeconds > audioDuration + 1 ||
          durationInSeconds < audioDuration - 1)
      ) {
        // If the difference is greater than 1 second, reset the file to null
        setFile(null);
        const errorMessage =
          'The duration of uploaded file should be within threshold limit. Please try another file';
        notify(errorMessage);
      } else {
        const timestamp = new Date().getTime();
        const fileNameWithTimestamp = `${timestamp}_${selectedFile.name}`;

        // Create a new File object with the updated name
        const updatedFile = new File([selectedFile], fileNameWithTimestamp, {
          type: selectedFile.type,
          lastModified: selectedFile.lastModified,
        });
        setFile(updatedFile);
        setPlayUrl(URL.createObjectURL(updatedFile));
      }
    });
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const selectedFile = e.dataTransfer.files[0];
    const audio = new Audio(URL.createObjectURL(selectedFile));
    audio.addEventListener('loadedmetadata', () => {
      const durationInSeconds = Math.floor(audio.duration);

      // Compare the new duration with the existing audioDuration
      if (
        audioDuration !== null &&
        (durationInSeconds > audioDuration + 1 ||
          durationInSeconds < audioDuration - 1)
      ) {
        // If the difference is greater than 1 second, reset the file to null
        setFile(null);
        const errorMessage =
          'The duration of uploaded file should be within threshold limit. Please try another file';
        notify(errorMessage);
      } else {
        const timestamp = new Date().getTime();
        const fileNameWithTimestamp = `${timestamp}_${selectedFile.name}`;
        // Create a new File object with the updated name
        const updatedFile = new File([selectedFile], fileNameWithTimestamp, {
          type: selectedFile.type,
          lastModified: selectedFile.lastModified,
        });
        setFile(updatedFile);

        // Create a URL for the audio file
        setPlayUrl(URL.createObjectURL(updatedFile));
      }
    });
  };

  function uploadFile(selectedFile) {
    if (!FileValidation(selectedFile.name)) {
      return; // Exit the function if validation fails
    }
    const formData = new FormData();
    formData.append('ProjectId', projectId);
    formData.append('filename', selectedFile.name);
    formData.append('file', selectedFile);
    // console.log(formData);
    fetch(`${apiURL}/util/file_upload_aws`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Failed to fetch project data');
        }
      })
      .then((res) => {
        if (res.IsValid) {
          console.log(res.Url);
          saveAudio(res.Url);
        } else {
          notify('Upload unsuccessful, please upload a valid file!');
          console.error('Failed to upload file');
        }
      })
      .catch((error) => {
        setFlag(false);
        console.error('Error:', error);
      });
  }

  // Fetch audio segments api call

  const fetchAudioSegments = async () => {
    const reqBody = {
      ProjectId: projectId,
      TargetLanguageId: languageId,
    };
    try {
      const response = await fetch(`${apiURL}/web/get_audio_segments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reqBody),
      });

      if (response.ok) {
        const newData = await response.json();
        if (newData.IsValid) {
          setAudioSegments([...(newData.ResultAudioSegments || [])]);
          setFlag(false);
          handleUploadClose();
        }
      } else {
        setFlag(false);
        throw new Error('Failed to fetch project data');
      }
    } catch (error) {
      setFlag(false);
      console.error('Error:', error);
    }
  };

  function saveAudio(fileUrl) {
    const request = {
      ProjectId: projectId,
      TargetLangId: languageId,
      AudioSegmentId: audioId,
      FileName: fileName,
      AudioSegmentUrl: fileUrl,
    };
    fetch(`${apiURL}/dub/replace_audio_segment`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          setFlag(false);
          throw new Error('Failed to fetch project data');
        }
      })
      .then((newData) => {
        if (newData.IsValid) {
          fetchAudioSegments();
        }
      });
  }

  function handleSave() {
    if (file !== null) {
      setFlag(true);
      // console.log('file is',file);
      uploadFile(file);
    } else {
      setFlag(false);
      let errorMessage = 'Please upload a file.';
      notify(errorMessage);
    }
  }

  return (
    <div>
      <Modal.Body>
        <Container
          className="fileUploadContainer"
          style={{ boxShadow: 'none', height: '55vh' }}
          onDragOver={(e) => e.preventDefault()}
          onDrop={handleDrop}
        >
          {file !== null ? (
            <Button
              variant="text"
              className="delete-button"
              style={{
                position: 'absolute',
                top: 15,
                right: 15,
                fontWeight: 700,
              }}
              onClick={handleDelete}
            >
              <GrClose />
            </Button>
          ) : null}
          {file === null ? (
            <div>
              <Button
                variant="text"
                style={{ fontSize: '50px' }}
                onClick={handleUploadClick}
              >
                <FaUpload />
              </Button>
              <p style={{ fontWeight: 500 }}>
                Drop your audio here, or click to browse
              </p>
              <input
                ref={fileInputRef}
                type="file"
                style={{ display: 'none' }}
                accept=".mp3, .wav, .flac"
                onChange={handleFileChange}
              />
            </div>
          ) : (
            <div>
              <p className="mb-2 mt-2">{file.name}</p>
              <audio
                preload="none"
                controls
                disablePictureInPicture
                controlsList="nodownload noplaybackrate"
                src={playUrl}
                style={{ width: '500px' }}
              />
            </div>
          )}
        </Container>
      </Modal.Body>
      {flag === false ? (
        <Modal.Footer>
          <Button variant="secondary" onClick={handleUploadClose}>
            Close
          </Button>
          <Button
            variant="primary"
            style={{ backgroundColor: 'rgb(0 151 254)', color: 'white' }}
            onClick={(e) => {
              handleSave();
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      ) : (
        <Modal.Footer style={{ display: 'flex', justifyContent: 'center' }}>
          <div
            className="mt-2 mb-2"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <span
              style={{ marginRight: '10px', fontSize: '14px', fontWeight: 500 }}
            >
              File upload in progress{' '}
            </span>{' '}
            <Spinner size="sm" animation="border" variant="success" />
          </div>
        </Modal.Footer>
      )}
      <ToastContainer />
    </div>
  );
}
